import { createReducer, on } from '@ngrx/store';
import { authState } from './auth.state';
import * as actions from './auth.actions';

export const authReducer = createReducer(
    authState,
    // login
    on(actions.doLoginAction, (state, payload) => ({
        ...state,
        loginLoading: true,
        loginData: null,
        loginFailData: null,
    })),
    on(actions.doLoginActionSuccess, (state, payload) => ({
        ...state,
        loginLoading: false,
        loginData: payload,
    })),
    on(actions.doLoginActionFail, (state, payload) => ({
        ...state,
        loginLoading: false,
        loginFailData: payload,
    })),
    // profile
    on(actions.getProfileAction, (state, payload) => ({
        ...state,
        getProfileData: null,
        getProfileLoading: true,
        getProfileFailData: null,
    })),
    on(actions.getProfileSuccessAction, (state, payload) => ({
        ...state,
        getProfileData: payload,
        getProfileLoading: false,
        getProfileFailData: null,
    })),
    on(actions.getProfileFailAction, (state, payload) => ({
        ...state,
        getProfileData: null,
        getProfileLoading: false,
        getProfileFailData: payload,
    }))
);
