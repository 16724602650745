import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-draw-zone',
    templateUrl: './draw-zone.component.html',
    styleUrls: ['./draw-zone.component.css'],
})
export class DrawZoneComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
