import { createReducer, on } from '@ngrx/store';
import { todoState } from './todos.state';
import {
    addTodo,
    removeTodo,
    loadTodos,
    loadTodosSuccess,
    loadTodosFailure,
} from './todos.actions';

export const todoReducer = createReducer(
    // Supply the initial state
    todoState,
    // Add the new todo to the todos array
    on(addTodo, (state, { content }) => ({
        ...state,
        todos: [
            ...state.todos,
            { id: Date.now().toString(), content: content || null },
        ],
    })),
    // Remove the todo from the todos array
    on(removeTodo, (state, { id }) => ({
        ...state,
        todos: state.todos.filter(todo => todo.id !== id),
    })),
    // Trigger loading the todos
    on(loadTodos, state => ({ ...state, status: 'loading' })),
    // Handle successfully loaded todos
    on(loadTodosSuccess, (state, { todos }) => ({
        ...state,
        todos: todos || null,
        error: null,
        status: 'success',
    })),
    // Handle todos load failure
    on(loadTodosFailure, (state, { error }) => ({
        ...state,
        error: error || null,
        status: 'error',
    }))
);
