import { ActionReducerMap } from '@ngrx/store';
import { marketplacesReducer } from './api-middleware/marketplaces/reducer';
import { AppState } from './app.state';
import { authReducer } from './auth/auth.reducer';
import { catalogReducer } from './catalog/reducer';
import { dictionaryReducer } from './dictionary/reducer';
import { todoReducer } from './todos/todos.reducer';

export const reducers: ActionReducerMap<AppState> = {
    todos: todoReducer,
    auth: authReducer,
    catalog: catalogReducer,
    dictionary: dictionaryReducer,
    marketplaces: marketplacesReducer,
};
