import { Injectable } from '@angular/core';
import {
    ToastController,
    LoadingController,
    AlertController,
} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class IntermediaryService {
    loading;
    created;
    constructor(
        private toastCtrl: ToastController,
        private loadingCtrl: LoadingController,
        private alertController: AlertController
    ) {}

    /**
     * Show error message
     *
     * @param message - message to be toasted
     * @param position
     * @param durationToast
     */
    async presentToastError(
        message: string,
        position: any = 'top',
        durationToast = 4000
    ) {
        const toast = (
            await this.toastCtrl.create({
                message,
                duration: durationToast,
                color: 'danger',
                position,
            })
        ).present();
    }

    /**
     * Show toast
     *
     * @param message - message to be presented
     * @param durationToast - te duration of the visible toast
     */
    async presentToastSuccess(
        message: string,
        durationToast = 1000,
        position: any = 'top'
    ) {
        const toast = await this.toastCtrl.create({
            message,
            duration: durationToast,
            color: 'success',
            position,
        });

        return toast.present();
    }

    async presentToastPrimary(
        message: string,
        durationToast = 3750,
        position: any = 'top'
    ) {
        const toast = await this.toastCtrl.create({
            message,
            duration: durationToast,
            color: 'primary',
            position,
        });

        return toast.present();
    }

    async presentToastWarning(
        message: string,
        durationToast = 3000,
        position: any = 'top'
    ) {
        const toast = await this.toastCtrl.create({
            message,
            duration: durationToast,
            color: 'warning',
            position,
        });

        return toast.present();
    }

    /**
     * launch loading
     *
     * @param message - the message to be showed in loading
     */
    async presentLoading(message: string = '', callback: () => any = () => {}) {
        if (!this.loading) {
            this.loading = true;
            this.loadingCtrl
                .create({
                    message,
                })
                .then(loading => {
                    loading.present().then(() => {
                        this.created = true;
                        if (callback && typeof callback == 'function') {
                            callback();
                        }
                        if (!this.loading) {
                            this.dismissLoading();
                        }
                    });
                });
        }
    }

    /**
     * launch loading
     *
     * @param message - the message to be showed in loading
     */
    async presentLoadingNew(message: string = '') {
        if (!this.loading) {
            this.loading = true;
            const loading = await this.loadingCtrl.create({
                message,
            });

            return await loading.present();
        }

        return null;
    }

    /**
     * close the latest modal
     */
    async dismissLoadingNew() {
        if (this.loading) {
            this.loading = false;
            return await this.loadingCtrl.dismiss();
        } else {
            return;
        }
    }

    /**
     * close the latest modal
     */
    async dismissLoading() {
        this.loading = false;
        if (this.created) {
            await this.loadingCtrl.dismiss().then(message => {
                this.created = false;
                /**flag what indicate that loading has been close */
            });
        }
    }

    async presentConfirm(
        message: string,
        callbackOK,
        callbackCancel?,
        textOK?: string,
        textCancel?: string
    ) {
        const buttonsAlert: any = [
            {
                text: textCancel ? textCancel : 'Cancelar',
            },
            {
                text: textOK ? textOK : 'OK',
                handler: callbackOK,
            },
        ];

        if (callbackCancel) {
            buttonsAlert[0].handler = callbackCancel;
        }

        const alert = await this.alertController.create({
            header: 'Confirmar',
            message,
            buttons: buttonsAlert,
        });
        return await alert.present();
    }

    async presentWarning(message: string, callback) {
        const alert = await this.alertController.create({
            header: '¡Atención!',
            message,
            buttons: [
                {
                    text: 'Cerrar',
                    handler: callback,
                },
            ],
        });

        return await alert.present();
    }

    /**
     * Show snackbar
     *
     * @param message - message to be presented
     * @param closeBtn
     */
    /*async presentSnackbar(message: string, closeBtn: string = 'CERRAR') {
    let toast = await this.toastCtrl.create({
      message: message,
      closeButtonText: closeBtn,
      color: 'dark',
      showCloseButton: true
    });

    return toast.present();
  }*/

    async presentInputConfirm(
        header: string,
        inputs: any[],
        callbackOK,
        callbackCancel?,
        textOK?: string,
        textCancel?: string
    ) {
        const buttonsAlert: any = [
            {
                text: textCancel ? textCancel : 'Cancelar',
            },
            {
                text: textOK ? textOK : 'Aceptar',
                handler: callbackOK,
            },
        ];

        if (callbackCancel) {
            buttonsAlert[0].handler = callbackCancel;
        }

        const alert = await this.alertController.create({
            header,
            buttons: buttonsAlert,
            inputs,
        });
        return await alert.present();
    }
}
