import { Injectable } from '@angular/core';

import { ExpeditionModel } from '../models';

import IExpeditionShippingPackageCode = ExpeditionModel.IExpeditionShippingPackageCode;

@Injectable({
    providedIn: 'root',
})
export class LabelsService {

    private _shippingPackageCodes: IExpeditionShippingPackageCode[] = [];

    constructor() {}

    get shippingPackageCodes(): IExpeditionShippingPackageCode[] {
        return this._shippingPackageCodes;
    }

    set shippingPackageCodes(value: IExpeditionShippingPackageCode[]) {
        this._shippingPackageCodes = value;
    }

    isAllShippingPackageCodesChecked(): boolean {
        return !this.shippingPackageCodes.find(c => !c.checked);
    }

    checkUniqueCodeInShippingPackageCodes(uniqueCode: string): boolean {
        const found = this.shippingPackageCodes.find(c => c.uniqueCode == uniqueCode);
        if (found) {
            found.checked = true;
            return true;
        }
        return false;
    }

    resetCheckedShippingPackageCodes(): void {
        this.shippingPackageCodes = this.shippingPackageCodes.map(c => {
            c.checked = false;
            return c;
        });
    }

    getNumShippingPackageCodesChecked(): number {
        return this.shippingPackageCodes ? this.shippingPackageCodes.filter(s => s.checked).length : 0;
    }

    getNumShippingPackageCodes(): number {
        return this.shippingPackageCodes ? this.shippingPackageCodes.length : 0;
    }

}
