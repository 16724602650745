import { BrandModel, InventoryModel, ModelModel, ProductModel, ProviderModel, SizeModel, WarehouseModel } from './';

export namespace ReturnModel {

    export interface InventoryReturn {
        provider: ProviderModel.Provider;
        brands: BrandModel.Brand[];
        dateLimit: Date;
        warehouse: WarehouseModel.Warehouse;
        status: string;
        dateLastStatus: Date;
        userLastStatus: any;
        unitsSelected: number;
        unitsPrepared: number;
        unitsLaunched: number;
        history: boolean;
        dateReturnBefore: Date;
        email: string;
        observations: string;
        operatorObservations: string;
        lastStatus: string;
        user: any;
        amountPackages: number;
        shipper: string;
        datePredictedPickup: Date;
        datePickup: Date;
        printTagPackages: boolean;
        assigning: boolean;
        products: InventoryReturnProduct[];
        avelonNotified: boolean;
    }

    export interface InventoryReturnProduct {
        return: InventoryReturn;
        returnId: number;
        product: ProductModel.Product;
        productId: number;
        model: ModelModel.Model;
        modelId: number;
        size: SizeModel.Size;
        sizeId: number;
        status: string;
        inventory: InventoryModel.Inventory;
        inventoryId: number;
    }

    export interface GetReturnsByPackingParameters {
        packingReference: string;
        returnPicking: boolean;
        returnId?: number;
    }

    export interface GetReturnsByPackingResponse {
        returnProducts: InventoryReturnProduct[];
        returnIds: number[];
        otherProcess: boolean;
        otherProducts: InventoryModel.Inventory[];
    }

}
