import { WarehouseModel } from 'src/app/models/warehouse.model';

export interface CatalogState {
    syncSizesLoading: boolean;
    syncSizesData: any;
    syncSizesFailData: any;

    getWarehouseListLoading: boolean;
    getWarehouseListData: WarehouseModel.ResponseModel;
    getWarehouseListFailData: any;

    getWarehouseItemLoading: any;
    getWarehouseItemData: any;
    getWarehouseItemFailData: any;

    updateWarehouseItemLoading: any;
    updateWarehouseItemData: any;
    updateWarehouseItemFailData: any;

    syncWarehouseLoading: any;
    syncWarehouseData: any;
    syncWarehouseFailData: any;

    getRackListLoading: any;
    getRackListData: any;
    getRackListFailData: any;

    getRackItemLoading: any;
    getRackItemData: any;
    getRackItemFailData: any;

    resizeRackItemLoading: any;
    resizeRackItemData: any;
    resizeRackItemFailData: any;

    getContainerListLoading: any;
    getContainerListData: any;
    getContainerListFailData: any;

    getContainerItemLoading: any;
    getContainerItemData: any;
    getContainerItemFailData: any;

    setLockedContainerLoading: any;
    setLockedContainerData: any;
    setLockedContainerFailData: any;

    getSizesListLoading: any;
    getSizesListData: any;
    getSizesListFailData: any;

    getDomainSizesListLoading: any;
    getDomainSizesListData: any;
    getDomainSizesListFailData: any;

    getPackingItemLoading: any;
    getPackingItemData: any;
    getPackingItemFailData: any;

    getPackingListLoading: any;
    getPackingListData: any;
    getPackingListFailData: any;

    updatePackingItemLoading: any;
    updatePackingItemData: any;
    updatePackingItemFailData: any;

    getMarketListLoading: any;
    getMarketListData: any;
    getMarketListFailData: any;

    deleteMarketItemLoading: any;
    deleteMarketItemData: any;
    deleteMarketItemFailData: any;

    updateMarketItemLoading: any;
    updateMarketItemData: any;
    updateMarketItemFailData: any;

    getMarketItemLoading: any;
    getMarketItemData: any;
    getMarketItemFailData: any;
}

export const catalogRecord: CatalogState = {
    syncSizesLoading: false,
    syncSizesData: null,
    syncSizesFailData: null,

    getWarehouseListLoading: null,
    getWarehouseListData: null,
    getWarehouseListFailData: null,

    getWarehouseItemLoading: null,
    getWarehouseItemData: null,
    getWarehouseItemFailData: null,

    updateWarehouseItemLoading: null,
    updateWarehouseItemData: null,
    updateWarehouseItemFailData: null,

    syncWarehouseLoading: null,
    syncWarehouseData: null,
    syncWarehouseFailData: null,

    getRackListLoading: null,
    getRackListData: null,
    getRackListFailData: null,

    getRackItemLoading: null,
    getRackItemData: null,
    getRackItemFailData: null,

    resizeRackItemLoading: null,
    resizeRackItemData: null,
    resizeRackItemFailData: null,

    getContainerListLoading: null,
    getContainerListData: null,
    getContainerListFailData: null,

    getContainerItemLoading: null,
    getContainerItemData: null,
    getContainerItemFailData: null,

    setLockedContainerLoading: null,
    setLockedContainerData: null,
    setLockedContainerFailData: null,

    getSizesListLoading: null,
    getSizesListData: null,
    getSizesListFailData: null,

    getDomainSizesListLoading: null,
    getDomainSizesListData: null,
    getDomainSizesListFailData: null,

    getPackingItemLoading: null,
    getPackingItemData: null,
    getPackingItemFailData: null,

    getPackingListLoading: null,
    getPackingListData: null,
    getPackingListFailData: null,

    updatePackingItemLoading: null,
    updatePackingItemData: null,
    updatePackingItemFailData: null,

    getMarketListLoading: null,
    getMarketListData: null,
    getMarketListFailData: null,

    deleteMarketItemLoading: null,
    deleteMarketItemData: null,
    deleteMarketItemFailData: null,

    updateMarketItemLoading: null,
    updateMarketItemData: null,
    updateMarketItemFailData: null,

    getMarketItemLoading: null,
    getMarketItemData: null,
    getMarketItemFailData: null,
};
