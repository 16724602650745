export const API_ENDPOINTS = {
    auth: {
        login: '/auth/login',
        profile: '/auth/profile',
    },
    fileStore: {
        info: '/file-store/{id}/info',
        stringBody: '/file-store/{id}/info',
        streamBody: '/file-store/{id}/stream-body',
    },
    rabbit: {
        broadcast: '/file-store/{id}/stream-body',
        workerReLoad: '/rabbit/worker-re-load',
        webReLoad: '/rabbit/worker-re-load',
    },
    task: {
        launch: '/task/launch',
        launchPlain: '/task/launch-plain/{longName}',
        cancel: '/task/cancel/{id}',
        getItem: '/task/{id}',
        getList: '/task',
    },
    catalog: {
        syncSizes: '/catalog/synchronize-sizes',
        marketItem: '/catalog/market/{id}',
        market: '/catalog/market',
        packing: '/catalog/packing',
        packingItem: '/catalog/packing/{id}',
        domainSize: '/catalog/domain-size',
        size: '/catalog/size',
        containerSetLocked:
            '/catalog/container/{containerId}/set-locked/{value}',
        containerId: '/catalog/container/{id}',
        container: '/catalog/container',
        rackResize: '/catalog/rack/resize/{rackId}',
        rackItem: '/catalog/rack/{id}',
        rack: '/catalog/rack',
        warehouseSync: '/catalog/warehouse/synchronize',
        warehouseItem: '/catalog/warehouse/{id}',
        warehouse: '/catalog/warehouse',
    },
    dictionary: {
        colorSync: '/dictionary/color/synchronize',
        color: '/dictionary/color',
        colorItem: '/dictionary/color/{id}',
        providerItem: '/dictionary/provider/{id}',
        provider: '/dictionary/provider',
    },
    geo: {
        countrySync: '/geo/country/synchronize',
        countryItem: '/geo/country/{id}',
        country: '/geo/country',
        postalCodeItem: '/geo/postal-code/{id}',
        postalCode: '/geo/postal-code',
        provinceItem: '/geo/province/{id}',
        province: '/geo/province',
        regionItem: '/geo/region/{id}',
        region: '/geo/region',
    },
};
