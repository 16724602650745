import { MenuItemList } from './menu.data';

export const logisticsMenu: MenuItemList = [
    {
        title: 'Logística',
        open: false,
        type: 'wrapper',
        icon: 'hardware-chip-outline',
        permissions: ['core.product.product'],
        isMobile: true,
        children: [
            {
                title: 'Productos',
                id: 'products-sga',
                url: '/products',
                icon: 'basket-outline',
                permissions: ['core.product.product'],
            },
            /*{
                    title:'Etiquetas',
                    id:'labels',
                    url:'/labels',
                    icon:'basket'
                  },*/
            {
                title: 'Mapa Almacén',
                id: 'warehouses-map-sga',
                url: '/warehouse/manage',
                icon: 'apps-outline',
                permissions: ['core.catalog.warehouse'],
                isMobile: true,
            },
            {
                title: 'Tareas de Picking',
                id: 'picking-task',
                url: '/picking-tasks',
                icon: 'qr-code-outline',
                permissions: ['core.stock.workwave'],
                tooltip: 'Tareas de picking asignadas',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Ubicar/escanear con cámara',
                id: 'positioning',
                url: 'positioning',
                icon: 'locate-outline',
                permissions: ['core.inventory.product.move'],
                tooltip: 'Escanear artículos mediante cámara para ubicar',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Ubicar/escanear con láser',
                id: 'positioning-manual',
                url: '/positioning/manual',
                icon: 'locate-outline',
                permissions: ['core.inventory.product.move'],
                tooltip: 'Escanear artículos mediante láser para ubicar',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Creación Picking Pedidos/Peticiones',
                id: 'create-picking-orders-petitions',
                url: '/createPickingOrdersPetitions',
                icon: 'add-outline',
                permissions: ['core.stock.workwave'],
                tooltip: 'Crear pickings de peticiones y pedidos',
                isMobile: true,
                isWeb: false,
            },
            // Hide until someone needs to use it
            // {
            //     title: 'Verificación de artículos',
            //     icon: 'checkmark-circle-outline',
            //     url: '/picking/online-store/verify',
            //     id: 'verification-products-online',
            //     tooltip: 'Sección para verificar los artículos aptos online',
            //     isMobile: true,
            //     isWeb: false,
            // },
            {
                title: 'Control Embalajes',
                id: 'packing-control-sga',
                url: '/audits',
                icon: 'hardware-chip-outline',
                permissions: ['core.catalog.packing'],
            },
            {
                title: 'Embalajes',
                id: 'packing-sga',
                url: '/jails/menu',
                icon: 'grid-outline',
                permissions: ['core.catalog.packing'],
                isMobile: true,
            },
            {
                title: 'Incidencias',
                id: 'incidences',
                url: '/incidences',
                icon: 'notifications-outline',
                permissions: ['el.incidence'],
            },
            {
                title: 'Incidencias Escaneos Salida',
                id: 'out-scans-incidences',
                url: '/out-scans-incidences',
                icon: 'notifications-outline',
                permissions: ['sorter'],
            },
            {
                title: 'Incidencias Escaneos Recepción',
                id: 'in-scans-incidences',
                url: '/in-scans-incidences',
                icon: 'notifications-outline',
                permissions: ['sorter'],
            },
            {
                title: 'Traspasos',
                id: 'picking-task-store',
                icon: 'qr-code-outline',
                permissions: [
                    'core.inventory.product.move',
                    'core.catalog.packing',
                ],
                url: '/picking-tasks',
                tooltip: 'Traspasos pendientes de realizar',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Listado de peticiones',
                id: 'picking-tasks-stores',
                url: '/picking-tasks-stores',
                icon: 'qr-code-outline',
                permissions: [
                    'core.inventory.product.move',
                    'core.catalog.packing',
                ],
                tooltip: 'Listado de peticiones pendientes de realizar',
                isMobile: true,
                isWeb: false,
                //   amount: this.pickingTasksStoresAmount
            },
            {
                title: 'Asociar pares a embalajes',
                id: 'picking-scan-packing',
                url: '/picking-scan-packing',
                icon: 'qr-code-outline',
                permissions: [
                    'core.inventory.product.move',
                    'core.catalog.packing',
                ],
                tooltip:
                    'Asociar pares procesados para traspasos a embalajes y precintarlos',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Liberar Reservas Expiradas',
                id: 'free-expired-reserves',
                icon: 'ios-link',
                url: '/free-expired-reserves',
                tooltip:
                    'Liberar reservas expiradas mediante escaneo con cámara',
                permissions: ['reception.order.free-expired'],
                isMobile: true,
                isWeb: false,
                //   amount: this.reservedExpiredAmount
            },
            {
                title: 'Ubicar defectuosos',
                id: 'defectives-positioning',
                url: 'defective-positioning',
                icon: 'warning-outline',
                permissions: ['core.defect.defect'],
                tooltip:
                    'Escanear artículos defectuosos mediante cámara para ubicar',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Recepcionar embalaje',
                id: 'reception-products-packing',
                url: 'reception',
                icon: 'archive-outline',
                permissions: [
                    'core.catalog.packing',
                    'core.inventory.receive-by-package',
                ],
                tooltip:
                    'Recepcionar embalaje completo escaneando la etiqueta con cámara',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Recepcionar par a par',
                id: 'reception-products-pair-by-pair',
                url: 'reception/pair-by-pair',
                icon: 'square-outline',
                permissions: ['core.inventory.receive-by-pair'],
                tooltip:
                    'Recepcionar par a par los artículos recibidos escanéandolos con la cámara',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Reetiquetado embalajes',
                id: 'print-packing',
                url: '/print/packing',
                icon: 'grid-outline',
                permissions: ['core.catalog.packing'],
                tooltip: 'Imprimir nuevas etiquetas de embalajes',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Precintar embalaje con cámara',
                id: 'packing-seal',
                url: 'packing/seal',
                icon: 'paper-plane-outline',
                permissions: ['core.catalog.packing'],
                tooltip:
                    'Precintar embalaje para enviar desde el escáner de la cámara',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Precintar embalaje con láser',
                id: 'packing-seal-manual',
                url: '/packing/seal/manual',
                icon: 'paper-plane-outline',
                permissions: ['core.catalog.packing'],
                tooltip: 'Precintar embalaje para enviar desde el láser',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Traspaso contenido embalaje',
                id: 'packing-transfer',
                url: '/packing/transfer',
                icon: 'arrow-redo-outline',
                permissions: ['core.catalog.packing'],
                tooltip: 'Traspasar toda la mercancía de un embalaje a otro',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Recepción de embalaje vacío',
                id: 'reception-empty-packing',
                url: '/packing/carrierEmptyPacking',
                icon: 'exit-outline',
                permissions: ['core.catalog.packing'],
                tooltip: 'Recibir embalajes que han sido enviados vacíos',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Envío de embalaje vacío',
                id: 'send-empty-packing',
                url: '/sendEmptyPacking',
                icon: 'send-outline',
                permissions: ['core.catalog.packing'],
                tooltip: 'Enviar embalajes vacíos a otro destino',
                isMobile: true,
                isWeb: false,
            },
        ],
    },
];
