import { WarehouseModel, InventoryModel } from './';

export namespace PackingModel {

    export interface Packing {
        id: number;
        createdAt: string;
        updatedAt: string;
        reference: string;
        type: PackingType;
        status: PackingStatus;
        processingState: PackingProcessingState;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        movedAt: Date;
        isForIncidences: boolean;
        inventories: InventoryModel.Inventory[];
        destinations: PackingDestination[];
        productCount: PackingProductCountVw;
    }

    export interface PackingDestination {
        packing: Packing;
        packingId: number;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
    }

    export interface PackingProductCountVw {
        id: number;
        productCount: number;
        packing: Packing;
    }

    export interface PackingInventoriesFiltersResponse {
        items: {
            id: number;
            reference: string;
            type: 'product' | 'package';
        }[];
        destinationWarehouses: {
            id: number;
            name: string;
        }[];
    }

    export interface SearchPackingInventoriesParameters {
        filters: {
            productIds: number[];
            packageIds: number[];
            warehouseIds: number[];
        };
        orderBy: {
            field: 'reference' | 'destination';
            order: 'ASC' | 'DESC';
        };
    }

    export interface EmptyBulkParameters {
        packingIds: number[];
        process: ProcessType;
    }

    export interface EmptyBulkResponse {
        missingIds: number[];
    }

}

export enum ProcessType {
    location = 'location',
    warehouseManage = 'warehouseManage',
    sorter = 'sorter',
    picking = 'picking',
    sorterEmptying = 'sorterEmptying',
    other = 'other',
    reception = 'reception',
    assignationLineRequest = 'assignationLineRequest',
    receptionManufacturer = 'receptionManufacturer',
}

export enum PackingType {
    cage = 'cage',
    pallet = 'pallet',
    box = 'box',
}

export enum PackingStatus {
    empty = 'empty',
    charging = 'charging',
    sent = 'sent',
    sealed = 'sealed',
    sentWithoutProducts = 'sentWithoutProducts',
}

export enum PackingProcessingState {
    pickingWarehouse = 'pickingWarehouse',
    pickingStore = 'pickingStore',
    return = 'return',
    ventilation = 'ventilation',
    sorterOut = 'sorterOut',
    location = 'location',
    sealMainWarehouse = 'sealMainWarehouse',
    sealStore = 'sealStore',
}
