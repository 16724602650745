import { createReducer, on } from '@ngrx/store';
import { catalogRecord } from './state';
import * as actions from './actions';

export const catalogReducer = createReducer(
    catalogRecord,
    //
    on(actions.syncSizesAction, (state, payload) => ({
        ...state,
        syncSizesLoading: true,
        syncSizesData: null,
        syncSizesFailData: null,
    })),
    on(actions.syncSizesSuccessAction, (state, payload) => ({
        ...state,
        syncSizesLoading: false,
        syncSizesData: payload,
        syncSizesFailData: null,
    })),
    on(actions.syncSizesFailAction, (state, payload) => ({
        ...state,
        syncSizesLoading: false,
        syncSizesData: null,
        syncSizesFailData: payload,
    })),
    //
    on(actions.getWarehouseListAction, (state, payload) => ({
        ...state,
        getWarehouseListLoading: true,
        getWarehouseListData: null,
        getWarehouseListFailData: null,
    })),
    on(actions.getWarehouseListSuccessAction, (state, payload) => ({
        ...state,
        getWarehouseListLoading: null,
        getWarehouseListData: payload,
        getWarehouseListFailData: null,
    })),
    on(actions.getWarehouseListFailAction, (state, payload) => ({
        ...state,
        getWarehouseListLoading: null,
        getWarehouseListData: null,
        getWarehouseListFailData: payload,
    })),
    //
    on(actions.getWarehouseItemAction, (state, payload) => ({
        ...state,
        getWarehouseItemLoading: true,
        getWarehouseItemData: null,
        getWarehouseItemFailData: null,
    })),
    on(actions.getWarehouseItemSuccessAction, (state, payload) => ({
        ...state,
        getWarehouseItemLoading: null,
        getWarehouseItemData: payload,
        getWarehouseItemFailData: null,
    })),
    on(actions.getWarehouseItemFailAction, (state, payload) => ({
        ...state,
        getWarehouseItemLoading: null,
        getWarehouseItemData: null,
        getWarehouseItemFailData: payload,
    })),
    //
    on(actions.updateWarehouseItemAction, (state, payload) => ({
        ...state,
        updateWarehouseItemLoading: true,
        updateWarehouseItemData: null,
        updateWarehouseItemFailData: null,
    })),
    on(actions.updateWarehouseItemSuccessAction, (state, payload) => ({
        ...state,
        updateWarehouseItemLoading: null,
        updateWarehouseItemData: payload,
        updateWarehouseItemFailData: null,
    })),
    on(actions.updateWarehouseItemFailAction, (state, payload) => ({
        ...state,
        updateWarehouseItemLoading: null,
        updateWarehouseItemData: null,
        updateWarehouseItemFailData: payload,
    })),
    on(actions.clearUpdateWarehouseItemStateAction, (state, payload) => ({
        ...state,
        updateWarehouseItemLoading: false,
        updateWarehouseItemData: null,
        updateWarehouseItemFailData: null,
    })),
    //
    on(actions.syncWarehouseAction, (state, payload) => ({
        ...state,
        syncWarehouseLoading: true,
        syncWarehouseData: null,
        syncWarehouseFailData: null,
    })),
    on(actions.syncWarehouseSuccessAction, (state, payload) => ({
        ...state,
        syncWarehouseLoading: null,
        syncWarehouseData: payload,
        syncWarehouseFailData: null,
    })),
    on(actions.syncWarehouseFailAction, (state, payload) => ({
        ...state,
        syncWarehouseLoading: null,
        syncWarehouseData: null,
        syncWarehouseFailData: payload,
    })),
    //
    on(actions.getRackListAction, (state, payload) => ({
        ...state,
        getRackListLoading: true,
        getRackListData: null,
        getRackListFailData: null,
    })),
    on(actions.getRackListSuccessAction, (state, payload) => ({
        ...state,
        getRackListLoading: null,
        getRackListData: payload,
        getRackListFailData: null,
    })),
    on(actions.getRackListFailAction, (state, payload) => ({
        ...state,
        getRackListLoading: null,
        getRackListData: null,
        getRackListFailData: payload,
    })),
    //
    on(actions.getRackItemAction, (state, payload) => ({
        ...state,
        getRackItemLoading: true,
        getRackItemData: null,
        getRackItemFailData: null,
    })),
    on(actions.getRackItemSuccessAction, (state, payload) => ({
        ...state,
        getRackItemLoading: null,
        getRackItemData: payload,
        getRackItemFailData: null,
    })),
    on(actions.getRackItemFailAction, (state, payload) => ({
        ...state,
        getRackItemLoading: null,
        getRackItemData: null,
        getRackItemFailData: payload,
    })),
    //
    on(actions.resizeRackItemAction, (state, payload) => ({
        ...state,
        resizeRackItemLoading: true,
        resizeRackItemData: null,
        resizeRackItemFailData: null,
    })),
    on(actions.resizeRackItemSuccessAction, (state, payload) => ({
        ...state,
        resizeRackItemLoading: null,
        resizeRackItemData: payload,
        resizeRackItemFailData: null,
    })),
    on(actions.resizeRackItemFailAction, (state, payload) => ({
        ...state,
        resizeRackItemLoading: null,
        resizeRackItemData: null,
        resizeRackItemFailData: payload,
    })),
    on(actions.clearResizeRackItemStateAction, (state, payload) => ({
        ...state,
        resizeRackItemLoading: false,
        resizeRackItemData: null,
        resizeRackItemFailData: null,
    })),
    //
    on(actions.getContainerListAction, (state, payload) => ({
        ...state,
        getContainerListLoading: true,
        getContainerListData: null,
        getContainerListFailData: null,
    })),
    on(actions.getContainerListSuccessAction, (state, payload) => ({
        ...state,
        getContainerListLoading: null,
        getContainerListData: payload,
        getContainerListFailData: null,
    })),
    on(actions.getContainerListFailAction, (state, payload) => ({
        ...state,
        getContainerListLoading: null,
        getContainerListData: null,
        getContainerListFailData: payload,
    })),
    //
    on(actions.getContainerItemAction, (state, payload) => ({
        ...state,
        getContainerItemLoading: true,
        getContainerItemData: null,
        getContainerItemFailData: null,
    })),
    on(actions.getContainerItemSuccessAction, (state, payload) => ({
        ...state,
        getContainerItemLoading: null,
        getContainerItemData: payload,
        getContainerItemFailData: null,
    })),
    on(actions.getContainerItemFailAction, (state, payload) => ({
        ...state,
        getContainerItemLoading: null,
        getContainerItemData: null,
        getContainerItemFailData: payload,
    })),
    //
    on(actions.setLockedContainerItemAction, (state, payload) => ({
        ...state,
        setLockedContainerLoading: true,
        setLockedContainerData: null,
        setLockedContainerFailData: null,
    })),
    on(actions.setLockedContainerItemSuccessAction, (state, payload) => ({
        ...state,
        setLockedContainerLoading: null,
        setLockedContainerData: payload,
        setLockedContainerFailData: null,
    })),
    on(actions.setLockedContainerItemFailAction, (state, payload) => ({
        ...state,
        setLockedContainerLoading: null,
        setLockedContainerData: null,
        setLockedContainerFailData: payload,
    })),
    on(actions.clearSetLockedContainerItemStateAction, (state, payload) => ({
        ...state,
        setLockedContainerLoading: false,
        setLockedContainerData: null,
        setLockedContainerFailData: null,
    })),
    //
    on(actions.getSizesListAction, (state, payload) => ({
        ...state,
        getSizesListLoading: true,
        getSizesListData: null,
        getSizesListFailData: null,
    })),
    on(actions.getSizesListSuccessAction, (state, payload) => ({
        ...state,
        getSizesListLoading: null,
        getSizesListData: payload,
        getSizesListFailData: null,
    })),
    on(actions.getSizesListFailAction, (state, payload) => ({
        ...state,
        getSizesListLoading: null,
        getSizesListData: null,
        getSizesListFailData: payload,
    })),
    //
    on(actions.getDomainSizesListAction, (state, payload) => ({
        ...state,
        getDomainSizesListLoading: true,
        getDomainSizesListData: null,
        getDomainSizesListFailData: null,
    })),
    on(actions.getDomainSizesListSuccessAction, (state, payload) => ({
        ...state,
        getDomainSizesListLoading: null,
        getDomainSizesListData: payload,
        getDomainSizesListFailData: null,
    })),
    on(actions.getDomainSizesListFailAction, (state, payload) => ({
        ...state,
        getDomainSizesListLoading: null,
        getDomainSizesListData: null,
        getDomainSizesListFailData: payload,
    })),
    //
    on(actions.getPackingItemAction, (state, payload) => ({
        ...state,
        getPackingItemLoading: true,
        getPackingItemData: null,
        getPackingItemFailData: null,
    })),
    on(actions.getPackingItemSuccessAction, (state, payload) => ({
        ...state,
        getPackingItemLoading: null,
        getPackingItemData: payload,
        getPackingItemFailData: null,
    })),
    on(actions.getPackingItemFailAction, (state, payload) => ({
        ...state,
        getPackingItemLoading: null,
        getPackingItemData: null,
        getPackingItemFailData: payload,
    })),
    //
    on(actions.getPackingListAction, (state, payload) => ({
        ...state,
        getPackingListLoading: true,
        getPackingListData: null,
        getPackingListFailData: null,
    })),
    on(actions.getPackingListSuccessAction, (state, payload) => ({
        ...state,
        getPackingListLoading: null,
        getPackingListData: payload,
        getPackingListFailData: null,
    })),
    on(actions.getPackingListFailAction, (state, payload) => ({
        ...state,
        getPackingListLoading: null,
        getPackingListData: null,
        getPackingListFailData: payload,
    })),
    //
    on(actions.updatePackingItemAction, (state, payload) => ({
        ...state,
        updatePackingItemLoading: true,
        updatePackingItemData: null,
        updatePackingItemFailData: null,
    })),
    on(actions.updatePackingItemSuccessAction, (state, payload) => ({
        ...state,
        updatePackingItemLoading: null,
        updatePackingItemData: payload,
        updatePackingItemFailData: null,
    })),
    on(actions.updatePackingItemFailAction, (state, payload) => ({
        ...state,
        updatePackingItemLoading: null,
        updatePackingItemData: null,
        updatePackingItemFailData: payload,
    })),
    //
    on(actions.getMarketListAction, (state, payload) => ({
        ...state,
        getMarketListLoading: true,
        getMarketListData: null,
        getMarketListFailData: null,
    })),
    on(actions.getMarketListSuccessAction, (state, payload) => ({
        ...state,
        getMarketListLoading: null,
        getMarketListData: payload,
        getMarketListFailData: null,
    })),
    on(actions.getMarketListFailAction, (state, payload) => ({
        ...state,
        getMarketListLoading: null,
        getMarketListData: null,
        getMarketListFailData: payload,
    })),
    //
    on(actions.deleteMarketItemAction, (state, payload) => ({
        ...state,
        deleteMarketItemLoading: true,
        deleteMarketItemData: null,
        deleteMarketItemFailData: null,
    })),
    on(actions.deleteMarketItemSuccessAction, (state, payload) => ({
        ...state,
        deleteMarketItemLoading: null,
        deleteMarketItemData: payload,
        deleteMarketItemFailData: null,
    })),
    on(actions.deleteMarketItemFailAction, (state, payload) => ({
        ...state,
        deleteMarketItemLoading: null,
        deleteMarketItemData: null,
        deleteMarketItemFailData: payload,
    })),
    //
    on(actions.updateMarketItemAction, (state, payload) => ({
        ...state,
        updateMarketItemLoading: true,
        updateMarketItemData: null,
        updateMarketItemFailData: null,
    })),
    on(actions.updateMarketItemSuccessAction, (state, payload) => ({
        ...state,
        updateMarketItemLoading: null,
        updateMarketItemData: payload,
        updateMarketItemFailData: null,
    })),
    on(actions.updateMarketItemFailAction, (state, payload) => ({
        ...state,
        updateMarketItemLoading: null,
        updateMarketItemData: null,
        updateMarketItemFailData: payload,
    })),
    //
    on(actions.getMarketItemAction, (state, payload) => ({
        ...state,
        getMarketItemLoading: true,
        getMarketItemData: null,
        getMarketItemFailData: null,
    })),
    on(actions.getMarketItemSuccessAction, (state, payload) => ({
        ...state,
        getMarketItemLoading: null,
        getMarketItemData: payload,
        getMarketItemFailData: null,
    })),
    on(actions.getMarketItemFailAction, (state, payload) => ({
        ...state,
        getMarketItemLoading: null,
        getMarketItemData: null,
        getMarketItemFailData: payload,
    }))
);
