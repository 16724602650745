import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { CommonSandbox } from 'src/app/core/common/common.sandbox';
import { Location } from "@angular/common";
import { MobileCheckService } from '../../../app/core/mobile-check.service';
import { KeyboardService } from "../../core";

@Component({
  selector: 'main-tool-bar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolBarComponent implements OnInit {
  @Input() path: string;
  @Input() breadcrumbs: any[];
  @Input() transparent: boolean;
  @Input() flagBackPage: boolean = false;
  @Input() flagChangeButtonsPutCamera: boolean = false;
  @Input() flagButtonPutKeyboard: boolean = false;
  @Output() cameraMlkitClicked: EventEmitter<void> = new EventEmitter<void>();

  isMobile: boolean;
  iconName;
  homePage: boolean;
  userData;
  keyboardButtonEnabled: boolean = this.keyboardService.isEnabled();

  constructor(
    private router: Router,
    private menuController: MenuController,
    public commonSandbox: CommonSandbox,
    private location: Location,
    private mobileCheckService: MobileCheckService,
    public menu: MenuController,
    private keyboardService: KeyboardService,
  ) {
      this.userData = JSON.parse(sessionStorage.getItem('userData'));

      if (sessionStorage.getItem('iconMenu') === 'assets/icon/menu-left.svg') {
          this.iconName = 'assets/icon/menu-right.svg';
      } else {
          this.iconName = 'assets/icon/menu-left.svg';
      }
  }

  ngOnInit() {
   this.isMobile = this.mobileCheckService.checkIsMobile();
   this.homePage = this.router.url === '/home';
  }

  toggleMenu(): void {
    if (this.isMobile) {
      this.menuController.toggle('start').then();
    }
  }

  toggleSidebar(): void {
    this.commonSandbox.sidebarToggle$.next(1);
    if (sessionStorage.getItem('iconMenu') === 'assets/icon/menu-left.svg') {
        this.iconName = 'assets/icon/menu-right.svg';
    } else {
        this.iconName = 'assets/icon/menu-left.svg';
    }
  }

  logout(): void {
    sessionStorage.clear();
    this.router.navigate(['/logout']);
  }

  goPreviousPage() {
      this.location.back();
  }

  handleBreadcrumbClick(last: boolean): void {
      if (last && this.breadcrumbs.length > 1) {
          this.goPreviousPage();
      }
  }

  async openUserPage() {
      await this.router.navigate(['/user']);
  }

  cameraMlkit() {
      this.cameraMlkitClicked.emit();
  }

  onActiveKeyboard() {
      this.keyboardButtonEnabled = this.keyboardService.isEnabled();
      if (this.keyboardButtonEnabled === true) {
          this.keyboardService.disabled();
          this.keyboardButtonEnabled = false;
      } else {
          this.keyboardService.enabled();
          this.keyboardButtonEnabled = true;
      }
  }
}
