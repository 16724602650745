export namespace TransportModel {

    export interface Transport {
        name: string;
        reference: number;
        internalLogistic: boolean;
        address: string;
        phone: string;
        expeditionTransports: any[];
    }

    export interface TransportAgencies {
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        reference: string;
        internalLogistic: boolean;
        address: string;
        phone: string;
    }

    interface Pagination {
        page: number;
        lastPage: number;
        limit: number;
        count: number;
    }

    export interface GetTransportAgenciesFilters {
        name: string[];
        address: string[];
        phone: string[];
    }

    export interface GetTransportAgencies {
        records: TransportAgencies[];
        pagination: Pagination;
    }

    export interface ItemModel {
        id: number;
        createdAt: string;
        deletedAt: string;
        name: string;
        reference: string;
        internalLogistic: boolean;
        address: string;
        phone: string;
    }

    export interface TransportWithQuantity {
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        reference: number;
        internalLogistic: boolean;
        address: string;
        phone: string;
        quantity: number;
    }

    export enum TypeFilter {
        Name = 'name',
        Address = 'address',
        Phone = 'phone',
    }
}
