import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpMethod } from './http-method';

interface IHttpOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    responseType?;
}

export class ApiBase {
    constructor(
        private readonly name: string,
        private readonly http: HttpClient,
        private readonly baseUrl: string
    ) {}

    submit<Res = any>(
        urlPath: string,
        method: HttpMethod,
        body?: any,
        options: IHttpOptions = {}
    ) {
        // console.log('URL PATH --> ', urlPath, ' BASE URL --> ', this.baseUrl);
        const urlHref = new URL(urlPath, this.baseUrl).href;
        // console.log('URL --> ', urlHref);
        const logReqPath = `[${method}](${urlHref})`;
        const now = Date.now();

        // 2do:
        // logging should be optional
        // console.log(`${this.name} requests ${logReqPath}`);

        return this.http
            .request<Res>(method, urlHref, {
                body,
                // observe?: 'body' | 'events' | 'response';
                observe: 'body',
                responseType: 'json',
                ...options,
            })
            .pipe(
                tap(() => {
                    const duration = Date.now() - now;
                    console.log(
                        `${this.name}: done requesting ${logReqPath} in ${duration} ms`
                    );
                })
            );
    }

    get<Res = any>(url: string, options?: IHttpOptions) {
        return this.submit<Res>(url, 'get', null, options);
    }

    post<Res = any>(url: string, body?: any, options?: IHttpOptions) {
        return this.submit<Res>(url, 'post', body, options);
    }

    put<Res = any>(url: string, body?: any, options?: IHttpOptions) {
        return this.submit<Res>(url, 'put', body, options);
    }

    patch<Res = any>(url: string, body?: any, options?: IHttpOptions) {
        return this.submit<Res>(url, 'patch', body, options);
    }

    delete<Res = any>(url: string, options?: IHttpOptions) {
        return this.submit<Res>(url, 'delete', null, options);
    }
}
