import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CatalogSandbox } from 'src/app/core/catalog/sandbox';
import { LockContainerComponent } from '../dialogs/lock-container/lock-container.component';
import { MgaService } from "../../core/mga.service";
import { getToken } from "../helper-functions/helper-functions";

@Component({
    selector: 'warehouse-map',
    templateUrl: './warehouse-map.component.html',
    styleUrls: ['./warehouse-map.component.scss'],
})
export class WarehouseMapComponent implements OnInit, OnDestroy {

    @Input() columns: number;
    @Input() rows: number;
    @Input() rackId: number;
    @Input() data;
    @Input() screenLogistic;
    @Input() locationsWithCheckbox;

    rowTitle = [];

    mainData = [];

    @Output() selectedItem: EventEmitter<any> = new EventEmitter();

    containers: any[] = [];

    selectedCols: any[] = [];
    locationsSelected: any = {};
    @Output() selectedItemsChanged: EventEmitter<any[]> = new EventEmitter();

    constructor(
        public catalogSandbox: CatalogSandbox,
        private modalCtrl: ModalController,
        public service: MgaService,
    ) {}

    ngOnInit(): void {
        this.rowTitle = [
            '',
            ...new Array(this.columns).fill(0).map((x, i) => i + 1),
            '',
        ];

        this.mainData = new Array(this.rows).fill(
            new Array(this.columns).fill(false)
        );

        this.mainData = this.mainData.map(row =>
            row.map(el => this.getColor())
        );

        if (this.rackId) {
            this.fetchContainerData();
        }
    }

    private async fetchContainerData() {
        const qb = RequestQueryBuilder.create();

        qb.setFilter({ field: 'rackId', operator: '$eq', value: this.rackId });
        qb.setLimit(this.data.totalContainers);
        const params = qb.query(false);

        const token = await getToken();
        this.service.api.catalog.container.list('?' + params, token).subscribe(res => {
            if (res && res.data) {
                res.data.map(el => {
                    if (el.column && el.row) {
                        this.mainData[el.row - 1][el.column - 1] = el;
                    }
                });
            }
        })
    }

    async selectItem(col) {
        this.selectedItem.emit(col);

        if(!this.screenLogistic){
            if (!col.id) {
                return;
            }

            const modal = await this.modalCtrl.create({
                component: LockContainerComponent,
                cssClass: 'custom',
                componentProps: {
                    container: col,
                },
            });
            modal.present();

            const res = await modal.onWillDismiss();
            if (res && res.data) {
                if (res.data.id === col.id && res.data.locked !== col.locked) {
                    this.fetchContainerData();
                }
            }
        }

        if(this.locationsWithCheckbox){
            this.locationsSelected[col.id] = !this.locationsSelected[col.id];

            if (this.locationsSelected[col.id]) {
                this.selectedCols.push(col);
            } else {
                const index = this.selectedCols.findIndex(item => item.id === col.id);
                if (index !== -1) {
                    this.selectedCols.splice(index, 1);
                }
            }
            this.selectedItemsChanged.emit(this.selectedCols);
        }
    }

    selectAllItems() {
        const areAllSelected = this.areAllItemsSelected();
        this.locationsSelected = {};
        this.selectedCols = [];

        if (!areAllSelected) {
            this.mainData.forEach(row => {
                row.forEach(col => {
                    if (col) {
                        this.locationsSelected[col.id] = true;
                        this.selectedCols.push(col);
                    }
                });
            });
        }
        this.selectedItemsChanged.emit(this.selectedCols);
    }

    areAllItemsSelected(): boolean {
        let allSelected = true;
        this.mainData.forEach((row) => {
            row.forEach((col) => {
                if(col && !this.locationsSelected[col.id]) {
                    allSelected = false;
                }
            });
        });
        return allSelected;
    }

    ngOnDestroy(): void {
        this.selectedCols = [];
        this.selectedItemsChanged.emit(this.selectedCols);
    }

    private getColor(): boolean {
        return Math.random() > 0.5;
    }
}
