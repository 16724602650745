import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { PackageCancelledModel } from '../../../../models';

import IPackageCancelledInfo = PackageCancelledModel.IPackageCancelledInfo;
import PackageInfoStatus = PackageCancelledModel.ElPackageInfoStatus;
import IPackageCancelledLineInfo = PackageCancelledModel.IPackageCancelledLineInfo;

@Component({
    selector: 'suite-packages-warning-cancelled',
    templateUrl: './packages-warning-cancelled.component.html',
    styleUrls: ['./packages-warning-cancelled.component.scss'],
})
export class PackagesWarningCancelledComponent implements OnInit {

    packages: IPackageCancelledInfo[] = [];
    callbackOk: () => void;
    callbackCancel: () => void;

    constructor(private modalController: ModalController) {}

    displayedColumns: string[] = [
        'deliveryRequestExternalId',
        'container',
        'tracking',
        'status',
        'lines',
    ];

    ngOnInit() {}

    async dismiss() {
        this.callbackCancel();
        await this.modalController.dismiss();
        this.callbackCancel();
    }

    async generateManifest() {
        await this.callbackOk();
        await this.modalController.dismiss();
    }

    getStatusName(status: PackageInfoStatus) {
        switch (status) {
            case PackageInfoStatus.packed:
                return 'Embalado';
            case PackageInfoStatus.cancelled:
                return 'Cancelado';
            case PackageInfoStatus.returned:
                return 'Devuelto';
            default:
                return '';
        }
    }

    getLinesFormat(lines: IPackageCancelledLineInfo[]) {
        if (!(lines && lines.length > 0)) {
            return '-';
        }
        return lines.map(l => `${l.modelReference}-${l.sizeNumber}`).join(', ');
    }

}
