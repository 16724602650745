/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AuthService } from './auth.service';
import * as actions from './auth.actions';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthEffect {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private authService: AuthService
    ) {}

    doLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.doLoginAction),
            switchMap(state => {
                return this.authService.doLogin(state.params).pipe(
                    map(data => {
                        if (data.accessToken) {
                            return actions.doLoginActionSuccess(data);
                        } else {
                            return actions.doLoginActionFail(data);
                        }
                    }),
                    catchError(err => of(actions.doLoginActionFail(err)))
                );
            })
        )
    );

    getProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getProfileAction),
            switchMap(state => {
                return this.authService.doProfile().pipe(
                    map(data => {
                        return actions.getProfileSuccessAction(data);
                    }),
                    catchError(err => of(actions.getProfileFailAction(err)))
                );
            })
        )
    );
}
