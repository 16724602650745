export namespace PackageCancelledModel {

    export interface IPackageCancelledInfo {
        deliveryRequestExternalId: string;
        packageId: number;
        containerId: number;
        uniqueCodeTracking: string;
        lines: IPackageCancelledLineInfo[];
        status: ElPackageInfoStatus;
    }

    export interface IPackageCancelledLineInfo {
        deliveryRequestExternalLineId: string;
        deliveryRequestLineId: number;
        modelReference: string;
        sizeNumber: string;
    }

    export enum ElPackageInfoStatus {
        packed = 'packed',
        cancelled = 'cancelled',
        returned = 'returned',
    }

}
