import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertController } from '@ionic/angular';

@Injectable()
export class MessageService {
    constructor(
        private snackBar: MatSnackBar,
        private alertController: AlertController
    ) {}

    log(message: string): void {
        this.snackBar.open(message, 'Ok', {
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            duration: 2000,
        });
    }

    async presentConfirm(
        message: string,
        callbackOK,
        callbackCancel?,
        textOK?: string,
        textCancel?: string
    ) {
        const buttonsAlert: any = [
            {
                text: textCancel ? textCancel : 'Cancelar',
            },
            {
                text: textOK ? textOK : 'OK',
                handler: callbackOK,
            },
        ];

        if (callbackCancel) {
            buttonsAlert[0].handler = callbackCancel;
        }

        const alert = await this.alertController.create({
            header: 'Confirmar',
            message,
            buttons: buttonsAlert,
        });
        return await alert.present();
    }

    async presentWarning(message: string, callback) {
        const alert = await this.alertController.create({
            header: '¡Atención!',
            message,
            buttons: [
                {
                    text: 'Cerrar',
                    handler: callback,
                },
            ],
        });

        return await alert.present();
    }

    async presentInputConfirm(
        header: string,
        inputs: any[],
        callbackOK,
        callbackCancel?,
        textOK?: string,
        textCancel?: string
    ) {
        const buttonsAlert: any = [
            {
                text: textCancel ? textCancel : 'Cancelar',
            },
            {
                text: textOK ? textOK : 'Aceptar',
                handler: callbackOK,
            },
        ];

        if (callbackCancel) {
            buttonsAlert[0].handler = callbackCancel;
        }

        const alert = await this.alertController.create({
            header,
            buttons: buttonsAlert,
            inputs,
        });
        return await alert.present();
    }
}
