import { createAction, props } from '@ngrx/store';

//
export const syncSizesAction = createAction('sync sizes', props<any>());
export const syncSizesSuccessAction = createAction(
    'sync sizes success',
    props<any>()
);
export const syncSizesFailAction = createAction(
    'sync sizes fail',
    props<any>()
);
//
export const getWarehouseListAction = createAction(
    'get warehouse list',
    props<any>()
);
export const getWarehouseListSuccessAction = createAction(
    'get warehouse list success',
    props<any>()
);
export const getWarehouseListFailAction = createAction(
    'get warehouse list fail',
    props<any>()
);
//
export const getWarehouseItemAction = createAction(
    'get warehouse item',
    props<any>()
);
export const getWarehouseItemSuccessAction = createAction(
    'get warehouse item success',
    props<any>()
);
export const getWarehouseItemFailAction = createAction(
    'get warehouse item fail',
    props<any>()
);
//
export const updateWarehouseItemAction = createAction(
    'update warehouse item',
    props<any>()
);
export const updateWarehouseItemSuccessAction = createAction(
    'update warehouse item success',
    props<any>()
);
export const updateWarehouseItemFailAction = createAction(
    'update warehouse item fail',
    props<any>()
);
export const clearUpdateWarehouseItemStateAction = createAction(
    'update warehouse item clear state',
    props<any>()
);
//
export const syncWarehouseAction = createAction('sync warehouse', props<any>());
export const syncWarehouseSuccessAction = createAction(
    'sync warehouse success',
    props<any>()
);
export const syncWarehouseFailAction = createAction(
    'sync warehouse fail',
    props<any>()
);
//
export const getRackListAction = createAction('get rack list', props<any>());
export const getRackListSuccessAction = createAction(
    'get rack list success',
    props<any>()
);
export const getRackListFailAction = createAction(
    'get rack list fail',
    props<any>()
);
//
export const getRackItemAction = createAction('get rack item', props<any>());
export const getRackItemSuccessAction = createAction(
    'get rack item success',
    props<any>()
);
export const getRackItemFailAction = createAction(
    'get rack item fail',
    props<any>()
);
//
export const resizeRackItemAction = createAction(
    'resize rack item',
    props<any>()
);
export const resizeRackItemSuccessAction = createAction(
    'resize rack item success',
    props<any>()
);
export const resizeRackItemFailAction = createAction(
    'resize rack item fail',
    props<any>()
);
export const clearResizeRackItemStateAction = createAction(
    'resize rack item clear state',
    props<any>()
);
//
export const getContainerListAction = createAction(
    'get container list',
    props<any>()
);
export const getContainerListSuccessAction = createAction(
    'get container list success',
    props<any>()
);
export const getContainerListFailAction = createAction(
    'get container list fail',
    props<any>()
);
//
export const getContainerItemAction = createAction(
    'get container item',
    props<any>()
);
export const getContainerItemSuccessAction = createAction(
    'get container item success',
    props<any>()
);
export const getContainerItemFailAction = createAction(
    'get container item fail',
    props<any>()
);
//
export const setLockedContainerItemAction = createAction(
    'set locked container item',
    props<any>()
);
export const setLockedContainerItemSuccessAction = createAction(
    'set locked container item success',
    props<any>()
);
export const setLockedContainerItemFailAction = createAction(
    'set locked container item fail',
    props<any>()
);
export const clearSetLockedContainerItemStateAction = createAction(
    'set locked container item clear state',
    props<any>()
);
//
export const getSizesListAction = createAction('get sizes list', props<any>());
export const getSizesListSuccessAction = createAction(
    'get sizes list success',
    props<any>()
);
export const getSizesListFailAction = createAction(
    'get sizes list fail',
    props<any>()
);
//
export const getDomainSizesListAction = createAction(
    'get domain sizes list',
    props<any>()
);
export const getDomainSizesListSuccessAction = createAction(
    'get domain sizes list success',
    props<any>()
);
export const getDomainSizesListFailAction = createAction(
    'get domain sizes list fail',
    props<any>()
);
//
export const getPackingItemAction = createAction(
    'get packing item',
    props<any>()
);
export const getPackingItemFailAction = createAction(
    'get packing item fail',
    props<any>()
);
export const getPackingItemSuccessAction = createAction(
    'get packing item success',
    props<any>()
);
//
export const getPackingListAction = createAction(
    'get packing list',
    props<any>()
);
export const getPackingListFailAction = createAction(
    'get packing list fail',
    props<any>()
);
export const getPackingListSuccessAction = createAction(
    'get packing list success',
    props<any>()
);
//
export const updatePackingItemAction = createAction(
    'get packing item',
    props<any>()
);
export const updatePackingItemSuccessAction = createAction(
    'get packing item success',
    props<any>()
);
export const updatePackingItemFailAction = createAction(
    'get packing item fail',
    props<any>()
);
//
export const getMarketListAction = createAction(
    'get market list',
    props<any>()
);
export const getMarketListSuccessAction = createAction(
    'get market list success',
    props<any>()
);
export const getMarketListFailAction = createAction(
    'get market list fail',
    props<any>()
);
//
export const deleteMarketItemAction = createAction(
    'delete market item',
    props<any>()
);
export const deleteMarketItemSuccessAction = createAction(
    'delete market item success',
    props<any>()
);
export const deleteMarketItemFailAction = createAction(
    'delete market item fail',
    props<any>()
);
//
export const updateMarketItemAction = createAction(
    'update market item',
    props<any>()
);
export const updateMarketItemSuccessAction = createAction(
    'update market item success',
    props<any>()
);
export const updateMarketItemFailAction = createAction(
    'update market item fail',
    props<any>()
);
//
export const getMarketItemAction = createAction(
    'get market item',
    props<any>()
);
export const getMarketItemSuccessAction = createAction(
    'get market item success',
    props<any>()
);
export const getMarketItemFailAction = createAction(
    'get market item fail',
    props<any>()
);
