import { HttpClient } from '@angular/common/http';
import { ApiBase } from './api-base';

export class ApiMiddleware extends ApiBase {
    constructor(http: HttpClient, baseUrl: string) {
        super('MIDDLEWARE', http, baseUrl);
    }

    readonly marketplaces = Object.freeze({
        searchProductCatalog: (params = '') =>
            this.post(`product/search`, params),
        getMarketplaces: () =>
            this.get(`market`),
        reportsMarketplaces: (report) =>
            this.post(`oddjob/launch`, report),
        updateData: (body) =>
            this.post(`product/remap-rule-filter`, body),
        getMarketProductProperty: (params) =>
            this.get(`market/product-property${params}`),
        postUpdateZalandoSizeMapWithApi: () =>
            this.post(`zalando-size-map/update-with-api`),
        getZalandoSizeMap: (params) =>
            this.get(`zalando-size-map${params}`),
        postUpdateZalandoSizeMap: (sizeDomainId, body) =>
            this.post(`zalando-size-map/update-size/${sizeDomainId}`, body),
        getMarketFeatures: (market = '') =>
            this.get(`market/product-property/features/by-market/${market}`),
        getMarketBrands: (market = '') =>
            this.get(`market/product-property/brands/by-market/${market}`),
        getMarketSizes: (market = '') =>
            this.get(`market/product-property/sizes/by-market/${market}`),
        getMarketColors: (market = '') =>
            this.get(`market/product-property/colors/by-market/${market}`),
        getMarketSeasons: (market = '') =>
            this.get(`market/product-property/seasons/by-market/${market}`),
        searchProductWithVariationsCatalog: (params) =>
            this.post(`product/search-with-variations`, params),
        getAllProductCatalog: (filters) =>
            this.post(`product/search`, filters),
        updateCategoriesMinPrices: (params) =>
            this.post(`product/remap-for-market`, params),
        getReports: (params) =>
            this.get(`oddjob${params}`),
    });
}
