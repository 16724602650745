import * as JsBarcode from 'jsbarcode';
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppSettingsModel, PrinterModel } from '../models';

import { IntermediaryService } from './intermediary';
import { SettingsService } from './settings.service';

import ParamsPrintBase64 = PrinterModel.ParamsPrintBase64;
import ParamsPrint = PrinterModel.ParamsPrint;
import Print = PrinterModel.Print;

declare let cordova: any;

@Injectable({
    providedIn: 'root',
})
export class PrinterService {
    private static readonly MAX_PRINT_ATTEMPTS = 5;
    public stampe$: Subject<boolean> = new Subject();
    private address: string;
    private postPrintUrl = '/api/print';
    private printServiceAddress: string = null;

    constructor(
        private intermediaryService: IntermediaryService,
        private settingsService: SettingsService,
        private http: HttpClient
    ) {}

    printLabelBarcode(products): any {
        let dataToPrint;
        const referencesToPrint = [];
        products.map(product => {
            dataToPrint = this.processProductToPrintTagBarcode(product);
            referencesToPrint.push(dataToPrint);
        });
        return referencesToPrint;
    }

    private getTextToPrinter(printOptions: Print) {
        console.debug(
            'PRINT::getTextToPrinter 1 [' + new Date().toJSON() + ']',
            printOptions
        );
        const toPrint = '';
        let toPrintReturn = '';

        if (printOptions.text) {
            for (let i = 0; i < printOptions.text.length; i++) {
                toPrintReturn += this.addTextToPrint(
                    toPrint,
                    printOptions.text[i],
                    printOptions
                );
            }
        } else {
            toPrintReturn += this.addTextToPrint(
                toPrint,
                printOptions.product.productShoeUnit.reference,
                printOptions
            );
        }
        console.debug(
            'PRINT::getTextToPrinter 2 [' + new Date().toJSON() + ']',
            { printOptions, toPrintReturn }
        );
        return toPrintReturn;
    }

    /**
     * Turn an array of objects into string ready to be printed
     *
     * @param options - the options to be converted in the needed string
     */
    buildString(options: Array<Print>): string {
        console.debug(
            'PRINT::buildString 1 [' + new Date().toJSON() + ']',
            options
        );
        let strToPrint = '';
        /**If need separator between labels */
        const separator = '';
        options.forEach(option => {
            strToPrint += this.getTextToPrinter(option) + separator;
        });
        console.debug(
            'PRINT::buildString 2 [' + new Date().toJSON() + ']',
            strToPrint
        );
        console.log('ZPL PRODUCT ---> ',strToPrint);
        return strToPrint;
    }

    public processProductToPrintTagPrice(
        dataToProcess: any | Array<any>,
        isNewProduct = false,
        sizeId = 0
    ): { valuePrint; options } {
        console.debug(
            'PRINT::processProductToPrintTagPrice 1 [' +
                new Date().toJSON() +
                ']',
            dataToProcess
        );
        const dictionaryOfCaseTypes = {
            'labelInfoProduct': LabelTypes.LABEL_INFO_PRODUCT,
            'labelPriceWithoutTarif': LabelTypes.LABEL_PRICE_WITHOUT_TARIF,
            'labelPriceWithoutTarifOutlet': LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET,
            'labelPriceWithTarifWithoutDiscount': LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT,
            'labelPriceWithTarifWithoutDiscountOutlet': LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET,
            'labelPriceWithTarifWithDiscount': LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT,
            'labelPriceWithTarifWithDiscountOutlet': LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT_OUTLET,
            'labelPriceWithoutTarifModul': LabelTypes.LABEL_PRICE_WITHOUT_TARIF_MODUL,
        };

        let options: Array<PrinterModel.Print> = [];
        let arrayPricesToProcess: Array<any> = [];

        if (!Array.isArray(dataToProcess)) {
            arrayPricesToProcess.push(dataToProcess);
        } else {
            arrayPricesToProcess = dataToProcess;
        }
        console.debug(
            'PRINT::processProductToPrintTagPrice 2 [' +
                new Date().toJSON() +
                ']',
            arrayPricesToProcess
        );
        /** Iterate and build object to print */
        for (let iPrice in arrayPricesToProcess) {
            if (arrayPricesToProcess.hasOwnProperty(iPrice)) {
                let price = arrayPricesToProcess[iPrice];
                for (let filterKey in price.value) {
                    let labelType =
                        price.value[filterKey].priceFilter.labelType;
                    price = this.processTagPricesAndChangeTemplate(
                        price,
                        labelType
                    );

                    let printOptions: PrinterModel.Print = {
                        type: dictionaryOfCaseTypes[labelType],
                        product: {
                            productShoeUnit: {
                                model: {
                                    id: price.value[filterKey].model.id,
                                    reference:
                                        price.value[filterKey].model.reference,
                                    name: price.value[filterKey].model?.name,
                                    color: {
                                        name:
                                            price.value[filterKey].model.color?.name,
                                    },
                                    detailColor:
                                        price.value[filterKey].model
                                            .detailColor,
                                    lifestyle:
                                        price.value[filterKey].model?.lifestyle,
                                    category:
                                        price.value[filterKey].model?.category,
                                },
                                manufacturer: {
                                    name:
                                        price.value[filterKey].model.brand?.name,
                                },
                            },
                        },
                        price: {
                            id: price.value[filterKey]?.priceFlter?.tariffId,
                            hash: price.value[filterKey]?.priceFilter?.hash,
                            percent: price.value[filterKey].priceFilter?.percent,
                            tariffId: price.value[filterKey]?.priceFilter?.tariffId,
                            percentOutlet: price.value[filterKey].priceFilter?.percentOutlet,
                            totalPrice: price.value[
                                filterKey
                            ].priceFilter.totalPrice?.replace('.', ','),
                            priceOriginal: price.value[
                                filterKey
                            ].priceFilter.priceOriginal?.replace('.', ','),
                            priceDiscount: price.value[
                                filterKey
                            ].priceFilter.priceDiscount?.replace('.', ','),
                            priceDiscountOutlet: price.value[
                                filterKey
                            ].priceFilter.priceDiscountOutlet?.replace('.', ','),
                            typeLabel: labelType,
                            numRange: price.value[filterKey]?.priceFilter?.numRange
                                ? price.value[filterKey]?.numRange
                                : 0,
                            valueRange: this.getCorrectValueRange(price),
                        },
                    };
                    if (isNewProduct) {
                        printOptions.product.productShoeUnit.model.id =
                            price.model.id;
                        printOptions.sizeId = sizeId;
                    }
                    /** Build the array for obtain the string to send to printer */
                    options.push(printOptions);
                }
            }
        }
        console.debug(
            'PRINT::processProductToPrintTagPrice 3 [' +
                new Date().toJSON() +
                ']',
            options
        );
        if (options) {
            /** Obtain the string from options */
            return { valuePrint: this.buildString(options), options: options };
        } else {
            return null;
        }
    }

    private getCorrectValueRange(dataToObtainValueRange): string {
        console.debug(
            'PRINT::getCorrectValueRange 1 [' + new Date().toJSON() + ']',
            dataToObtainValueRange
        );
        if (dataToObtainValueRange && dataToObtainValueRange.rangesNumbers) {
            let rangesNumbers = dataToObtainValueRange.rangesNumbers;
            if (
                rangesNumbers.sizeRangeNumberMin &&
                rangesNumbers.sizeRangeNumberMax &&
                rangesNumbers.sizeRangeNumberMin ==
                    rangesNumbers.sizeRangeNumberMax
            ) {
                console.debug(
                    'PRINT::getCorrectValueRange 2 [' +
                        new Date().toJSON() +
                        ']',
                    String(rangesNumbers.sizeRangeNumberMin)
                );
                return String(rangesNumbers.sizeRangeNumberMin);
            } else {
                console.debug(
                    'PRINT::getCorrectValueRange 3 [' +
                        new Date().toJSON() +
                        ']',
                    String(rangesNumbers.sizeRangeNumberMin) +
                        '-' +
                        String(rangesNumbers.sizeRangeNumberMax)
                );
                return (
                    String(rangesNumbers.sizeRangeNumberMin) +
                    '-' +
                    String(rangesNumbers.sizeRangeNumberMax)
                );
            }
        } else {
            return '';
        }
    }

    public processProductToPrintTagBarcode(
        dataToProcess? /*: (ProductModel.Product | Array<ProductModel.Product>)*/
    ): string {
        console.debug(
            'PRINT::processProductToPrintTagBarcode 1 [' +
                new Date().toJSON() +
                ']',
            dataToProcess
        );
        const options: Array<Print> = [];

        const printOptions: Print = {
            type: LabelTypes.LABEL_INFO_PRODUCT,
            product: {
                productShoeUnit: {
                    reference: dataToProcess.product.reference,
                    size: {
                        name: dataToProcess.product.size
                            ? dataToProcess.product.size.name
                            : '',
                    },
                    manufacturer: {
                        name: dataToProcess.product.manufacturer
                            ? dataToProcess.product.manufacturer.name
                            : dataToProcess.product.model.brand
                            ? dataToProcess.product.model.brand.name
                            : '',
                    },
                    model: {
                        name: dataToProcess.product.model
                            ? dataToProcess.product.model.name
                            : '',
                        reference: dataToProcess.product.model
                            ? dataToProcess.product.model.reference
                            : '',
                        color: {
                            name: dataToProcess.product.model.color
                                ? dataToProcess.product.model.color.name
                                : '',
                        },
                        season: {
                            name: dataToProcess.product.season
                                ? dataToProcess.product.season.name
                                : dataToProcess.product.model.season
                                ? dataToProcess.product.model.season.name
                                : '',
                        },
                    },
                },
            },
        };
        /** Build the array for obtain the string to send to printer */
        options.push(printOptions);

        console.debug(
            'PRINT::processProductToPrintTagBarcode 3 [' +
                new Date().toJSON() +
                ']',
            options
        );
        if (options) {
            /** Obtain the string from options */
            return this.buildString(options);
        } else {
            return null;
        }
    }

    private addTextToPrint(toPrint, stringToBarcode, printOptions) {
        console.debug('PRINT::addTextToPrint 1 [' + new Date().toJSON() + ']', {
            toPrint,
            stringToBarcode,
            printOptions,
        });
        switch (printOptions.type) {
            case LabelTypes.LABEL_BARCODE_TEXT: // Test with Barcode and string of data below
                let size = '';
                if (stringToBarcode.length >= 11) {
                    size = '45';
                } else if (stringToBarcode.length >= 8) {
                    size = '80';
                } else if (stringToBarcode.length >= 6) {
                    size = '100';
                } else {
                    // tested for >=4 but will use as default measure
                    size = '120';
                }

                toPrint =
                    '^XA^FO' +
                    size +
                    ',30^BY2\n' +
                    '^A0N,60,60\n' +
                    // ^BC orientation, height, interpretation line, i.l. above code, check digit, mode (N/U/A/D)
                    '^BCN,100,Y,N,N\n' +
                    '^FD' +
                    stringToBarcode +
                    '^XZ\n';
                break;
            case LabelTypes.LABEL_INFO_PRODUCT: // Tag with product reference, size and model details
                /*
                 * Original Code
                ^XA^LH30,5^CI27^AVN^FO1,5^FD@item^FS^AVN^FO0,15^FB325,1,0,R,0^FD@talla^FS^ABN^FO3,70^FD@brand^FS^ABN^FO3,85^FD@style^FS^ABN^FO3,100^FD@detcol^FS^AQN^FO0,110^FB325,1,0,R,0^FD@season^FS^FO10,125^BY2,3.0^BCN,40,Y,N,N^FD>;@barcode^FS^XZ
                */
                toPrint =
                    '^XA^CI28^LH30,5^AVN^FO1,5^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^AVN^FO0,15^FB325,1,0,R,0^FD';
                /* Commented code for Nutribiotica, the "size" is too large for the label. */
                /*if (printOptions.product.productShoeUnit?.size?.name) {
                    toPrint += printOptions.product.productShoeUnit?.size?.name;
                }*/
                toPrint += '^FS^ABN^FO3,70^FD';
                if (
                    printOptions.product.productShoeUnit.manufacturer &&
                    printOptions.product.productShoeUnit.manufacturer?.name
                ) {
                    toPrint +=
                        printOptions.product.productShoeUnit.manufacturer?.name;
                }
                toPrint += '^FS^ABN^FO3,85^FD';
                if (printOptions.product.productShoeUnit.model?.name) {
                    toPrint += printOptions.product.productShoeUnit.model?.name;
                }
                toPrint += '^FS^ABN^FO3,100^FD';
                if (printOptions.product.productShoeUnit.model.color.name) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model.color?.name;
                }
                toPrint += '^FS^AQN^FO0,110^FB325,1,0,R,0^FD';
                if (
                    printOptions.product.productShoeUnit.model?.season &&
                    printOptions.product.productShoeUnit.model?.season?.name
                ) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model?.season?.name;
                }
                toPrint +=
                    '^FS^FO10,125^BY2,3.0^BCN,40,Y,N,N^FD>;' +
                    printOptions.product.productShoeUnit.reference +
                    '^FS^XZ';
                break;
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF: // Tag with product price
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF_MODUL:
                toPrint =
                    '^XA^CI28^LH28,0^AFN^FO0,30^FB320,1,0,R,0^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^ADN^FO10,95^FB320,1,0,R,0^FD';
                toPrint += '^FS^AVN^FO0,55^FB320,1,0,C,0^FD';
                if (printOptions.price.priceOriginal) {
                    toPrint += printOptions.price.priceOriginal + ' €';
                }
                toPrint += '^FS^AP^FO0,115^GB320,0,3^FS^AQ^FWB^FO5,123^FD';
                if (
                    printOptions.product.productShoeUnit.model.lifestyle &&
                    printOptions.product.productShoeUnit.model.lifestyle
                        .avelonId &&
                    printOptions.product.productShoeUnit.model.category
                ) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model.lifestyle.avelonId.substring(
                            0,
                            1
                        ) +
                        printOptions.product.productShoeUnit.model.category
                            .avelonId;
                }
                toPrint +=
                    '^FS^LH28,0^FWN^FO40,125^BY2,3.0^BCN,50,N,N,N^FD' +
                    printOptions.product.productShoeUnit.model.reference.slice(
                        0,
                        6
                    ) +
                    '^FS^AAN^FO0,145^FB330,1,0,R,0^FD' +
                    printOptions.product.productShoeUnit.manufacturer?.name +
                    '^FS^AAN^FO10,190^FB315,1,0,L,0^FD' +
                    printOptions.product.productShoeUnit.model?.name +
                    '^FS^AAN^FO0,190^FB315,1,0,R,0^FD';
                if (printOptions.product.productShoeUnit.model.detailColor) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model.detailColor;
                }
                toPrint +=
                    '^FS^ADN^FO0,125^FB330,1,0,R,0^FD' +
                    '^FS^ADN^FO0,157^FB330,1,0,R,0^FD';
                if (printOptions.price.valueRange) {
                    toPrint += printOptions.price.valueRange;
                }
                toPrint += '^FS^XZ';
                break;
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT: // Tag with product price
                /*
                 * Original Code
                ^XA^LH28,0^CI27^AFN^FO0,30^FB320,1,0,R,0^FD@iniciales^FS^ADN^FO10,95^FB320,1,0,R,0^FD@porcent^FS^AVN^FO0,55^FB320,1,0,C,0^FD@saleprice1 €^FS^AP^FO0,115^GB320,0,3^FS^AQ^FWB^FO5,123^FD@item^FS^LH28,0^FWN^FO40,125^BY2,3.0^BCN,50,N,N,N^FD@barcode^FS^AAN^FO0,145^FB330,1,0,R,0^FD@brand^FS^AAN^FO10,190^FB315,1,0,L,0^FD@style^FS^AAN^FO0,190^FB315,1,0,R,0^FD@detcol^FS^ADN^FO0,125^FB330,1,0,R,0^FD@siglas^FS^ADN^FO0,157^FB330,1,0,R,0^FD@TagSzRng^FS^XZ
                */
                toPrint =
                    '^XA^CI28^LH28,0^AFN^FO0,30^FB320,1,0,R,0^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^ADN^FO10,95^FB320,1,0,R,0^FD';
                toPrint += '^FS^AVN^FO0,55^FB320,1,0,C,0^FD';
                if (printOptions.price.priceOriginal) {
                    toPrint += printOptions.price.priceOriginal + ' €';
                }
                toPrint += '^FS^AP^FO0,115^GB320,0,3^FS^AQ^FWB^FO5,123^FD';
                if (
                    printOptions.product.productShoeUnit.model.lifestyle &&
                    printOptions.product.productShoeUnit.model.lifestyle
                        .reference &&
                    printOptions.product.productShoeUnit.model.category
                ) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model.lifestyle.avelonId.substring(
                            0,
                            1
                        ) +
                        printOptions.product.productShoeUnit.model.category
                            .avelonId;
                }
                toPrint +=
                    '^FS^LH28,0^FWN^FO40,125^BY2,3.0^BCN,50,N,N,N^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^AAN^FO0,145^FB330,1,0,R,0^FD' +
                    printOptions.product.productShoeUnit.manufacturer?.name +
                    '^FS^AAN^FO10,190^FB315,1,0,L,0^FD' +
                    printOptions.product.productShoeUnit.model?.name +
                    '^FS^AAN^FO0,190^FB315,1,0,R,0^FD';
                if (printOptions.product.productShoeUnit.model.detailColor) {
                    toPrint +=
                        printOptions.product.productShoeUnit.model.detailColor;
                }
                toPrint +=
                    '^FS^ADN^FO0,125^FB330,1,0,R,0^FD' +
                    '^FS^ADN^FO0,157^FB330,1,0,R,0^FD';
                if (printOptions.price.valueRange) {
                    toPrint += printOptions.price.valueRange;
                }
                toPrint += '^FS^XZ';
                break;
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT: // Tag with current product price and previous price
                /*
                 * Original Code
                ^XA^LH28,0^CI27^AFN^FO0,30^FB320,1,0,R,0^FD@iniciales^FS^ADN^FO8,108^FB320,1,0,R,0^FD@porcent^FS^AEN^FO10,30^FB310,1,0,L,0^FD@saleprice1€^FS^FO25,25^GD90,30,8,B,L^FS^FO25,25^GD90,30,8,B,R^FS^AVN^FO0,58^FB340,1,0,C,0^FD@saleprice2 €^FS^AP^FO0,123^GB335,0,3^FS^AQ^FWB^FO5,130^FD@item^FS^FWN^FO40,135^BY2,3.0^BCN,50,N,N,N^FD@barcode^FS^ADN^FO0,130^FB330,1,0,R,0^FD@siglas^FS^AAN^FO0,155^FB330,1,0,R,0^FD@brand^FS^ADN^FO0,175^FB330,1,0,R,0^FD@TagSzRng^FS^XZ
                */
                toPrint =
                    '^XA^CI28^LH28,0^AFN^FO0,30^FB320,1,0,R,0^FD^FS^ADN^FO8,108^FB320,1,0,R,0^FD';
                if (printOptions.price.percent) {
                    toPrint += printOptions.price.percent + '%';
                }
                toPrint += '^FS^AEN^FO10,30^FB310,1,0,L,0^FD';
                if (printOptions.price.priceOriginal) {
                    toPrint += printOptions.price.priceOriginal + ' €';
                }
                toPrint +=
                    '^FS^FO25,25^GD90,30,8,B,L^FS^FO25,25^GD90,30,8,B,R^FS^AVN^FO0,58^FB340,1,0,C,0^FD';
                if (printOptions.price.priceDiscount) {
                    toPrint += printOptions.price.priceDiscount + '€';
                }
                toPrint +=
                    '^FS^AP^FO0,123^GB335,0,3^FS^AQ^FWB^FO5,130^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^FWN^FO40,135^BY2,3.0^BCN,50,N,N,N^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^AAN^FO0,155^FB330,1,0,R,0^FD';
                if (
                    printOptions.product.productShoeUnit.manufacturer &&
                    printOptions.product.productShoeUnit.manufacturer?.name
                ) {
                    toPrint +=
                        printOptions.product.productShoeUnit.manufacturer?.name;
                }
                toPrint += '^FS^ADN^FO0,175^FB330,1,0,R,0^FD';
                if (printOptions.price.valueRange) {
                    toPrint += printOptions.price.valueRange;
                }
                toPrint += '^FS^XZ';
                break;
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET: // Tag with original product pvp and product pvp for outlet
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET: // Tag with original product pvp and product pvp for outlet
                /*
                 * Original Code
                ^XA^LH28,0^CI27^AFN^FO0,30^FB320,1,0,R,0^FD@iniciales^FS^AFN^FO0,30^FB310,1,0,L,0^FDPVP:@saleprice1€^FS^AUN^FO0,80^FB335,1,0,R,0^FD@saleprice4 €^FS^ARN^FO0,80^FB340,1,0,L,0^FDPVP Outlet:^FS^AP^FO0,127^GB335,0,3^FS^AQ^FWB^FO5,135^FD@item^FS^FWN^FO40,155^BY2,3.0^BCN,50,N,N,N^FD@barcode^FS^ADN^FO0,137^FB330,1,0,R,0^FD@siglas^FS^ADN^FO0,155^FB330,1,0,R,0^FD@porcent^FS^ADN^FO0,175^FB330,1,0,R,0^FD@TagSzRng^FS^XZ
                */
                toPrint = '^XA^CI28^LH28,0^AFN^FO0,30^FB320,1,0,R,0^FD';
                // toPrint += 'TagSzRng';
                toPrint += '^FS^AFN^FO0,30^FB310,1,0,L,0^FD' + 'PVP:';
                if (printOptions.price.priceOriginal) {
                    toPrint += printOptions.price.priceOriginal + ' €';
                }
                toPrint += '^FS^AUN^FO0,80^FB335,1,0,R,0^FD';
                if (printOptions.price.priceDiscount) {
                    toPrint += printOptions.price.priceDiscount + ' €';
                }
                toPrint +=
                    '^FS^ARN^FO0,80^FB340,1,0,L,0^FD' +
                    'PVP Outlet:' +
                    '^FS^AP^FO0,127^GB335,0,3^FS^AQ^FWB^FO5,135^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^FWN^FO40,155^BY2,3.0^BCN,50,N,N,N^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^ADN^FO0,137^FB330,1,0,R,0^FD^FS^ADN^FO0,155^FB330,1,0,R,0^FD';
                if (printOptions.price.percent) {
                    toPrint += printOptions.price.percent + '%';
                }
                toPrint += '^FS^ADN^FO0,175^FB330,1,0,R,0^FD';
                if (printOptions.price.valueRange) {
                    toPrint += printOptions.price.valueRange;
                }
                toPrint += '^FS^XZ';
                break;
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT_OUTLET: // Tag with original product pvp, product pvp for outlet and last product price
                /*
                 * Original Code
                ^XA^LH28,0^CI27^AFN^FO0,30^FB320,1,0,R,0^FD@iniciales^FS^AFN^FO0,30^FB310,1,0,L,0^FDPVP:@saleprice1€^FS^AFN^FO0,60^FB310,1,0,L,0^FDPVP Outlet:@saleprice4€^FS^ATN^FO0,90^FB335,1,0,R,0^FD@saleprice5 €^FS^ARN^FO0,100^FB340,1,0,L,0^FDÚltimo precio:^FS^AP^FO0,127^GB335,0,3^FS^AQ^FWB^FO5,135^FD@item^FS^FWN^FO40,155^BY2,3.0^BCN,50,N,N,N^FD@barcode^FS^ADN^FO0,137^FB330,1,0,R,0^FD@siglas^FS^ADN^FO0,155^FB330,1,0,R,0^FD@porcent^FS^ADN^FO10,175^FB320,1,0,R,0^FD@TagSzRng^FS^XZ
                */
                toPrint = '^XA^CI28^LH28,0^AFN^FO0,30^FB320,1,0,R,0^FD';
                toPrint += '^FS^AFN^FO0,30^FB310,1,0,L,0^FD' + 'PVP:';
                if (printOptions.price.priceOriginal) {
                    toPrint += printOptions.price.priceOriginal + ' €';
                }
                toPrint += '^FS^AFN^FO0,60^FB310,1,0,L,0^FD' + 'PVP Outlet:';
                if (printOptions.price.priceDiscount) {
                    toPrint += printOptions.price.priceDiscount + ' €';
                }
                toPrint += '^FS^ATN^FO0,90^FB335,1,0,R,0^FD';
                if (printOptions.price.priceDiscountOutlet) {
                    toPrint += printOptions.price.priceDiscountOutlet + ' €';
                }
                toPrint +=
                    '^FS^ARN^FO0,100^FB340,1,0,L,0^FD' +
                    'Último precio:' +
                    '^FS^AP^FO0,127^GB335,0,3^FS^AQ^FWB^FO5,135^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^FWN^FO40,155^BY2,3.0^BCN,50,N,N,N^FD' +
                    printOptions.product.productShoeUnit.model.reference +
                    '^FS^ADN^FO0,137^FB330,1,0,R,0^FD^FS^ADN^FO0,155^FB330,1,0,R,0^FD';
                if (printOptions.price.percentOutlet) {
                    toPrint += printOptions.price.percentOutlet + '%';
                }
                toPrint += '^FS^ADN^FO10,175^FB320,1,0,R,0^FD';
                if (printOptions.price.valueRange) {
                    toPrint += printOptions.price.valueRange;
                }
                toPrint += '^FS^XZ';
                break;
        }
        console.debug('PRINT::addTextToPrint 2 [' + new Date().toJSON() + ']', {
            toPrint,
            stringToBarcode,
            printOptions,
        });
        return toPrint;
    }

    public async openConnection(showAlert: boolean = false) {
        this.address = await this.getConfiguredAddress();
        console.debug(
            'PRINT::openConnection 1 [' + new Date().toJSON() + ']',
            this.address
        );
        return new Promise(resolve => {
            if (this.address) {
                if (cordova.plugins.zbtprinter) {
                    cordova.plugins.zbtprinter.openConnection(
                        this.address,
                        result => {
                            if (showAlert) {
                                this.intermediaryService.presentToastSuccess(
                                    'Conectado a la impresora',
                                    3750
                                );
                            }
                            console.debug(
                                'PRINT::openConnection 2 [' +
                                    new Date().toJSON() +
                                    ']',
                                result
                            );

                            resolve(true);
                        },
                        error => {
                            if (showAlert) {
                                this.intermediaryService.presentToastError(
                                    'No ha sido posible conectarse con la impresora',
                                    4000
                                );
                            }
                            console.debug(
                                'PRINT::openConnection 3 [' +
                                    new Date().toJSON() +
                                    ']',
                                error
                            );
                            resolve(false);
                        }
                    );
                } else {
                    if (showAlert) {
                        this.intermediaryService.presentToastError(
                            'No ha sido posible conectarse con la impresora',
                            4000
                        );
                    }
                    console.debug(
                        'PRINT::openConnection 4 [' + new Date().toJSON() + ']'
                    );
                    resolve(false);
                }
            } else {
                if (showAlert) {
                    this.intermediaryService.presentToastError(
                        'No está configurada la impresora',
                        4000
                    );
                }
                console.debug(
                    'PRINT::openConnection 5 [' + new Date().toJSON() + ']'
                );
                resolve(false);
            }
        });
    }

    public async closeConnection() {
        return new Promise<void>((resolve, reject) => {
            if (cordova.plugins.zbtprinter) {
                cordova.plugins.zbtprinter.closeConnection(
                    result => {
                        console.debug(
                            'PRINT::closeConnection 1 [' +
                                new Date().toJSON() +
                                ']',
                            result
                        );
                        resolve();
                    },
                    error => {
                        console.debug(
                            'PRINT::closeConnection 2 [' +
                                new Date().toJSON() +
                                ']',
                            error
                        );
                        reject();
                    }
                );
            } else {
                console.debug(
                    'PRINT::closeConnection 3 [' + new Date().toJSON() + ']'
                );
                reject();
            }
        });
    }

    private async getConfiguredAddress(): Promise<string> {
        console.debug(
            'PRINT::getConfiguredAddress 1 [' + new Date().toJSON() + ']'
        );
        return <Promise<string>>this.settingsService.getDeviceSettings().then(
            incomingDataObservable =>
                new Promise((resolve, reject) => {
                    console.debug(
                        'PRINT::getConfiguredAddress 2 [' +
                            new Date().toJSON() +
                            ']'
                    );
                    incomingDataObservable.subscribe(
                        (settings: AppSettingsModel.AppSettings) =>
                            resolve(settings.printerBluetoothMacAddress || '')
                    );
                })
        );
    }

    public async toPrintCommands(
        commandsToPrint,
        callbackSuccess?: () => any,
        callbackFail?: () => any,
        macAddress?
    ) {
        console.debug(
            'PRINT::toPrintCommands 1 [' + new Date().toJSON() + ']',
            { commandsToPrint, macAddress }
        );
        if (macAddress) {
            this.address = macAddress;
            console.debug(
                'PRINT::toPrintCommands 2 [' + new Date().toJSON() + ']',
                { commandsToPrint, macAddress: this.address }
            );
        } else {
            this.address = await this.getConfiguredAddress();
            console.debug(
                'PRINT::toPrintCommands 3 [' + new Date().toJSON() + ']',
                { commandsToPrint, macAddress: this.address }
            );
        }

        console.debug(
            'PRINT::toPrintCommands 4 [' + new Date().toJSON() + ']',
            { commandsToPrint, macAddress: this.address }
        );
        if (!this.address) {
            await this.connect();
            console.debug(
                'PRINT::toPrintCommands 5 [' + new Date().toJSON() + ']',
                { commandsToPrint, macAddress: this.address }
            );
        }

        console.debug(
            'PRINT::toPrintFromString 6 [' + new Date().toJSON() + ']',
            { commandsToPrint, macAddress: this.address }
        );
        if (this.address) {
            if (typeof cordova != 'undefined' && cordova.plugins.zbtprinter) {
                console.debug(
                    'PRINT::toPrintFromString 7 [' + new Date().toJSON() + ']',
                    { commandsToPrint, macAddress: this.address }
                );
                return new Promise((resolve, reject) => {
                    let printAttempts = 0;
                    console.debug(
                        'PRINT::toPrintFromString 8 [' +
                            new Date().toJSON() +
                            ']',
                        {
                            commandsToPrint,
                            macAddress: this.address,
                            printAttempts,
                        }
                    );
                    let tryToPrintFn = () => {
                        printAttempts++;
                        console.debug(
                            'PRINT::toPrintFromString 9 [' +
                                new Date().toJSON() +
                                ']',
                            {
                                commandsToPrint,
                                macAddress: this.address,
                                printAttempts,
                            }
                        );
                        cordova.plugins.zbtprinter.printWithConnection(
                            this.address,
                            commandsToPrint,
                            success => {
                                console.debug(
                                    'PRINT::toPrintFromString 10 [' +
                                        new Date().toJSON() +
                                        ']',
                                    {
                                        commandsToPrint,
                                        macAddress: this.address,
                                        printAttempts,
                                    }
                                );
                                this.stampe$.next(true);
                                if (callbackSuccess) {
                                    callbackSuccess();
                                }
                                resolve(true);
                            },
                            fail => {
                                console.debug(
                                    'PRINT::toPrintFromString 11 [' +
                                        new Date().toJSON() +
                                        ']',
                                    {
                                        commandsToPrint,
                                        macAddress: this.address,
                                        printAttempts,
                                    }
                                );
                                if (
                                    printAttempts >=
                                    PrinterService.MAX_PRINT_ATTEMPTS
                                ) {
                                    console.debug(
                                        'PRINT::toPrintFromString 12 [' +
                                            new Date().toJSON() +
                                            ']',
                                        {
                                            commandsToPrint,
                                            macAddress: this.address,
                                            printAttempts,
                                        }
                                    );
                                    this.intermediaryService.presentToastError(
                                        'No ha sido posible conectarse con la impresora',
                                        4000
                                    );
                                    if (callbackFail) {
                                        callbackFail();
                                    }
                                    resolve(false);
                                } else {
                                    console.debug(
                                        'PRINT::toPrintFromString 13 [' +
                                            new Date().toJSON() +
                                            ']',
                                        {
                                            commandsToPrint,
                                            macAddress: this.address,
                                            printAttempts,
                                        }
                                    );
                                    setTimeout(tryToPrintFn, 1000);
                                }
                            }
                        );
                    };
                    tryToPrintFn();
                });
            } else {
                console.debug(
                    'PRINT::toPrintFromString 14 [' + new Date().toJSON() + ']',
                    { commandsToPrint, macAddress: this.address }
                );
            }
        } else {
            console.debug(
                'PRINT::toPrintFromString 15 [' + new Date().toJSON() + ']',
                { commandsToPrint, macAddress: this.address }
            );
        }
    }

    public async connect() {
        console.debug('PRINT::connect 1 [' + new Date().toJSON() + ']');
        return new Promise<void>((resolve, reject) => {
            if (typeof cordova == 'undefined') {
                console.debug(
                    'PRINT::NOT CORDOVA [' + new Date().toJSON() + ']'
                );
                resolve();
            }
            if (cordova.plugins.zbtprinter) {
                cordova.plugins.zbtprinter.find(
                    result => {
                        let address = '';
                        if (typeof result == 'string') {
                            address = result;
                        } else {
                            address = result.address;
                        }
                        this.address = address;
                        console.debug(
                            'PRINT::connect 2 [' + new Date().toJSON() + ']'
                        );
                        resolve();
                    },
                    error => {
                        console.debug(
                            'PRINT::connect 3 [' + new Date().toJSON() + ']',
                            error
                        );
                        reject();
                    }
                );
            } else {
                console.debug('PRINT::connect 4 [' + new Date().toJSON() + ']');
                reject();
            }
        });
    }

    printTest(ip: string, parameters: ParamsPrint): Observable<any> {
        const url = 'http://' + ip + this.postPrintUrl;
        console.debug(
            'PrinterService::Print document: ',
            JSON.stringify({ url: this.postPrintUrl, body: parameters })
        );
        return this.http.post(url, parameters);
    }

    public async print(printOptions: Print, macAddress?: string) {
        console.debug('PRINT::print 1 [' + new Date().toJSON() + ']', {
            printOptions,
            macAddress,
        });
        printOptions.type = 0;

        if (macAddress) {
            this.address = macAddress;
        } else {
            this.address = await this.getConfiguredAddress();
        }
        console.debug('PRINT::print 2 [' + new Date().toJSON() + ']', {
            printOptions,
            macAddress: this.address,
        });
        if (this.address) {
            return await this.toPrint(printOptions);
        } else {
            console.debug('PRINT::print 3 [' + new Date().toJSON() + ']', {
                printOptions,
                macAddress: this.address,
            });
            return await this.connect().then(() => this.toPrint(printOptions));
        }
    }

    private async toPrint(printOptions: Print) {
        console.debug(
            'PRINT::toPrint 1 [' + new Date().toJSON() + ']',
            printOptions
        );
        // this.stampe$.next(true);
        if (this.address) {
            if (typeof cordova !== 'undefined' && cordova.plugins.zbtprinter) {
                let textToPrint = this.getTextToPrinter(printOptions);
                return new Promise<void>((resolve, reject) => {
                    let printAttempts = 0;
                    console.debug(
                        'PRINT::toPrint 2 [' + new Date().toJSON() + ']',
                        { printOptions, address: this.address, printAttempts }
                    );
                    // this.stampe$.next(true);
                    let tryToPrintFn = () => {
                        printAttempts++;
                        console.debug(
                            'PRINT::toPrint 3 [' + new Date().toJSON() + ']',
                            {
                                printOptions,
                                address: this.address,
                                printAttempts,
                            }
                        );
                        // this.stampe$.next(true);
                        cordova.plugins.zbtprinter.printWithConnection(
                            this.address,
                            textToPrint,
                            success => {
                                console.debug(
                                    'PRINT::toPrint 4 [' +
                                        new Date().toJSON() +
                                        ']',
                                    {
                                        printOptions,
                                        address: this.address,
                                        printAttempts,
                                        success,
                                    }
                                );
                                this.stampe$.next(true);
                                resolve();
                            },
                            fail => {
                                if (
                                    printAttempts >=
                                    PrinterService.MAX_PRINT_ATTEMPTS
                                ) {
                                    console.debug(
                                        'PRINT::toPrint 5 [' +
                                            new Date().toJSON() +
                                            ']',
                                        {
                                            printOptions,
                                            address: this.address,
                                            printAttempts,
                                        }
                                    );
                                    this.intermediaryService.presentToastError(
                                        'No ha sido posible conectarse con la impresora',
                                        4000
                                    );
                                    reject();
                                } else {
                                    console.debug(
                                        'PRINT::toPrint 6 [' +
                                            new Date().toJSON() +
                                            ']',
                                        {
                                            printOptions,
                                            address: this.address,
                                            printAttempts,
                                        }
                                    );
                                    setTimeout(tryToPrintFn, 1000);
                                }
                            }
                        );
                    };
                    tryToPrintFn();
                });
            } else {
                console.debug(
                    'PRINT::toPrint 7 [' + new Date().toJSON() + ']',
                    { printOptions, address: this.address }
                );
                // this.stampe$.next(true);
            }
        } else {
            console.debug('PRINT::toPrint 8 [' + new Date().toJSON() + ']', {
                printOptions,
                address: this.address,
            });
            // this.stampe$.next(true);
        }
    }

    async printBarcodesOnBrowser(codes: string[]) {
        let divBarcodes = document.createElement('div');
        divBarcodes.style.display = 'none';
        document.body.append(divBarcodes);

        let imageLoadPromises: Promise<void>[] = [];

        let position = 1;
        let row = 1;
        for (let key in codes) {
            let newImage = document.createElement('img');
            imageLoadPromises.push(
                new Promise<void>(resolve => {
                    newImage.onload = <any>resolve;
                })
            );
            let className = 'barcode';
            newImage.id = 'image-barcode-' + key;
            if (position == 1) className += ' barcode-left';
            if (position == 5) className += ' barcode-right';
            if (row == 1) className += ' barcode-row-top';
            if (row == 13) className += ' barcode-row-bottom';
            newImage.className = className;
            divBarcodes.appendChild(newImage);
            JsBarcode('#image-barcode-' + key, codes[key], {
                fontSize: 54,
                fontOptions: 'bold',
                font: 'arial',
            });
            position++;
            if (position == 6) {
                row++;
                position = 1;
            }
            if (row == 14) {
                row = 1;
            }
        }

        return Promise.all(imageLoadPromises).then(
            () =>
                new Promise<void>(resolve => {
                    const barcodesHTML = divBarcodes.innerHTML;
                    divBarcodes.remove();

                    let barcodesPopupWindow = window.open(
                        '',
                        'PRINT',
                        'height=800,width=800'
                    );

                    const printPageHtml = `
                    <html>
                    <head>
                        <title></title>
                        <style>
                            @media print {
                                @page { size: auto; margin: 0px; }
                                body { margin: 0px; }
                                img {width: 30mm;
                                    height: 17.30769231mm;
                                    margin-right: 4.25mm;
                                    margin-left: 4.25mm;
                                    margin-top: 1.9mm;
                                    margin-bottom: 1.9mm;
                                }
                                .barcode-row-top{
                                    margin-top: 12.5mm !important;
                                }
                                .barcode-row-bottom{
                                    margin-bottom: 9mm !important;
                                }
                                .barcode-left{
                                    margin-left: 12.5mm !important;
                                }
                                .barcode-right{
                                    margin-right: 12.5mm !important;
                                }
                            }
                        </style>
                    </head>
                    <body>
                        ${barcodesHTML}
                    </body>
                    </html>`;
                    barcodesPopupWindow.document.write(printPageHtml);

                    barcodesPopupWindow.document.close(); // necessary for IE >= 10
                    barcodesPopupWindow.focus(); // necessary for IE >= 10*/

                    barcodesPopupWindow.print();

                    resolve();
                })
        );
    }

    public async printProductBoxTag(strToPrint: string, macAddress?: string) {
        console.debug("PRINT::printProductBoxTag 1 [" + new Date().toJSON() + "]", { strToPrint, macAddress: macAddress });
        if (macAddress) {
            this.address = macAddress;
        } else {
            this.address = await this.getConfiguredAddress();
        }
        console.debug("PRINT::printProductBoxTag 2 [" + new Date().toJSON() + "]", { strToPrint, macAddress: this.address });
        if (this.address) {
            return await this.toPrintFromString(strToPrint);
        } else {
            console.debug("PRINT::printProductBoxTag 3 [" + new Date().toJSON() + "]", { strToPrint, macAddress: this.address });
            return await this.connect()
                .then(() => this.toPrintFromString(strToPrint));
        }
    }

    public async toPrintFromString(textToPrint: string, callbackSuccess?: () => any, callbackFail?: () => any,  macAddress?) {
        console.debug("PRINT::toPrintFromString 1 [" + new Date().toJSON() + "]", { textToPrint, macAddress });
        if (macAddress) {
            this.address = macAddress;
            console.debug("PRINT::toPrintFromString 2 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        } else {
            this.address = await this.getConfiguredAddress();
            console.debug("PRINT::toPrintFromString 3 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        }

        console.debug("PRINT::toPrintFromString 4 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        if (!this.address) {
            if (callbackFail) {
                callbackFail();
                return;
            }
            await this.connect();
            console.debug("PRINT::toPrintFromString 5 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        }

        console.debug("PRINT::toPrintFromString 6 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        if (this.address) {
            if (typeof cordova != "undefined" && cordova.plugins.zbtprinter) {
                console.debug("PRINT::toPrintFromString 7 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
                return new Promise((resolve, reject) => {
                    let printAttempts = 0;
                    console.debug("PRINT::toPrintFromString 8 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                    let tryToPrintFn = () => {
                        printAttempts++;
                        console.debug("PRINT::toPrintFromString 9 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                        cordova.plugins.zbtprinter.printWithConnection(this.address, textToPrint,
                            (success) => {
                                console.debug("PRINT::toPrintFromString 10 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                                //console.debug("Zbtprinter print success: " + success, { text: printOptions.text || printOptions.product.productShoeUnit.reference, mac: this.address, textToPrint: textToPrint });
                                this.stampe$.next(true);
                                if (callbackSuccess) {
                                    callbackSuccess();
                                }
                                resolve(true);
                            }, (fail) => {
                                console.debug("PRINT::toPrintFromString 11 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                                if (printAttempts >= PrinterService.MAX_PRINT_ATTEMPTS) {
                                    console.debug("PRINT::toPrintFromString 12 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                                    //console.debug("Zbtprinter print finally fail:" + fail, { text: printOptions.text || printOptions.product.productShoeUnit.reference, mac: this.address, textToPrint: textToPrint });
                                    this.intermediaryService.presentToastError('No ha sido posible conectarse con la impresora', 4000);
                                    if(callbackFail){
                                        callbackFail();
                                    }
                                    reject(false);
                                } else {
                                    console.debug("PRINT::toPrintFromString 13 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address, printAttempts });
                                    //console.debug("Zbtprinter print attempt " + printAttempts + " fail:" + fail + ", retrying...", { text: printOptions.text || printOptions.product.productShoeUnit.reference, mac: this.address, textToPrint: textToPrint });
                                    if (callbackFail) {
                                        callbackFail();
                                        reject(false);
                                    } else {
                                        setTimeout(tryToPrintFn, 1000);
                                    }
                                }
                            }
                        );
                    };
                    tryToPrintFn();
                });
            } else {
                console.debug("PRINT::toPrintFromString 14 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
            }
        } else {
            console.debug("PRINT::toPrintFromString 15 [" + new Date().toJSON() + "]", { textToPrint, macAddress: this.address });
        }
    }

    public async getPrinterServiceAddress() {
        const address = await this.settingsService.getDeviceSetting(
            SettingsService.STORAGE_KEY_PRINTER_SERVICE_ADDRESS
        );

        this.printServiceAddress =
            'http://' +
            (address || SettingsService.DEFAULT_PRINTER_SERVICE_ADDRESS);
    }

    postPrint(parameters: ParamsPrint | ParamsPrintBase64): Observable<any> {
        console.log(
            'PrinterService::Print document: ',
            JSON.stringify({ url: this.postPrintUrl, body: parameters })
        );

        return this.http.post(
            this.printServiceAddress + this.postPrintUrl,
            parameters
        );
    }

    private processTagPricesAndChangeTemplate(price, labelType) {
        let priceItemUpdated = null;

        switch (labelType) {
            case LabelTypes.LABEL_INFO_PRODUCT:
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF:
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF_MODUL:
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT:
                priceItemUpdated = price;
                break;
            case LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET:
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET:
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT:
                let priceOriginal1 = !isNaN(parseFloat(price.priceOriginal))
                    ? parseFloat(price.priceOriginal)
                    : 0;
                let priceDiscount1 = !isNaN(parseFloat(price.priceDiscount))
                    ? parseFloat(price.priceDiscount)
                    : 0;

                if (!priceDiscount1) {
                    labelType =
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT;
                } else if (priceOriginal1 <= priceDiscount1) {
                    price.priceOriginal = price.priceDiscount;
                    labelType =
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT;
                }
                priceItemUpdated = price;
                break;
            case LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT_OUTLET:
                let priceOriginal2 = !isNaN(parseFloat(price.priceOriginal))
                    ? parseFloat(price.priceOriginal)
                    : 0;
                let priceDiscount2 = !isNaN(parseFloat(price.priceDiscount))
                    ? parseFloat(price.priceDiscount)
                    : 0;
                let priceDiscountOutlet = !isNaN(
                    parseFloat(price.priceDiscountOutlet)
                )
                    ? parseFloat(price.priceDiscountOutlet)
                    : 0;

                if (!priceDiscountOutlet) {
                    labelType =
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET;
                } else if (priceDiscount2 <= priceDiscountOutlet) {
                    labelType =
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET;
                    price.priceDiscount = price.priceDiscountOutlet;
                    priceDiscount2 = !isNaN(
                        parseFloat(price.priceDiscountOutlet)
                    )
                        ? parseFloat(price.priceDiscountOutlet)
                        : 0;
                }

                if (!priceDiscount2) {
                    labelType =
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT;
                } else if (priceOriginal2 <= priceDiscount2) {
                    if (
                        labelType ==
                        LabelTypes.LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT_OUTLET
                    ) {
                        price.priceDiscount = price.priceDiscountOutlet;
                        labelType =
                            LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET;
                    } else {
                        price.priceOriginal = price.priceDiscount;
                        labelType =
                            LabelTypes.LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT;
                    }
                }

                priceItemUpdated = price;
                break;
            default:
                priceItemUpdated = price;
        }

        return priceItemUpdated;
    }
}

enum LabelTypes {
    LABEL_BARCODE_TEXT = 'labelBarcodeText',
    LABEL_INFO_PRODUCT = 'labelInfoProduct',
    LABEL_PRICE_WITHOUT_TARIF = 'labelPriceWithoutTarif',
    LABEL_PRICE_WITHOUT_TARIF_OUTLET = 'labelPriceWithoutTarifOutlet',
    LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT = 'labelPriceWithTarifWithoutDiscount',
    LABEL_PRICE_WITH_TARIF_WITHOUT_DISCOUNT_OUTLET = 'labelPriceWithTarifWithoutDiscountOutlet',
    LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT = 'labelPriceWithTarifWithDiscount',
    LABEL_PRICE_WITH_TARIF_WITH_DISCOUNT_OUTLET = 'labelPriceWithTarifWithDiscountOutlet',
    LABEL_PRICE_WITHOUT_TARIF_MODUL = 'labelPriceWithoutTarifModul',
}
