import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';

import { ToolbarProvider } from '../../../providers';

import { ReceptionMlkitService } from '../../core';

@Component({
    selector: 'suite-after-packing-reception',
    templateUrl: './after-packing-reception.component.html',
    styleUrls: ['./after-packing-reception.component.scss'],
})
export class AfterPackingReceptionComponent implements OnInit {
    count: {
        packingReference: string;
        total: number;
        packages: number;
        receivedProducts: number;
        newProducts: number;
        requestedProducts: number;
        noOnlineProducts: number;
    };

    receptionMlkitService: ReceptionMlkitService;

    buttons: {
        route: string;
        text1: string;
        text2: string;
        title: string;
    }[] = [];

    constructor(
        private router: Router,
        private modalController: ModalController,
        private navParams: NavParams,

        private toolbarProvider: ToolbarProvider,
    ) {
        this.count = this.navParams.get('count');
        this.receptionMlkitService = this.navParams.get(
            'receptionScanditService'
        );
    }

    async ngOnInit() {
        if (this.count.receivedProducts > 0) {
            this.buttons.push({
                route: '/print/product/received',
                text1: 'Productos',
                text2: 'Recibidos',
                title: 'Productos Recibidos',
            });
        }
        if (this.count.packages > 0) {
            this.buttons.push({
                route: '/package-received',
                text1: 'Pedidos',
                text2: 'Recibidos',
                title: 'Pedidos Recibidos',
            });
        }
        if (this.count.newProducts > 0) {
            this.buttons.push({
                route: '/new-products',
                text1: 'Productos',
                text2: 'Nuevos',
                title: 'Nuevos Productos',
            });
        }
        if (this.count.requestedProducts > 0) {
            this.buttons.push({
                route: '/requested-products',
                text1: 'Productos',
                text2: 'Solicitados',
                title: 'Productos Solicitados',
            });
        }
        if (this.count.noOnlineProducts > 0) {
            this.buttons.push({
                route: '/defect-handler',
                text1: 'Ir a ',
                text2: 'Defectuosos',
                title: 'Defectuosos',
            });
        }
    }

    close() {
        this.modalController.dismiss();
    }

    redirect(route: string, title: string) {
        this.toolbarProvider.currentPage.next(title);
        this.router.navigateByUrl(route).then(() => this.close());
    }

    newReception() {
        this.receptionMlkitService.reception(1);
        this.modalController.dismiss();
    }

}
