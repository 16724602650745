import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { MarketplacesState, marketplacesRecord } from './state';

const currentState = (state: AppState) => state.marketplaces;
//
export const getMarketplacesData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketplacesData
);
export const getMarketplacesFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketplacesFailData
);
export const getMarketplacesLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketplacesLoading
);
//
export const searchProductCatalogData = createSelector(
    currentState,
    (state: MarketplacesState) => state.searchProductCatalogData
);
export const searchProductCatalogFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.searchProductCatalogFailData
);
export const searchProductCatalogLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.searchProductCatalogLoading
);
//
export const searchProductWithVariationsCatalogData = createSelector(
    currentState,
    (state: MarketplacesState) => state.searchProductWithVariationsCatalogData
);
export const searchProductWithVariationsCatalogFailData = createSelector(
    currentState,
    (state: MarketplacesState) =>
        state.searchProductWithVariationsCatalogFailData
);
export const searchProductWithVariationsCatalogLoading = createSelector(
    currentState,
    (state: MarketplacesState) =>
        state.searchProductWithVariationsCatalogLoading
);
//
export const getBrandsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getBrandsData
);
export const getBrandsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getBrandsFailData
);
export const getBrandsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getBrandsLoading
);
//
export const getCachedMarketById = createSelector(
    currentState,
    (state: MarketplacesState) => state.getCachedMarketById
);
//
export const getSizesData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSizesData
);
export const getSizesFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSizesFailData
);
export const getSizesLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSizesLoading
);
//
export const getColorsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getColorsData
);
export const getColorsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getColorsFailData
);
export const getColorsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getColorsLoading
);
//
export const getFeaturesData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getFeaturesData
);
export const getFeaturesFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getFeaturesFailData
);
export const getFeaturesLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getFeaturesLoading
);
//
export const getSeasonsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSeasonsData
);
export const getSeasonsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSeasonsFailData
);
export const getSeasonsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getSeasonsLoading
);
//
export const getMarketBrandsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketBrandsData
);
export const getMarketBrandsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketBrandsFailData
);
export const getMarketBrandsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketBrandsLoading
);
//
export const getMarketSizesData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSizesData
);
export const getMarketSizesFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSizesFailData
);
export const getMarketSizesLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSizesLoading
);
//
export const getMarketColorsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketColorsData
);
export const getMarketColorsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketColorsFailData
);
export const getMarketColorsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketColorsLoading
);
//
export const getMarketFeaturesData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketFeaturesData
);
export const getMarketFeaturesFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketFeaturesFailData
);
export const getMarketFeaturesLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketFeaturesLoading
);
//
export const getMarketSeasonsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSeasonsData
);
export const getMarketSeasonsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSeasonsFailData
);
export const getMarketSeasonsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getMarketSeasonsLoading
);
//
export const getRulesConfigurationsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getRulesConfigurationsData
);
export const getRulesConfigurationsFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.getRulesConfigurationsFailData
);
export const getRulesConfigurationsLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.getRulesConfigurationsLoading
);
//
export const postUpdateZalandoSizeMapWithApiData = createSelector(
    currentState,
    (state: MarketplacesState) => state.postUpdateZalandoSizeMapWithApiData
);
export const postUpdateZalandoSizeMapWithApiFailData = createSelector(
    currentState,
    (state: MarketplacesState) => state.postUpdateZalandoSizeMapWithApiFailData
);
export const postUpdateZalandoSizeMapWithApiLoading = createSelector(
    currentState,
    (state: MarketplacesState) => state.postUpdateZalandoSizeMapWithApiLoading
);
//
export const updateRulesConfigurationsData = createSelector(
    currentState,
    (state: MarketplacesState) => state.updateRulesConfigurationsData
);
