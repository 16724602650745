export namespace ActionTypeModel {

  export namespace ActionTypes {
      export const actionTypesDictionary = {
          'internalWarehousePositioningOutput':
              'Posicionamiento almacén interno salida',
          'positioningInternalWarehouseEntry':
              'Posicionamiento almacén interno entrada',
          'positioningNewProduct': 'Posicionamiento nuevo Producto',
          'positioningNewRollbackProduct':
              'Posicionamiento nuevo Producto rollback',
          'positioningAnotherWarehouse': 'Posicionamiento otro almacen',
          'positioningAnotherRollback': 'Posicionamiento otro rollback',
          'positioningNewRecord': 'Posicionamiento nuevo registro',
          'picking': 'Picking',
          'rollbackPicking': 'Rollback picking',
          'positioningInternalWarehouseRollback':
              'Posicionamiento almacén interno rollback',
          'pickingConsolidated': 'Picking consolidado',
          'rollbackPickingConsolidated': 'Rollback picking consolidado',
          'productPickingNotFound': 'Producto no encontrado en Picking',
          'pickingStore': 'Picking tienda',
          'relabelProduct': 'Reetiquetado de producto',
          'transferFullCarrierPackings':
              'Transferencia contenido entre embalajes',
          'transferSelectiveCarrierPackings':
              'Transferencia selectiva entre embalajes',
          'sorterOutput': 'Salida sorter',
          'sorterInput': 'Entrada sorter',
          'sorterRackInput': 'Entrada en estantería anexa',
          'removeProductFromPacking':
              'Producto eliminado de un embalaje marcado como vacío',
          'receptionSorter': 'Recepción al Sorter',
          'receptionWarehouse': 'Recepción almacén principal',
          'pickingOnline': 'Picking de pedido online',
          'positioningDefective':
              'Posicionamiento para envío almacén de defectuosos',
          'oplInternProcess': 'Proceso de Operación Logística Interna',
          'returnPicking': 'Picking de devolución',
          'associatedToOrder': 'Asociado a Pedido',
          'returnPickedUp': 'Devolución recogida',
          'preparedReturn': 'Preparado Devolución',
          'locationCancelled': 'Baja de ubicación',
          'productRegularized': 'Producto regularizado',
          'unassociatedPickingProduct': 'Producto desasociado del picking',
          'sizeRegularization': 'Regularización Talla',
          'massiveImport': 'Importación Masiva',
          'massiveImportChangeLocation':
              'Importación Masiva (Cambio de Ubicación)',
      }
  }
}
