import { SupplierConditionModel } from './supplier-condition.model';
import SupplierCondition = SupplierConditionModel.SupplierCondition;

export namespace BrandModel {
    export interface Brand {
        createdAt: string;
        updatedAt: string;
        id: number;
        avelonId: number;
        datasetHash: string;
        name: string;
        supplierName: string;
        providerId?: number;
        condition?: SupplierCondition;
        multiple?: boolean;
    }
}
