import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../modules/material.module';
import { AfterPackingReceptionComponent } from '../modules/after-packing-reception/after-packing-reception.component';
import { PackagesWarningCancelledComponent } from '../modules/online-orders/expedition-collected/packages-warning-cancelled/packages-warning-cancelled.component';
import { BaseComponent } from './base-component/base.component';
import { CustomSelectComponent } from './custom-select/sustom-select.component';
import { MainToolBarComponent } from './main-toolbar/main-toolbar.component';
import { WarehouseMapComponent } from './warehouse-map/warehouse-map.component';
import { MenuComponent } from './menu/menu.component';
import { ScrollbarDirective } from './scrollbar/scrollbar';
import { TableTitleFilterComponent } from './table-title-filter/table-title-filter.component';
import { TitleDIrective } from './title/title.directive';
import { ScrollToItemDirective } from './scroll-to-item.directive';
import { ImageUploadComponent } from './dialogs/image-upload/image-upload.component';
import { DrawZoneComponent } from './dialogs/draw-zone/draw-zone.component';
import { TipoSelectComponent } from './dialogs/tipo-select/tipo-select.component';
import { AlmacenSelectComponent } from './dialogs/almacen-select/almacen-select.component';
import { ChangeStatusComponent } from './dialogs/change-status/change-status.component';
import { SupplierSelectComponent } from './dialogs/supplier-select/supplier-select.component';
import { UnidadesDetailComponent } from './dialogs/unidades-detail/unidades-detail.component';
import { MarcaSelectComponent } from './dialogs/marca-select/marca-select.component';
import { TiendasSelectionComponent } from './dialogs/tiendas-selection/tiendas-selection.component';
import { EditRackSizeComponent } from './dialogs/edit-rack-size/edit-rack-size.component';
import { MessageService } from './message.service';
import { LockContainerComponent } from './dialogs/lock-container/lock-container.component';
import { VirtualKeyboardComponent } from './virtual-keyboard/virtual-keyboard.component';
import { LoadingButtonComponent } from './button/loading-button/loading-button.component';
import { SizeInputComponent } from './size-input/size-input.component';
import { SizeSelectComponent } from './size-select/size-select.component';
import { FabExtendedComponent } from './button/fab/fab-extended/fab-extended.component';
import { TableCheckboxesComponent } from './table-checkboxes/table-checkboxes.component';
import { TableHeaderCheckboxComponent } from './table-header-checkbox/table-header-checkbox.component';
import { ListProductsPackingComponent } from "./list-products-packing/list-products-packing.component";
import { FiltersListComponent } from "./list-products-packing/filters-list/filters-list.component";
import { TagInputModule } from "../modules/ngx-chips";

@NgModule({
    declarations: [
        AfterPackingReceptionComponent,
        MenuComponent,
        BaseComponent,
        MainToolBarComponent,
        WarehouseMapComponent,
        CustomSelectComponent,
        TitleDIrective,
        ScrollbarDirective,
        TableTitleFilterComponent,
        ScrollToItemDirective,
        ImageUploadComponent,
        DrawZoneComponent,
        TipoSelectComponent,
        AlmacenSelectComponent,
        ChangeStatusComponent,
        SupplierSelectComponent,
        UnidadesDetailComponent,
        MarcaSelectComponent,
        TiendasSelectionComponent,
        EditRackSizeComponent,
        LockContainerComponent,
        VirtualKeyboardComponent,
        LoadingButtonComponent,
        SizeInputComponent,
        SizeSelectComponent,
        FabExtendedComponent,
        TableCheckboxesComponent,
        TableHeaderCheckboxComponent,
        PackagesWarningCancelledComponent,
        ListProductsPackingComponent,
        FiltersListComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        MaterialModule,
        RouterModule,
        TranslateModule,
        ScrollingModule,
        TagInputModule,
    ],
    exports: [
        MenuComponent,
        BaseComponent,
        MainToolBarComponent,
        WarehouseMapComponent,
        CustomSelectComponent,
        TitleDIrective,
        ScrollbarDirective,
        TableTitleFilterComponent,
        ScrollToItemDirective,
        ImageUploadComponent,
        DrawZoneComponent,
        TipoSelectComponent,
        AlmacenSelectComponent,
        ChangeStatusComponent,
        SupplierSelectComponent,
        UnidadesDetailComponent,
        MarcaSelectComponent,
        TiendasSelectionComponent,
        EditRackSizeComponent,
        LockContainerComponent,
        TranslateModule,
        VirtualKeyboardComponent,
        LoadingButtonComponent,
        SizeInputComponent,
        SizeSelectComponent,
        FabExtendedComponent,
        TableCheckboxesComponent,
        TableHeaderCheckboxComponent,
        PackagesWarningCancelledComponent,
    ],
    providers: [MessageService],
})
export class SharedModule {}
