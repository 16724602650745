import { createReducer, on } from '@ngrx/store';
import { DictionaryState, dictionaryRecord } from './state';
import * as actions from './actions';

export const dictionaryReducer = createReducer(
    dictionaryRecord,
    //
    on(actions.syncColorAction, (state, payload) => ({
        ...state,
        syncColorData: null,
        syncColorFailData: null,
        syncColorLoading: true,
    })),
    on(actions.syncColorSuccessAction, (state, payload) => ({
        ...state,
        syncColorData: payload,
        syncColorFailData: null,
        syncColorLoading: null,
    })),
    on(actions.syncColorFailAction, (state, payload) => ({
        ...state,
        syncColorData: null,
        syncColorFailData: payload,
        syncColorLoading: null,
    })),
    //
    on(actions.getColorListAction, (state, payload) => ({
        ...state,
        getColorListData: null,
        getColorListFailData: null,
        getColorListLoading: true,
    })),
    on(actions.getColorListSuccessAction, (state, payload) => ({
        ...state,
        getColorListData: payload,
        getColorListFailData: null,
        getColorListLoading: null,
    })),
    on(actions.getColorListFailAction, (state, payload) => ({
        ...state,
        getColorListData: null,
        getColorListFailData: payload,
        getColorListLoading: null,
    })),
    //
    on(actions.addColorItemAction, (state, payload) => ({
        ...state,
        addColorItemData: null,
        addColorItemFailData: null,
        addColorItemLoading: true,
    })),
    on(actions.addColorItemSuccessAction, (state, payload) => ({
        ...state,
        addColorItemData: payload,
        addColorItemFailData: null,
        addColorItemLoading: null,
    })),
    on(actions.addColorItemFailAction, (state, payload) => ({
        ...state,
        addColorItemData: null,
        addColorItemFailData: payload,
        addColorItemLoading: null,
    })),
    //
    on(actions.deleteColorItemAction, (state, payload) => ({
        ...state,
        deleteColorItemData: null,
        deleteColorItemFailData: null,
        deleteColorItemLoading: true,
    })),
    on(actions.deleteColorItemSuccessAction, (state, payload) => ({
        ...state,
        deleteColorItemData: payload,
        deleteColorItemFailData: null,
        deleteColorItemLoading: null,
    })),
    on(actions.deleteColorItemFailAction, (state, payload) => ({
        ...state,
        deleteColorItemData: null,
        deleteColorItemFailData: payload,
        deleteColorItemLoading: null,
    })),
    //
    on(actions.getProviderItemAction, (state, payload) => ({
        ...state,
        getProviderItemData: null,
        getProviderItemFailData: null,
        getProviderItemLoading: true,
    })),
    on(actions.getProviderItemSuccessAction, (state, payload) => ({
        ...state,
        getProviderItemData: payload,
        getProviderItemFailData: null,
        getProviderItemLoading: null,
    })),
    on(actions.getProviderItemFailAction, (state, payload) => ({
        ...state,
        getProviderItemData: null,
        getProviderItemFailData: payload,
        getProviderItemLoading: null,
    })),
    //
    on(actions.getProviderListAction, (state, payload) => ({
        ...state,
        getProviderListData: null,
        getProviderListFailData: null,
        getProviderListLoading: true,
    })),
    on(actions.getProviderListSuccessAction, (state, payload) => ({
        ...state,
        getProviderListData: payload,
        getProviderListFailData: null,
        getProviderListLoading: null,
    })),
    on(actions.getProviderListSuccessAction, (state, payload) => ({
        ...state,
        getProviderListData: null,
        getProviderListFailData: payload,
        getProviderListLoading: null,
    }))
);
