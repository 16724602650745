import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaitForDataGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.match(/android/i) || userAgent.match(/iphone|ipad|ipod/i)) {
      //It's a mobile device, redirect to another page instead of showing NotFoundComponent
      //this.router.navigate(['/another-route']);
      return false;
    }

    // If not a mobile device, allow navigation to the NotFoundComponent
    return true;
  }
}
