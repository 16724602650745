import { Auth } from 'src/app/models/auth.models';

export interface AuthState {
    loginLoading: boolean;
    loginData: Auth.ResponseModel;
    loginFailData: any;

    getProfileData: any;
    getProfileLoading: boolean;
    getProfileFailData: boolean;
}

export const authState: AuthState = {
    loginLoading: false,
    loginData: null,
    loginFailData: null,

    getProfileData: null,
    getProfileLoading: null,
    getProfileFailData: null,
};
