import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class Api {
    constructor(protected http: HttpClient) {}

    protected mgaApiUrl: string = environment.mgaApiUrl;

    protected apiMiddleware: string = environment.apiMiddleware;

    protected apiRule: string = environment.apiRule;

    protected apiCrm: string = environment.apiCrm;

    protected apiLogisticOperator: string = environment.apiLogisticOperator;

    protected apiBase: string = environment.mgaApiUrl + '/api';
}
