import { WarehouseModel, ModelModel, SizeModel, PriceModel, InventoryModel, SorterControlProductModel } from './';

export namespace ProductModel {

    export interface Product {
        id: number;
        reference: string;
        hash: string;
        style: string;
        internalColorId: string;
        avelonId: string;
        initialWarehouse: WarehouseModel.Warehouse;
        initialWarehouseId: number;
        initialWarehouseReference: string;
        model: ModelModel.Model;
        modelId: number;
        size: SizeModel.Size;
        sizeId: number;
        relabel: boolean;
        noOnline: boolean;
        inventories: InventoryModel.Inventory[];
        inventory: InventoryModel.Inventory;
        sorterControlProducts: SorterControlProductModel.SorterControlProduct[];
        deliveryRequests: any[];
    }

    export interface Package {
        uniqueCode: string;
        destinationShop: PackageDestinationShop;
        expedition: PackageExpedition;
        deliveryRequestId: number;
        deliveryRequestExternalId: number;
    }

    export interface PackageDestinationShop {
        id: number;
        name: string;
        reference: string;
    }

    export interface PackageExpedition {
        id: number;
        barcode: string;
        status: number;
    }

    export interface ProductShoesUnit {
        id: number;
        initialWarehouseReference: string;
        reference: string;
    }

    export interface ProductPicking {
        id: number;
        reference: string;
        size: {
            id: number;
            name: string;
        };
        model: {
            id: number;
            reference: string;
            colorId: number;
            brand: {
                id: number;
                name: string;
            }
        }
    }

    export interface SizePrice extends SizeModel.Size {
        price: PriceModel.Price;
    }

    export interface ResponseIndex {
        data: Product[];
    }

    export interface SizesAndModel {
        sizes: SizeModel.Size[];
        model: ModelModel.Model;
        location: any;
    }

    export interface InfoVerificationOnlineStore {
        reference: string;
        model: ModelModel.Model;
        size: SizeModel.Size;
    }

    export interface ResponseExtendedInfo {
        data?: {
            productModel: ModelModel.Model;
            sizes: SizePrice[];
        };
        errors?: string;
        message: string;
        code: number;
    }

    export interface ParamsRelabel {
        productReference: string;
        warehouseId?: number;
        modelId?: number;
        sizeId?: number;
        locationReference?: string;
        manual?: boolean;
        relabelLinkedModel?: boolean;
    }

    export interface ParamsRelabelPrint {
        modelId: number;
        colorId: number;
        sizeId: number;
    }

    export interface ResponseRelabel {
        data?: Product;
        errors?: string;
        message: string;
        code: number;
    }

    export interface ResponseInfoReception {
        message?: string;
        code?: number;
        data?: {
            brand: { id: number; name: string };
            model: {
                id: number;
                name: string;
                reference: string;
                supplierReference: string;
            };
            color: { id: number; name: string };
            size: { id: number; name: string };
        };
        error?: any;
        errors?: any;
    }

    export interface ResponseCheckEan {
        message?: string;
        code?: number;
        data?: {
            codeExists: boolean;
        };
        error?: any;
        errors?: any;
    }

    export interface ErrorResponseIndex {
        statusCode: number;
        status: number;
        code: number;
        message: string;
        name: string;
    }

    export interface ResponseRelabelNotCompleted {
        data?: {
            model: ModelModel.Model;
            sizeNumberNotFound: number;
            missingData: boolean;
            newSizes?: any[];
        };
        errors?: string;
        message: string;
        code: number;
    }

}
