import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
    ContainerModel,
    ExpeditionModel,
    ExpeditionPackageModel,
    GlobalVariableModel,
    HttpRequestModel,
    InternalGroupModel,
    InventoryModel,
    LabelModel,
    PackingModel,
    PriceFilterStatus,
    ReceptionAvelonModel,
    ReturnModel,
    TariffModel,
    TariffWarehouseModel,
    TaskModel,
    TransportModel,
    WarehouseModel,
} from '../models';

import { ApiBase } from './api-base';

import GetByReferenceWithCountResponse = ContainerModel.GetByReferenceWithCountResponse;
import IExpeditionInfo = ExpeditionModel.IExpeditionInfo;
import ResponseCheckPackageForExpedition = ExpeditionModel.ResponseCheckPackageForExpedition;
import ResponseCheckProductsForExpedition = ExpeditionModel.ResponseCheckProductsForExpedition;
import GetExpeditionsPackagesParameters = ExpeditionPackageModel.GetExpeditionsPackagesParameters;
import ListGlobalVariablesResponse = GlobalVariableModel.ListGlobalVariablesResponse;
import TypeDescriptorsResponse = GlobalVariableModel.TypeDescriptorsResponse;
import CrudPaginatedResponse = HttpRequestModel.CrudPaginatedResponse;
import InternalGroup = InternalGroupModel.InternalGroup;
import MoveProductResponse = InventoryModel.MoveProductResponse;
import InventoryProcess = InventoryModel.InventoryProcess;
import MoveProductParameters = InventoryModel.MoveProductParameters;
import IExpeditionDocToPrint = LabelModel.IExpeditionDocToPrint;
import PackingInventoriesFiltersResponse = PackingModel.PackingInventoriesFiltersResponse;
import SearchPackingInventoriesParameters = PackingModel.SearchPackingInventoriesParameters;
import Packing = PackingModel.Packing;
import EmptyBulkParameters = PackingModel.EmptyBulkParameters;
import EmptyBulkResponse = PackingModel.EmptyBulkResponse;
import Providers = ReceptionAvelonModel.Providers;
import GetAllByProvider = ReceptionAvelonModel.GetAllByProvider;
import ResponseCheckExpeditionsByProvider = ReceptionAvelonModel.ResponseCheckExpeditionsByProvider;
import ResponseCheckExpeditionByReference = ReceptionAvelonModel.ResponseCheckExpeditionByReference;
import ResponseLoadSizesList = ReceptionAvelonModel.ResponseLoadSizesList;
import ResponsePrintReceptionLabel = ReceptionAvelonModel.ResponsePrintReceptionLabel;
import GetReturnsByPackingResponse = ReturnModel.GetReturnsByPackingResponse;
import GetReturnsByPackingParameters = ReturnModel.GetReturnsByPackingParameters;
import UpdateFilterPriceParameters = TariffModel.UpdateFilterPriceParameters;
import GetTariffs = TariffModel.GetTariffs;
import GetTariffFilters = TariffModel.GetTariffFilters;
import GetTariffsParameters = TariffModel.GetTariffsParameters;
import TariffWarehouse = TariffWarehouseModel.TariffWarehouse;
import Task = TaskModel.Task;
import GetTransportAgencies = TransportModel.GetTransportAgencies;
import GetTransportAgenciesFilters = TransportModel.GetTransportAgenciesFilters;
import TransportAgencies = TransportModel.TransportAgencies;
import TransportWithQuantity = TransportModel.TransportWithQuantity;
import Warehouse = WarehouseModel.Warehouse;

export class ApiMga extends ApiBase {

    constructor(http: HttpClient, baseUrl: string) {
        super('MGA', http, baseUrl);
    }

    private getHeaders(
        token: string,
        isApplication: boolean = false
    ): HttpHeaders {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': isApplication ? 'application/x-www-form-urlencoded' : 'application/json',
            Authorization: 'Bearer ' + token,
        });

        if (!isApplication) {
            headers.set('accept', '*/*');
        }

        return headers;
    }

    readonly auth = Object.freeze({
        login: (params) => {
            return this.post('auth/login', params, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Basic ' + btoa('sga:none'),
                }),
            });
        },
        listForUsers: (token, params) => {
            return this.post('auth/group/list-for-users', params, {
                headers: this.getHeaders(token),
            });
        },
        stockPickingListActivity: (token) => {
            return this.get(`auth/allowed/stock/picking-list-activity`, {
                headers: this.getHeaders(token, false),
            });
        },
        listForUserById: (userId: number, token) => {
            return this.get(`auth/group/list-for-user/${userId}`, {
                headers: this.getHeaders(token),
            });
        },
        getUserPermissions: (token) => {
            return this.post<string[]>(`auth/user/profile`, '', {
                headers: this.getHeaders(token, false),
            });
        },
    });

    readonly user = Object.freeze({
        create: (user: object, token: string) => {
            return this.post(`auth/user-administer`, user, {
                headers: this.getHeaders(token),
            });
        },
        update: (id: number, token: string, data?: object) => {
            return this.post(`auth/user-administer/${id}`, data, {
                headers: this.getHeaders(token),
            });
        },
        updateBulk: (token: string, data?: object) => {
            return this.post(`auth/user-administer/set-enabled-bulk`, data, {
                headers: this.getHeaders(token),
            });
        },
        updatePassword: (id: number, newPassword: object, token: string) => {
            return this.post(`auth/user-administer/set-password/${id}`, newPassword, {
                headers: this.getHeaders(token),
            });
        },
        setRoles: (userId: number, roles: object, token: string) => {
            return this.post(`auth/user-administer/${userId}/set-membership`, roles, {
                headers: this.getHeaders(token),
            });
        },
        revokeRole: (userId: number, roles: object, token: string) => {
            return this.post(`auth/user-administer/${userId}/revoke-membership`, roles, {
                headers: this.getHeaders(token),
            });
        },
        updateRoles: (userId: number, roles: object, token: string) => {
            return this.post(`auth/user-administer/${userId}/grant-membership`, roles, {
                headers: this.getHeaders(token),
            });
        },
        list: (token: string, params) => {
            return this.get(`auth/user-administer?${params}`, {
                headers: this.getHeaders(token),
            });
        },
        listUser: (token: string, id?: number) => {
            return this.get(`auth/user-administer/${id}`, {
                headers: this.getHeaders(token),
            });
        },
        groupsListStructure: (token: string) => {
            return this.get('auth/group/list-structured', {
                headers: this.getHeaders(token, false),
            });
        },
        listResources: (token: string, id: number) => {
            return this.get(`/auth/group/${id}/list-resources`, {
                headers: this.getHeaders(token, false),
            });
        },
        groups: (token: string, params) => {
            return this.get(`auth/group?${params}`, {
                headers: this.getHeaders(token, false),
            });
        },
        groupsByUser: (userId: number, token: string) => {
            return this.get(`auth/group/list-for-user/${userId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        scopeWarehouse: () => {
            return this.get(`auth/scope`);
        },
    });

    readonly dictionary = Object.freeze({
        providers: Object.freeze({
            list: (parameters = '') => {
                return this.get<CrudPaginatedResponse<Providers>>(`dictionary/provider${parameters}`);
            },
            getById: (id) => {
                return this.get<Providers>(`dictionary/provider/${id}`);
            },
        }),
        internalGroup: Object.freeze({
            list: (parameters = '') => {
                return this.get<CrudPaginatedResponse<InternalGroup>>(`dictionary/internal-group${parameters}`);
            },
            filters: () => {
                return this.get(`dictionary/internal-group/filters`);
            },
        }),
    });

    readonly task = Object.freeze({
        getById: (id: number, token) => {
            return this.get(`task/${id}`, {
                headers: this.getHeaders(token),
            });
        },
    });

    readonly reception = Object.freeze({
        expedition: Object.freeze({
            ensureSyncByProvider: (providerId: number) => {
                return this.post(`reception/expedition/ensure-sync/${providerId}`);
            },
            ensureSyncByReference: (reference: string) => {
                return this.post(`reception/expedition/ensure-sync-by-reference/${reference}`);
            },
            checkByProvider: (providerId: number) => {
                return this.post<ResponseCheckExpeditionsByProvider>(`reception/expedition/check-by-provider/${providerId}`);
            },
            checkByReference: (reference: string) => {
                return this.post<ResponseCheckExpeditionByReference>(`reception/expedition/check-by-reference/${reference}`);
            },
        }),
        allByProvider: (providerId: number, body: GetAllByProvider) => {
            return this.post(`reception/all-by-provider/${providerId}`, body);
        },
        listSizes: (params = '') => {
            return this.get<ResponseLoadSizesList>(`reception/sizes/list${params}`);
        },
        printLabels: (body) => {
            return this.post<ResponsePrintReceptionLabel>('reception/print-labels', body);
        },
        commercialFields: Object.freeze({
            list: (parameters = '') => {
                return this.get<CrudPaginatedResponse>(`reception/commercial-field${parameters}`);
            },
            createCommercialField: (commercialFieldId) => {
                return this.post<CrudPaginatedResponse>(`reception/commercial-field/${commercialFieldId}`);
            },
            deleteCommercialField: (commercialFieldId) => {
                return this.delete<CrudPaginatedResponse>(`reception/commercial-field/${commercialFieldId}`);
            },
        }),
    });

    readonly inventory = Object.freeze({
        list: (parameter = '', token) => {
            return this.get(`inventory/search${parameter}`, {
                headers: this.getHeaders(token, false),
            });
        },
        history: (productReference, token) => {
            return this.get(`inventory/history/${productReference}`, {
                headers: this.getHeaders(token, false),
            });
        },
        moveProduct: (body: MoveProductParameters, token) => {
            return this.post<MoveProductResponse>(`inventory/move-product`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        searchFilters: (token: string) => {
            return this.get(`inventory/search-filters`, {
                headers: this.getHeaders(token, false),
            });
        },
        listProductsByContainerId: (containerId: number, token) => {
            return this.get(`inventory/container-inventories/${containerId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        containerHistory: (containerId: number, token) => {
            return this.get(`inventory/container-history/${containerId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        moveLocationProducts: (body, token) => {
            return this.post(`inventory/move-location-products`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        undoLastProcess: (token) => {
            return this.get<InventoryProcess>(`inventory/undo-last-process`, {
                headers: this.getHeaders(token, false),
            });
        },
        emptyReturnPacking: (reference, token) => {
            return this.get<number>(`inventory/return/empty-packing/${reference}`, {
                headers: this.getHeaders(token, false),
            });
        },
        getReturnsByPacking: (body: GetReturnsByPackingParameters, token) => {
            return this.post<GetReturnsByPackingResponse>(`inventory/return/by-packing`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        productInfo: (reference: string, token) => {
            return this.get(`inventory/product-info/${reference}`, {
                headers: this.getHeaders(token),
            });
        },
        getContainersByModel: (modelId: number, token) => {
            return this.get(`/inventory/list-containers/${modelId}`, {
                headers: this.getHeaders(token),
            });
        },
        cancelProductLocations: (body, token) => {
            return this.post(`inventory/cancel-product-locations`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        storePickingUpdateStatus: (body, token) => {
            return this.post(`inventory/store-picking/update-status`, body, {
                headers: this.getHeaders(token, false),
            });
        },
    });

    readonly catalog = Object.freeze({
        warehouse: Object.freeze({
            list: (parameters = '', token) => {
                return this.get(`catalog/warehouse${parameters}`, {
                    headers: this.getHeaders(token),
                });
            },
            byId: (id: number, token) => {
                return this.get<Warehouse>(`catalog/warehouse/${id}`, {
                    headers: this.getHeaders(token),
                });
            },
            update: (id: number, body, token) => {
                return this.put(`catalog/warehouse/${id}`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            delete: (id: number, token) => {
                return this.delete(`catalog/warehouse/${id}`, {
                    headers: this.getHeaders(token),
                });
            },
        }),
        market: Object.freeze({
            list: () => {
                return this.get<CrudPaginatedResponse>(`catalog/market`);
            },
        }),
        rack: Object.freeze({
            list: (parameters = '', token) => {
                return this.get(`catalog/rack${parameters}`, {
                    headers: this.getHeaders(token),
                });
            },
            filters: (warehouseId: number, token) => {
                return this.get(`catalog/rack/filters/${warehouseId}`, {
                    headers: this.getHeaders(token),
                });
            },
            rackResizeByRackId: (rackId: number, body, token) => {
                return this.post(`catalog/rack/resize/${rackId}`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
        }),
        container: Object.freeze({
            getByWarehouse: (warehouseId: number, token) => {
                return this.get(`catalog/container/get-by-warehouse/${warehouseId}`, {
                    headers: this.getHeaders(token, false),
                });
            },
            setLockedByContainerId: (containerId: number, value, token) => {
                return this.post(`catalog/container/${containerId}/set-locked/${value}`, '', {
                    headers: this.getHeaders(token, false),
                });
            },
            setEnabledByContainerId: (containerId: number, value, token) => {
                return this.post(`catalog/container/${containerId}/set-enabled/${value}`, '', {
                    headers: this.getHeaders(token, false),
                });
            },
            list: (params = '', token) => {
                return this.get(`catalog/container${params}`, {
                    headers: this.getHeaders(token, false),
                });
            },
            updateEnabledLocked: (body, token) => {
                return this.post(`catalog/container/update-enabled-locked`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            getByReferenceWithCount: (containerReference: string, token) => {
                return this.get<GetByReferenceWithCountResponse>(`catalog/container/get-by-reference-with-count/${containerReference}`, {
                    headers: this.getHeaders(token),
                });
            },
            getContainerAndProductsModel: (reference: string, modelId: number, token) => {
                return this.get(`/catalog/container/with-count/${reference}/by-model/${modelId}`, {
                    headers: this.getHeaders(token, false),
                });
            },
        }),
        packing: Object.freeze({
            getById: (id: number, token) => {
                return this.get<Packing>(`catalog/packing/${id}`, {
                    headers: this.getHeaders(token),
                });
            },
            list: (parameters = '', token) => {
                return this.get(`catalog/packing${parameters}`, {
                    headers: this.getHeaders(token),
                });
            },
            create: (body, token) => {
                return this.post(`catalog/packing`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            update: (id: number, body, token) => {
                return this.post(`catalog/packing/${id}`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            search: (body, token) => {
                return this.post(`catalog/packing/search`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            filters: token => {
                return this.get(`catalog/packing/filters`, {
                    headers: this.getHeaders(token),
                });
            },
            checkPackingDestination: (packingId: number, destinationWarehouseId: number, token) => {
                return this.get(`inventory/check-packing-destination/${packingId}/${destinationWarehouseId}`, {
                    headers: this.getHeaders(token, false),
                });
            },
            send: (body, token) => {
                return this.post(`catalog/packing/send`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            destinationHistory: (body, token) => {
                return this.post(`catalog/packing/destination-history`, body, {
                    headers: this.getHeaders(token),
                });
            },
            destinationHistoryActions: token => {
                return this.get(`catalog/packing/destination-history-actions`, {
                    headers: this.getHeaders(token),
                });
            },
            delete: (id: number, token) => {
                return this.delete(`catalog/packing/${id}`, {
                    headers: this.getHeaders(token),
                });
            },
            emptyBulk: (body: EmptyBulkParameters, token) => {
                return this.post<EmptyBulkResponse>(`catalog/packing/empty-bulk`, body,{
                    headers: this.getHeaders(token),
                });
            },
            exportToExcel: (body, token) => {
                return this.post(`catalog/packing/export-to-excel/by-refs`, body, {
                    headers: this.getHeaders(token),
                    responseType: 'blob',
                });
            },
            warehouseMoveHistory: (id: number, body, token) => {
                return this.post(`catalog/packing-wh-move-history/list/${id}`, body, {
                    headers: this.getHeaders(token),
                });
            },
            warehouseMoveHistoryFilters: token => {
                return this.get(`catalog/packing-wh-move-history/filters`, {
                    headers: this.getHeaders(token),
                });
            },
            warehouseMoveHistoryExportToExcel: (id: number, body, token) => {
                return this.post(`catalog/packing-wh-move-history/export-to-excel/${id}`, body, {
                    headers: this.getHeaders(token),
                    responseType: 'blob',
                });
            },
            listMultiple: (body, token) => {
                return this.post(`catalog/packing/list-multiple`, body, {
                    headers: this.getHeaders(token),
                });
            },
            sealWarehouse: (body, token) => {
                return this.post(`catalog/packing-destination/create-many`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getPackingAudits: (body, token) => {
                return this.post(`catalog/packing/get-packing-audits`, body, {
                    headers: this.getHeaders(token),
                });
            },
            packingAuditFilters: token => {
                return this.get(`catalog/packing/packing-audit-filters`, {
                    headers: this.getHeaders(token),
                });
            },
            getPackingAuditProducts: (body, token) => {
                return this.post(`catalog/packing/get-packing-audit-products`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getPackingAuditProductFilters: (packingAuditId: number, token) => {
                return this.get(`catalog/packing/packing-audit-product-filters/${packingAuditId}`, {
                    headers: this.getHeaders(token),
                });
            },
            searchPackingInventories: (reference: string, body: SearchPackingInventoriesParameters, token) => {
                return this.post<Packing>(`catalog/packing/get-inventories/${reference}`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getPackingInventoriesFilters: (reference: string, token) => {
                return this.get<PackingInventoriesFiltersResponse>(`catalog/packing/inventory-filters/${reference}`, {
                    headers: this.getHeaders(token),
                });
            },
            findOneWithInventoriesByRef: (packingReference: string, token) => {
                return this.get<Packing>(`catalog/packing/by-reference/${packingReference}`, {
                    headers: this.getHeaders(token),
                });
            },
            deletePackingDestination: (packingId: number, token) => {
                return this.delete(`catalog/packing-destination/by-packing/${packingId}`, {
                    headers: this.getHeaders(token),
                });
            },
            updatePackingWarehouse: (packingId: number, body, token) => {
                return this.post(`catalog/packing/${packingId}`, body, {
                    headers: this.getHeaders(token),
                });
            },
        }),
    });

    readonly stock = Object.freeze({
        employee: () => {
            return this.get('/employee', {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            });
        },
        matchLineRequestOt: (token: string) => {
            return this.post('/workwave/match-linerequest-ot', null, {
                headers: this.getHeaders(token, false),
            });
        },
        assignUserToMatchLineRequestOt: (token: string, body) => {
            return this.post('/workwave/assign-user-to-match-linerequest-ot', body, {
                headers: this.getHeaders(token, false),
            });
        },
        confirmPicking: (token: string, body) => {
            return this.post('/workwave/confirm-match-linerequest-ot', body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwave: (token: string, string?: string) => {
            return this.get(`/workwave${string}`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderFilters: (token: string) => {
            return this.get(`workwave-order/filters`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderFiltersByWorkwave: (workwaveId: number, token: string) => {
            return this.get(`workwave-order/filters/${workwaveId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderSearchPending: (body, token) => {
            return this.post('workwave-order/search-pending', body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderSearchPendingByWorkwave: (workwaveId: number, body, token) => {
            return this.post(`workwave-order/search-pending/${workwaveId}`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderSetUsers: (body, token) => {
            return this.post('workwave-order/set-users', body, {
                headers: this.getHeaders(token, false),
            });
        },
        shoePickingByWorkwaveOrder: (workwaveOrderId: number, token) => {
            return this.get(`shoe-picking/by-workwave-order/${workwaveOrderId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderReset: (body, token) => {
            return this.post('workwave-order/reset', body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveHistoryListExecuted: (body, token) => {
            return this.post('workwave-history/list-executed', body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveHistoryFilters: token => {
            return this.get(`workwave-history/filters`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderHistorySearch: (workwaveId: number, body, token) => {
            return this.post(`workwave-order-history/search/${workwaveId}`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderHistoryFilters: (workwaveId: number, token) => {
            return this.get(`workwave-order-history/filters/${workwaveId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        shoePickingHistoryByWorkwaveOrder: (workwaveOrderId: number, token) => {
            return this.get(`shoe-picking-history/by-workwave-order/${workwaveOrderId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveListNotExecuted: (body, token) => {
            return this.post(`workwave/list-not-executed`, body, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveListNotExecutedFilters: token => {
            return this.get(`workwave/list-not-executed/filters`, {
                headers: this.getHeaders(token, false),
            });
        },
        packingSealMany: (body, token) => {
            return this.post(`stock/packing-seal-many`, body, {
                headers: this.getHeaders(token),
            });
        },
        workwaveOrderListByUser: (userId, token) => {
            return this.get(`workwave-order/list-by-user/${userId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        shoePickingPendingByWorkwaveOrder: (workwaveOrderId, token) => {
            return this.get(`shoe-picking/pending/by-workwave-order/${workwaveOrderId}`, {
                headers: this.getHeaders(token, false),
            });
        },
        workwaveOrderAllByPacking: (body, token) => {
            return this.post(`workwave-order/all-by-packing`, body,{
                headers: this.getHeaders(token)
            });
        },
        workwaveOrderProcessProductStoreOnline: (body, token) => {
            return this.post(`workwave-order/process-product-store-online`, body,{
                headers: this.getHeaders(token)
            });
        },
        workwaveOrderPackingUpdateByPicking: (workwaveOrderId, token) => {
            return this.post(`workwave-order-packing/update-by-picking/${workwaveOrderId}`, '',{
                headers: this.getHeaders(token)
            });
        },
        workwaveOrderPendingSeal: (body, token) => {
            return this.post(`workwave-order/pending-seal`, body,{
                headers: this.getHeaders(token)
            });
        },
        shoePickingProductNotFound: (workWaveOrderId, productId, token) => {
            return this.post(`shoe-picking/${workWaveOrderId}/product-not-found/${productId}`, '',{
                headers: this.getHeaders(token)
            });
        },
    });

    readonly geo = Object.freeze({
        province: Object.freeze({
            list: () => {
                return this.get(`geo/province`);
            },
        }),
        country: Object.freeze({
            list: () => {
                return this.get(`geo/country`);
            },
        }),
    });

    readonly globalVariable = Object.freeze({
        list: (token) => {
            return this.get<ListGlobalVariablesResponse>(`global-variable`, {
                headers: this.getHeaders(token),
            });
        },
        update: (body, token) => {
            return this.post(`global-variable`, body, {
                headers: this.getHeaders(token),
            });
        },
        typeDescriptors: (token) => {
            return this.get<TypeDescriptorsResponse>(`global-variable/type-descriptors`, {
                headers: this.getHeaders(token),
            });
        },
    });

    readonly tariffs = Object.freeze({
        tariff: Object.freeze({
            getTariffs: (body: GetTariffsParameters, token) => {
                return this.post<GetTariffs>(`tariff/tariff/search`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getTariffFilters: (token) => {
                return this.get<GetTariffFilters>(`tariff/tariff/filters`, {
                    headers: this.getHeaders(token),
                });
            },
            getAllByTariff: (tariffId: number, token) => {
                return this.get<TariffWarehouse[]>(`tariff/tariff-warehouse/by-tariff/${tariffId}`, {
                    headers: this.getHeaders(token, false),
                });
            },
            forceSync: (token) => {
                return this.post<Task | Task[]>(`tariff/tariff/force-sync`, null, {
                    headers: this.getHeaders(token),
                });
            },
            setEnabled: (id: number, value: 0 | 1, token) => {
                return this.post<GetTariffs>(`tariff/tariff/${id}/set-enabled/${value}`, null, {
                    headers: this.getHeaders(token),
                });
            },
        }),
        priceFilter: Object.freeze({
            getFilterPriceTypes: (token) => {
                return this.get<Pick<Record<PriceFilterStatus, string>, PriceFilterStatus>>(`tariff/price-filter/statuses`, {
                    headers: this.getHeaders(token),
                });
            },
            updateFilterPrice: (body: UpdateFilterPriceParameters[], tariffId: number, token) => {
                return this.post<void>(`tariff/price-filter/set-printed-for-tariff/${tariffId}`, body, {
                    headers: this.getHeaders(token),
                });
            },
        }),
    });

    readonly externalLogistics = Object.freeze({
        transport: Object.freeze({
            getTransportAgencies: (body, token) => {
                return this.post<GetTransportAgencies>(`external-logistics/transport/search`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getAllTransports: (token, params?) => {
                return this.get(`external-logistics/transport${params ? params : ''}`, {
                    headers: this.getHeaders(token),
                });
            },
            getTransportAgenciesFilters: token => {
                return this.get<GetTransportAgenciesFilters>(`external-logistics/transport/filters`, {
                    headers: this.getHeaders(token),
                });
            },
            update: (id: number, body, token) => {
                return this.patch<TransportAgencies>(`external-logistics/transport/${id}`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
        }),
        orderPackage: Object.freeze({
            filters: token => {
                return this.get(`external-logistics/order-package/filters`, {
                    headers: this.getHeaders(token),
                });
            },
        }),
        expedition: Object.freeze({
            getTransportsWithManifests: (token) => {
                return this.get(`external-logistics/expedition/get-transports-with-manifests`, {
                    headers: this.getHeaders(token),
                });
            },
            getExpeditionOrders: (body, token) => {
                return this.post(`external-logistics/expedition/get-expedition-orders`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            getExpeditionPackagesTrackingProducts: (body, token) => {
                return this.post(`external-logistics/expedition/get-expedition-packages-tracking-products`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            getExpeditionManifestFile: (expeditionOrderId: number, token) => {
                return this.get(`external-logistics/expedition/get-expedition-manifest-file/${expeditionOrderId}`, {
                    headers: this.getHeaders(token),
                });
            },
            getExpeditionById: (expeditionId: number, token) => {
                return this.get<ResponseCheckPackageForExpedition>(`external-logistics/${expeditionId}`, {
                    headers: this.getHeaders(token),
                });
            },
            processExpedition: (productBarcode, token) => {
                return this.get<IExpeditionInfo>(`external-logistics/expedition/process-expedition/${productBarcode}`, {
                    headers: this.getHeaders(token),
                });
            },
            checkShippingPackageCodes: (body, token) => {
                return this.post<any>(`external-logistics/expedition/check-shipping-package-codes`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            printExpeditionPackage: (body, token) => {
                return this.post<IExpeditionDocToPrint[]>(`external-logistics/expedition/print-expedition-package`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            checkProductsProcessExpedition: (body, token) => {
                return this.post<ResponseCheckProductsForExpedition>(`external-logistics/expedition/check-products-process-expedition`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            checkPackagesCancelledOrReturned: (body, token) => {
                return this.post<any>(`external-logistics/expedition/check-packages-cancelled-or-returned`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            updatePackages: (body, token) => {
                return this.post<any>(`external-logistics/expedition/update-packages`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
            unlockByExpeditionId: (token: string, expeditionId: string) => {
                return this.get(`/external-logistics/expedition/unlock-expedition/${expeditionId}`, {
                    headers: this.getHeaders(token),
                });
            },
            expeditionsToPickUp: token => {
                return this.get<TransportWithQuantity[]>(`external-logistics/expedition/expeditions-to-pick-up`, {
                    headers: this.getHeaders(token, false),
                });
            },
            getExpeditionsPackages: (body: GetExpeditionsPackagesParameters, token) => {
                return this.post(`external-logistics/expedition/get-expedition-packages`, body, {
                    headers: this.getHeaders(token, false),
                });
            },
        }),
        expeditionIncidence: Object.freeze({
            getExpeditionById: (expeditionId: string, token: string) => {
                return this.get(`external-logistics/el-expedition-incidence/detailed/${expeditionId}`, {
                    headers: this.getHeaders(token),
                });
            },
            expeditionIncidenceManage: (body, expeditionId: string, token: string) => {
                return this.post(`external-logistics/el-expedition-incidence/manage/${expeditionId}`, body, {
                    headers: this.getHeaders(token),
                });
            },
            incidenceSearch: (token: string, body?) => {
                return this.post(`/external-logistics/el-incidence/search`, body, {
                    headers: this.getHeaders(token),
                });
            },
            getIncidenceFilters: (token: string) => {
                return this.get(`external-logistics/el-incidence/filters`, {
                    headers: this.getHeaders(token),
                });
            },
        }),
        packingProcess: Object.freeze({
            getPackingProcessCurrent: (token: string) => {
                return this.get(`external-logistics/embalaje-process/current`, {
                    headers: this.getHeaders(token),
                });
            },
            postPackingProcessComplete: (id, token: string) => {
                return this.post(`external-logistics/embalaje-process/current/${id}/complete`, '', {
                    headers: this.getHeaders(token),
                });
            },
            postPackingProcessAddProduct: (barcode, token: string) => {
                return this.post(`external-logistics/embalaje-process/add-product/${barcode}`, '', {
                    headers: this.getHeaders(token),
                });
            },
            deletePackingProcessCurrent: (id, token: string) => {
                return this.delete(`external-logistics/embalaje-process/current/${id}`, {
                    headers: this.getHeaders(token),
                });
            },
        }),
    });

    readonly fileStore = Object.freeze({
        getStreamBody: (id: number, token) => {
            return this.get(`file-store/${id}/stream-body`, {
                headers: this.getHeaders(token),
                responseType: 'blob',
            });
        },
        stringBody: (id: number, token) => {
            return this.get<any>(`file-store/${id}/string-body`, {
                headers: this.getHeaders(token),
                responseType: 'text',
            });
        },
    });

}
