import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-supplier-select',
    templateUrl: './supplier-select.component.html',
    styleUrls: ['./supplier-select.component.css'],
})
export class SupplierSelectComponent implements OnInit {
    displayedColumns: string[] = ['Proveedor', 'Marca', 'Unidades'];
    dataSource = [1, 1, 1, 1, 1, 1, 1, 1];

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
