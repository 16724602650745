import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { isPlatformBrowser } from '@angular/common';
import {
    Directive,
    Input,
    Inject,
    ElementRef,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[appScrollToItem]',
})
export class ScrollToItemDirective {
    @Input() appScrollToItem: boolean | undefined;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private elementRef: ElementRef
    ) {}

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (coerceBooleanProperty(this.appScrollToItem)) {
                setTimeout(() => {
                    this.elementRef.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }, 2000);
            }
        }
    }
}
