import { MenuItemList } from './menu.data';

export const workwavesMenu: MenuItemList = [
    {
        title: 'Olas de trabajo',
        open: false,
        type: 'wrapper',
        icon: 'hammer-outline',
        permissions: ['core.stock.workwave', '#globalAdmin'],
        children: [
            {
                title: 'Picking directo/consolidado',
                id: 'workwaves-direct-consolidated',
                url: '/workwave-template-rebuild',
                icon: 'add-circle-outline',
                permissions: ['#globalAdmin'],
            },
            {
                title: 'Peticiones online/tienda',
                id: 'workwaves-online-store',
                url: '/workwave/online-store',
                icon: 'add-circle-outline',
                permissions: ['#globalAdmin'],
            },
            {
                title: 'Listado',
                id: 'workwaves-scheduled',
                url: '/workwaves-scheduled',
                icon: 'code-outline',
                permissions: ['#globalAdmin'],
            },
            {
                title: 'Historial',
                id: 'workwaves-history',
                url: '/workwaves-history',
                icon: 'code-download-outline',
                permissions: ['#globalAdmin'],
            },
            {
                title: 'Pickings en curso',
                id: 'pickings-execution',
                url: '/workwaves-scheduled/pickings',
                icon: 'code-working-outline',
                permissions: ['#globalAdmin'],
            },
        ],
    },
];
