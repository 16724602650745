import { ModelModel } from './model.model';
import { SizeModel } from './size.model';
import { WarehouseModel } from './warehouse.model';

export namespace StockModel {
    export interface Stock {
        id: number;
        model: ModelModel.Model;
        warehouse: WarehouseModel.Warehouse;
        size: SizeModel.Size;
        cantidad: number;
        numRange: number;
    }
}
