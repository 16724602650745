import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiMiddleware } from './api-middleware';

@Injectable({
    providedIn: 'root',
})
export class MiddlewareService {
    readonly api: ApiMiddleware;
    constructor(http: HttpClient) {
        this.api = new ApiMiddleware(http, environment.apiMiddleware);
    }
}
