/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CatalogService } from './service';
import * as actions from './actions';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CatalogEffect {
    constructor(private actions$: Actions, private service: CatalogService) {}

    syncSizes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.syncSizesAction),
            switchMap(() => {
                return this.service.syncSizes().pipe(
                    map(data => {
                        return actions.syncSizesSuccessAction(data);
                    }),
                    catchError(err => of(actions.syncSizesFailAction(err)))
                );
            })
        )
    );

    getWarehouseList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getWarehouseListAction),
            switchMap(state => {
                return this.service.getWarehouseList(state.params).pipe(
                    map(data => {
                        return actions.getWarehouseListSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.getWarehouseListFailAction(err))
                    )
                );
            })
        )
    );

    getWarehouseItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getWarehouseItemAction),
            switchMap(() => {
                return this.service.getWarehouseItem().pipe(
                    map(data => {
                        return actions.getWarehouseItemSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.getWarehouseItemFailAction(err))
                    )
                );
            })
        )
    );
    updateWarehouseItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateWarehouseItemAction),
            switchMap(state => {
                return this.service
                    .updateWarehouseItem(state.warehouseId, state.params)
                    .pipe(
                        map(data => {
                            return actions.updateWarehouseItemSuccessAction(
                                data
                            );
                        }),
                        catchError(err =>
                            of(actions.updateWarehouseItemFailAction(err))
                        )
                    );
            })
        )
    );
    syncWarehouse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.syncWarehouseAction),
            switchMap(() => {
                return this.service.syncWarehouse().pipe(
                    map(data => {
                        return actions.syncWarehouseSuccessAction(data);
                    }),
                    catchError(err => of(actions.syncWarehouseFailAction(err)))
                );
            })
        )
    );
    getRackList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRackListAction),
            switchMap(state => {
                return this.service.getRackList(state.params).pipe(
                    map(data => {
                        return actions.getRackListSuccessAction(data);
                    }),
                    catchError(err => of(actions.getRackListFailAction(err)))
                );
            })
        )
    );
    getRackItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRackItemAction),
            switchMap(() => {
                return this.service.getRackItem().pipe(
                    map(data => {
                        return actions.getRackItemSuccessAction(data);
                    }),
                    catchError(err => of(actions.getRackItemFailAction(err)))
                );
            })
        )
    );
    resizeRackItemAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.resizeRackItemAction),
            switchMap(state => {
                return this.service
                    .resizeRackItemAction(state.id, state.params)
                    .pipe(
                        map(data => {
                            return actions.resizeRackItemSuccessAction(data);
                        }),
                        catchError(err =>
                            of(actions.resizeRackItemFailAction(err))
                        )
                    );
            })
        )
    );
    getContainerList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getContainerListAction),
            switchMap(state => {
                return this.service.getContainerList(state.params).pipe(
                    map(data => {
                        return actions.getContainerListSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.getContainerListFailAction(err))
                    )
                );
            })
        )
    );
    getContainerItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getContainerItemAction),
            switchMap(() => {
                return this.service.getContainerItem().pipe(
                    map(data => {
                        return actions.getContainerItemSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.getContainerItemFailAction(err))
                    )
                );
            })
        )
    );
    setLockedContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setLockedContainerItemAction),
            switchMap(state => {
                return this.service
                    .setLockedContainer(state.containerId, state.value)
                    .pipe(
                        map(data => {
                            return actions.setLockedContainerItemSuccessAction(
                                data
                            );
                        }),
                        catchError(err =>
                            of(actions.setLockedContainerItemFailAction(err))
                        )
                    );
            })
        )
    );
    getSizesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSizesListAction),
            switchMap(() => {
                return this.service.getSizesList().pipe(
                    map(data => {
                        return actions.getSizesListSuccessAction(data);
                    }),
                    catchError(err => of(actions.getSizesListFailAction(err)))
                );
            })
        )
    );
    getDomainSizes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDomainSizesListAction),
            switchMap(() => {
                return this.service.getDomainSizes().pipe(
                    map(data => {
                        return actions.getDomainSizesListSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.getDomainSizesListFailAction(err))
                    )
                );
            })
        )
    );
    getPackingItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPackingItemAction),
            switchMap(() => {
                return this.service.getPackingItem().pipe(
                    map(data => {
                        return actions.getPackingItemSuccessAction(data);
                    }),
                    catchError(err => of(actions.getPackingItemFailAction(err)))
                );
            })
        )
    );
    getPackingListAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPackingListAction),
            switchMap(() => {
                return this.service.getPackingListAction().pipe(
                    map(data => {
                        return actions.getPackingListSuccessAction(data);
                    }),
                    catchError(err => of(actions.getPackingListFailAction(err)))
                );
            })
        )
    );
    updatePackingItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updatePackingItemAction),
            switchMap(() => {
                return this.service.updatePackingItem().pipe(
                    map(data => {
                        return actions.updatePackingItemSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.updatePackingItemFailAction(err))
                    )
                );
            })
        )
    );
    getMarketList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketListAction),
            switchMap(() => {
                return this.service.getMarketList().pipe(
                    map(data => {
                        return actions.getMarketListSuccessAction(data);
                    }),
                    catchError(err => of(actions.getMarketListFailAction(err)))
                );
            })
        )
    );
    deleteMarketItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteMarketItemAction),
            switchMap(() => {
                return this.service.deleteMarketItem().pipe(
                    map(data => {
                        return actions.deleteMarketItemSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.deleteMarketItemFailAction(err))
                    )
                );
            })
        )
    );
    updateMarketItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMarketItemAction),
            switchMap(() => {
                return this.service.updateMarketItem().pipe(
                    map(data => {
                        return actions.updateMarketItemSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.updateMarketItemFailAction(err))
                    )
                );
            })
        )
    );
    getMarketItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketItemAction),
            switchMap(() => {
                return this.service.getMarketItem().pipe(
                    map(data => {
                        return actions.getMarketItemSuccessAction(data);
                    }),
                    catchError(err => of(actions.getMarketItemFailAction(err)))
                );
            })
        )
    );
}
