import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-table-header-checkbox',
    templateUrl: './table-header-checkbox.component.html',
    styleUrls: ['./table-header-checkbox.component.scss'],
})
export class TableHeaderCheckboxComponent implements OnInit {

    @Input() selectAll: boolean = false;
    @Input() selectedItems: any[] = [];
    @Input() currentPageData: any[] = [];
    @Input() selectedItemsCount: number = 0;
    @Input() allAlreadySelected: boolean = false;
    @Input() row;
    @Output() toggleSelectOutput = new EventEmitter<any>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
    }

    emitToggleSelectEvent() {
        let data = {
            selectedItemsCount: this.selectedItemsCount,
            allAlreadySelected: this.allAlreadySelected,
            selectedItems: this.selectedItems,
            selectAll: this.selectAll
        }
        this.toggleSelectOutput.emit(data);
    }

    toggleSelectAll(allAlreadySelected?, selectedItemsCount?) {
        this.selectedItemsCount = selectedItemsCount ?? this.selectedItemsCount;
        this.allAlreadySelected = allAlreadySelected ?? this.allAlreadySelected;

        this.selectAll = true;
        if(this.allAlreadySelected){
            this.currentPageData.forEach((item: any) => {
                this.selectAll = false;
                item.selected = this.selectAll;
                this.selectedItems = this.selectedItems.filter((deleteItem) => deleteItem.id !== item.id);
                this.allAlreadySelected = false;
            });
        }else{
            this.currentPageData.forEach((item: any) => {
                item.selected = this.selectAll;
                if (!this.selectedItems.includes(item)) {
                    this.selectedItems.push(item);
                }
                this.allAlreadySelected = true;
            });
        }

        this.changeDetectorRef.detectChanges();
        this.emitToggleSelectEvent();
    }

    onMainCheckboxChange() {
        if (this.selectAll) {
            // The main checkbox has been selected
            this.selectedItemsCount = this.currentPageData.length;
        } else {
            // Main checkbox has been deselected
            this.selectedItemsCount = 0;
        }
        this.emitToggleSelectEvent();
    }

    resetCheckbox(){
        this.selectAll = false;
        this.selectedItems = [];
        this.selectedItemsCount = 0;
        this.allAlreadySelected = false
        this.emitToggleSelectEvent();
    }
}
