import { InventoryModel, ProductModel, WarehouseModel } from './';

export namespace SorterControlProductModel {

    export interface SorterControlProduct {
        inventory: InventoryModel.Inventory;
        inventoryId: number;
        product: ProductModel.Product;
        productId: number;
        destinationWarehouse: WarehouseModel.Warehouse;
        destinationWarehouseId: number;
        status: ControlProductStatus;
        lineRequestReference: string;
        deliveryRequestReference: number;
        ventilated: boolean;
    }

}

export enum ControlProductStatus {
    preassigned = 'preassigned',
    assigned = 'assigned',
    sent = 'sent',
    received = 'received',
}
