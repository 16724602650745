import { MenuItemList } from './menu.data';

export const marketplacesMenu:MenuItemList = [{
        title: 'Marketplaces',
        open: false,
        type: 'wrapper',
        icon: 'cart-outline',
        permissions: ['global.core.catalog.market'],
        children: [
            {
                title: 'Catálogos Marketplaces',
                id: 'catalogs-marketplaces',
                url: '/marketplaces/catalogs-marketplaces',
                icon: 'apps-outline',
            },
        ],
    }];
