import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from 'src/app/shared/message.service';
import { AuthSandbox } from '../auth/auth.sandbox';

const skipList: string[] = ['auth/login'];

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authSandbox: AuthSandbox,
        private message: MessageService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let token = sessionStorage.getItem('token');

        const isSkipHeader = !!skipList.find(el => req.url.indexOf(el) >= 0);

        if (token && !isSkipHeader) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            });
        }

        return next.handle(req).pipe(
            map(data => data),
            catchError(response => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
                switch (response.status) {
                    case 400:
                        console.error(
                            'Bad request. ' + response?.error?.message
                        );
                        break;
                    case 401:
                        if (!['userConfirmationRequired', 'userConfirmationRequiredToForce', 'invalidAction'].includes(response.error?.errorCode)){
                            this.handleUnAuthorized();
                        }
                        break;
                    case 403:
                        this.handleUnAuthorized();
                        break;
                    default:
                        break;
                }
                return throwError(response);
            })
        );
    }

    private handleUnAuthorized(): void {
        console.error('Unauthorized');
        this.message.log('Unauthorized');
        this.authSandbox.doLogout();
        this.router.navigate(['/login']);
    }
}
