import { MenuItemList } from './menu.data';

export const configMenu = [
    {
        title: 'Configuración',
        open: false,
        type: 'wrapper',
        icon: 'options-outline',
        isMobile: true,
        children: [
            {
                title: 'Variables globales',
                id: 'global-variables',
                url: '/global-variables',
                icon: 'cog-outline',
                permissions: ['global.core.global-variable', '#globalAdmin'],
            },
            // ¿Do we need this?
            // {
            //     title: 'Recepcion Final',
            //     id: 'reception-final',
            //     url: '/reception-final',
            //     icon: 'pricetags-outline',
            // },
            {
                title: 'Almacenes',
                id: 'warehouses',
                url: '/warehouses',
                // icon: 'file-tray-stacked-outline',
                icon: 'file-tray-full-outline',
                permissions: ['core.catalog.warehouse'],
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Grupos de almacenes',
            //     id: 'warehouses-group',
            //     url: '/groups/menu',
            //     icon: 'person-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Asignar grupos de almacenes',
            //     id: 'group-to-warehouse',
            //     url: '/group-to-warehouse',
            //     icon: 'people-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Grupos de tiendas para picking',
            //     id: 'group-warehouse-picking',
            //     url: '/group-warehouse-picking',
            //     icon: 'people-outline',
            // },
            {
                title: 'Agencias de transporte',
                id: 'online-orders-transporters',
                url: '/transports',
                icon: 'train-outline',
                permissions: ['#globalAdmin'],
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Edificios',
            //     id: 'building',
            //     url: '/building',
            //     icon: 'business-outline',
            // },
            {
                title: 'Impresora de etiqueta producto',
                id: 'config-printer',
                url: '/settings',
                icon: 'print-outline',
                tooltip: 'Mac de la impresora',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Servicio impresión etiquetas pedidos',
                id: 'config-equipment-ip-code',
                url: '/settings-print-service',
                icon: 'desktop-outline',
                tooltip: 'IP equipo de impresión',
                isMobile: true,
                isWeb: false,
            },
        ],
    },
];
