import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-unidades-detail',
    templateUrl: './unidades-detail.component.html',
    styleUrls: ['./unidades-detail.component.css'],
})
export class UnidadesDetailComponent implements OnInit {
    data = [1, 1, 1, 1, 1, 1, 1, 1];

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
