import { Component, OnDestroy, OnInit } from '@angular/core';
import { MarketplacesSandbox } from 'src/app/core/api-middleware/marketplaces/sandbox';
import { CommonSandbox } from 'src/app/core/common/common.sandbox';
import { MenuController} from '@ionic/angular';
import { MobileCheckService } from '../../../app/core/mobile-check.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    animations: [
        trigger('menuSlide', [
            state('closed', style({
                transform: 'translateX(-0%)'
            })),
            state('open', style({
                transform: 'translateX(0)'
            })),
            transition('closed <=> open', animate('0.3s ease-in-out'))
        ])
    ]
})
export class MainLayoutComponent implements OnInit {
    public appPages = [
        { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
        { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
        { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
        { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
        { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
        { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    ];
    public labels = [
        'Family',
        'Friends',
        'Notes',
        'Work',
        'Travel',
        'Reminders',
    ];

    small = false;

    isMobile: boolean;
    homePage: boolean;

    constructor(
        private commonSandbox: CommonSandbox,
        public marketplacesSandbox: MarketplacesSandbox,
        public menuController: MenuController,
        private mobileCheckService: MobileCheckService,
        private router: Router,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.homePage = this.router.url === '/home';
        });
    }

    async ngOnInit() {
        this.isMobile = this.mobileCheckService.checkIsMobile();
        this.marketplacesSandbox.doGetMarketplaces();

        this.commonSandbox.sidebarToggle$.subscribe(res => {
            this.small = !this.small;
            if (!this.small) {
                sessionStorage.setItem('iconMenu', 'assets/icon/menu-right.svg');
            } else {
                sessionStorage.setItem('iconMenu', 'assets/icon/menu-left.svg');
            }
        });
    }

    closeMenu(): void {
        this.menuController.close('start');
    }

    async openHomePage() {
        if (!this.small) {
            sessionStorage.setItem('iconMenu', 'assets/icon/menu-right.svg');
        } else {
            sessionStorage.setItem('iconMenu', 'assets/icon/menu-right.svg');
        }
        await this.router.navigate(['/home']);
    }
}
