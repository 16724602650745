import { TariffModel, WarehouseModel } from './';

export namespace TariffWarehouseModel {

    export interface TariffWarehouse {
        id: number;
        createdAt: Date;
        updatedAt: Date;
        tariff: TariffModel.Tariff;
        tariffId: number;
        warehouse:  WarehouseModel.Warehouse;
        warehouseId: number;
        isUpdated: boolean;
    }

    export interface TariffWarehouseOption extends TariffWarehouse {
        optionSelected?: string;
        optionsStatusValues?: string[];
        isAvailableFilterPrice?: boolean;
    }

}
