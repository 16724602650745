import { ExpeditionModel, OrderProductModel, TransportModel, WarehouseModel } from './';

export namespace OrderModel {

    export interface Order {
        deliveryRequestExternalId: string;
        deliveryRequestId: number;
        market: any;
        marketId: string;
        status: OrderStatus;
        containerId: number;
        destinationWarehouse: WarehouseModel.Warehouse;
        destinationWarehouseId: number;
        originWarehouse: WarehouseModel.Warehouse;
        originWarehouseId: number;
        trackingInfo: string;
        note: string;
        products: OrderProductModel.OrderProduct[];
        transport: TransportModel.Transport;
        transportId: number;
        expedition: ExpeditionModel.Expedition;
        expeditionId: number;
        date: string;
    }

}

export enum OrderStatus {
    generatedTagLogisticInternal = 'generatedTagLogisticInternal',
    pickedUpIntern = 'pickedUpIntern',
}
