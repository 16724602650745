export interface DictionaryState {
    syncColorData: any;
    syncColorFailData: any;
    syncColorLoading: any;

    getColorListData: any;
    getColorListFailData: any;
    getColorListLoading: any;

    addColorItemData: any;
    addColorItemFailData: any;
    addColorItemLoading: any;

    deleteColorItemData: any;
    deleteColorItemFailData: any;
    deleteColorItemLoading: any;

    getProviderItemData: any;
    getProviderItemFailData: any;
    getProviderItemLoading: any;

    getProviderListData: any;
    getProviderListFailData: any;
    getProviderListLoading: any;
}

export const dictionaryRecord: DictionaryState = {
    syncColorData: null,
    syncColorFailData: null,
    syncColorLoading: null,

    getColorListData: null,
    getColorListFailData: null,
    getColorListLoading: null,

    addColorItemData: null,
    addColorItemFailData: null,
    addColorItemLoading: null,

    deleteColorItemData: null,
    deleteColorItemFailData: null,
    deleteColorItemLoading: null,

    getProviderItemData: null,
    getProviderItemFailData: null,
    getProviderItemLoading: null,

    getProviderListData: null,
    getProviderListFailData: null,
    getProviderListLoading: null,
};
