export const API_RULES_ENDPOINTS = {
    getBrandsRuleFilters: '/api/RuleFilter/5',
    getSizesRuleFilters: '/api/RuleFilter/4',
    getColorsRuleFilters: '/api/RuleFilter/3',
    getFeaturesRuleFilters: '/api/RuleFilter/2',
    getSeasonsRuleFilters: '/api/RuleFilter/30',
    getRulesConfigurationsUrl: '/rc/bymarket/',
    updateRulesConfigurationsUrl: '/api/RuleConfiguration/',
    getCategories: '/api/ProductCategory',
    postRulesConfigurationsUrl: '/api/RuleConfiguration',
    deleteRuleUrl: '/api/RuleConfiguration/',
    getMapDataRulesUrl: '/api/MapDataRules',
    deleteMapDataRulesUrl: '/api/MapDataRules/',
    updateMapDataRulesUrl: '/api/MapDataRules/',
    postMapDataRulesUrl: '/api/MapDataRules/',
};
