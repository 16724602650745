import { Component, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[title]',
})
export class TitleDIrective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.style.color = 'black';
        this.el.nativeElement.style.fontSize = '17px';
        this.el.nativeElement.style.lineHeight = '20px';
        this.el.nativeElement.style.fontWeight = 'bold';
        // this.el.nativeElement.style.padding = '5px';
    }
}
