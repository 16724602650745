import { ModelModel, OrderModel, ProductModel, SizeModel } from './';

export namespace OrderProductModel {

    export interface OrderProduct {
        order: OrderModel.Order;
        orderId: number;
        product: ProductModel.Product;
        productId: number;
        model: ModelModel.Model;
        modelId: number;
        size: SizeModel.Size;
        sizeId: number;
    }

}
