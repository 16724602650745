import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NativeAudio } from '@ionic-native/native-audio/ngx';

@Injectable({
    providedIn: 'root',
})
export class AudioProvider {
    audioType: string = 'html5';
    sounds: any = [];

    constructor(public nativeAudio: NativeAudio, platform: Platform) {
        if (platform.is('cordova')) {
            this.audioType = 'native';
        }
    }

    preload(key, asset) {
        if (this.audioType === 'html5') {
            let audio = {
                key: key,
                asset: asset,
                type: 'html5',
            };
            this.sounds.push(audio);
        } else {
            this.nativeAudio.preloadSimple(key, asset).then(
                () => {
                    let audio = {
                        key: key,
                        asset: key,
                        type: 'native',
                    };
                    this.sounds.push(audio);
                },
                err => {
                    console.error('Error preloading audio:', err);
                }
            );
        }
    }

    play(key) {
        let audio = this.sounds.find(sound => {
            return sound.key === key;
        });

        if (audio.type === 'html5') {
            let audioAsset = new Audio(audio.asset);
            audioAsset.play();
        } else {
            this.nativeAudio.play(audio.asset).then(
                res => {},
                err => {
                    console.log(err);
                }
            );
        }
    }

    stop(key) {
        let audio = this.sounds.find(sound => {
            return sound.key === key;
        });

        if (audio.type === 'html5') {
            let audioAsset = new Audio(audio.asset);
            audioAsset.pause();
        } else {
            this.nativeAudio.stop(audio.asset).then(
                res => {},
                err => {
                    console.log(err);
                }
            );
        }
    }

    playDefaultError() {
        this.play('responseRequestError');
    }

    playDefaultOk() {
        this.play('responseRequestOk');
    }
}
