import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CatalogSandbox } from 'src/app/core/catalog/sandbox';
import { MessageService } from '../../message.service';

@Component({
    selector: 'app-lock-container',
    templateUrl: './lock-container.component.html',
    styleUrls: ['./lock-container.component.scss'],
})
export class LockContainerComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    public container;
    locked: FormControl = new FormControl(false);

    constructor(
        private modalCtrl: ModalController,
        public catalogSandbox: CatalogSandbox,
        private message: MessageService
    ) {
        this.catalogSandbox.doClearSetLockedContainerItemState();
    }

    ngOnInit() {
        if (this.container) {
            this.locked.patchValue(this.container.locked);
        }

        this.subs.push(
            this.catalogSandbox.setLockedContainerData$.subscribe(res => {
                if (res) {
                    this.message.log('Container updated!');
                    this.modalCtrl.dismiss(res);
                }
            })
        );
        this.subs.push(
            this.catalogSandbox.setLockedContainerFailData$.subscribe(res => {
                if (res) {
                    this.message.log('Unable to update container');
                    this.locked.enable();
                }
            })
        );
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.locked.disable();

        if (this.locked.value === this.container.locked) {
            this.modalCtrl.dismiss(this.container.locked);
        }

        this.catalogSandbox.doSetLockedContainerItem(
            this.container.id,
            this.locked.value ? 1 : 0
        );
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
