export namespace SeasonModel {
    export interface Season {
        createdAt: string;
        updatedAt: string;
        id: number;
        name: string;
        avelonId: string;
        datasetHash: string;
    }
}
