import { createAction, props } from '@ngrx/store';

//
export const syncColorAction = createAction('sync color', props<any>());
export const syncColorSuccessAction = createAction(
    'sync color succcess',
    props<any>()
);
export const syncColorFailAction = createAction(
    'sync color fail',
    props<any>()
);
//
export const getColorListAction = createAction('get color list', props<any>());
export const getColorListSuccessAction = createAction(
    'get color list success',
    props<any>()
);
export const getColorListFailAction = createAction(
    'get color list fail',
    props<any>()
);
//
export const addColorItemAction = createAction('add color item', props<any>());
export const addColorItemSuccessAction = createAction(
    'add color item success',
    props<any>()
);
export const addColorItemFailAction = createAction(
    'add color item fail',
    props<any>()
);
//
export const deleteColorItemAction = createAction(
    'delete color item',
    props<any>()
);
export const deleteColorItemSuccessAction = createAction(
    'delete color item success',
    props<any>()
);
export const deleteColorItemFailAction = createAction(
    'delete color item fail',
    props<any>()
);
//
export const getProviderItemAction = createAction(
    'get provider item',
    props<any>()
);
export const getProviderItemSuccessAction = createAction(
    'get provider item success',
    props<any>()
);
export const getProviderItemFailAction = createAction(
    'get provider item fail',
    props<any>()
);
//
export const getProviderListAction = createAction(
    'get provider list',
    props<any>()
);
export const getProviderListSuccessAction = createAction(
    'get provider list success',
    props<any>()
);
export const getProviderListFailAction = createAction(
    'get provider list fail',
    props<any>()
);
