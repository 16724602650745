import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-table-checkboxes',
    templateUrl: './table-checkboxes.component.html',
    styleUrls: ['./table-checkboxes.component.scss'],
})
export class TableCheckboxesComponent implements OnInit {
    @Input() selectedItems: any[] = [];
    @Input() currentPageData: any[] = [];
    @Input() selectedItemsCount: number = 0;
    @Input() allAlreadySelected: boolean = false;
    @Input() row;
    @Input() selectAll;
    @Output() toggleSelectOutput = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    emitToggleSelectEvent() {
        let data = {
            selectedItemsCount: this.selectedItemsCount,
            allAlreadySelected: this.allAlreadySelected,
            selectedItems: this.selectedItems,
            selectAll: this.selectAll,
        };
        this.toggleSelectOutput.emit(data);
    }

    toggleSelectedItem(item: any) {
        item.selected = true;

        this.selectedItems.forEach(itemSelected => {
            if (item.id === itemSelected.id) {
                if (item.selected === true) {
                    item.selected = false;
                    this.selectedItems = this.selectedItems.filter(
                        deleteItem => deleteItem.id !== item.id
                    );
                    this.selectedItemsCount--;
                    if (
                        this.selectedItems.length < this.currentPageData.length
                    ) {
                        this.selectAll = false;
                    }
                    if (this.selectedItems.length === 0) {
                        this.selectedItems = [];
                        this.allAlreadySelected = true;
                    }
                }
            }
        });

        if (item.selected) {
            if (!this.selectedItems.includes(item)) {
                this.selectedItems.push(item);
            }
            this.selectedItemsCount++;

            if (this.currentPageData.length === this.selectedItemsCount) {
                this.allAlreadySelected = false;
            }
        }
        this.emitToggleSelectEvent();
    }
}
