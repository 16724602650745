export namespace HttpRequestModel {

    export interface CrudPaginatedResponse<T = any> {
        data: T[];
        count: number;
        page: number;
        pageCount: number;
        total: number;
    }

    export interface HttpErrorResponse {
        error: Error,
        headers: any;
        message: string;
        name: string;
        ok: boolean;
        status: number;
        statusText: string;
        url: string;
    }

    export interface Error {
        errorCode: string,
        message?: string,
        errors?: {
            message: string,
            path: string[],
        }[],
        object?: any,
        name: string,
        path: string,
        timestamp: string,
    }

    export interface Response {
        message?: string,
        code?: number,
        data?: any,
        error?: any,
        errors?: any
    }

}
