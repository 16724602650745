import { Marketplaces } from 'src/app/models/marketplaces.models';
import { ResponseModel } from 'src/app/models/response.model';

export interface MarketplacesState {
    getMarketplacesData: Marketplaces.MarketItem[];
    getMarketplacesFailData: any;
    getMarketplacesLoading: boolean;

    searchProductCatalogData: ResponseModel<Marketplaces.ProductCatalog[]>;
    searchProductCatalogFailData: any;
    searchProductCatalogLoading: boolean;

    searchProductWithVariationsCatalogData: any;
    searchProductWithVariationsCatalogFailData: any;
    searchProductWithVariationsCatalogLoading: boolean;

    getBrandsData: any;
    getBrandsFailData: any;
    getBrandsLoading: any;

    getCachedMarketById: Marketplaces.MarketItem;

    getSizesData: any;
    getSizesFailData: any;
    getSizesLoading: any;

    getColorsData: any;
    getColorsFailData: any;
    getColorsLoading: any;

    getFeaturesData: any;
    getFeaturesFailData: any;
    getFeaturesLoading: any;

    getSeasonsData: any;
    getSeasonsFailData: any;
    getSeasonsLoading: any;

    getMarketBrandsData: any;
    getMarketBrandsFailData: any;
    getMarketBrandsLoading: any;

    getMarketSizesData: any;
    getMarketSizesFailData: any;
    getMarketSizesLoading: any;

    getMarketColorsData: any;
    getMarketColorsFailData: any;
    getMarketColorsLoading: any;

    getMarketFeaturesData: any;
    getMarketFeaturesFailData: any;
    getMarketFeaturesLoading: any;

    getMarketSeasonsData: any;
    getMarketSeasonsFailData: any;
    getMarketSeasonsLoading: any;

    getRulesConfigurationsData: any;
    getRulesConfigurationsFailData: any;
    getRulesConfigurationsLoading: any;

    postUpdateZalandoSizeMapWithApiData: any;
    postUpdateZalandoSizeMapWithApiFailData: any;
    postUpdateZalandoSizeMapWithApiLoading: any;

    updateRulesConfigurationsData: any;
    updateRulesConfigurationsFailData: any;
    updateRulesConfigurationsLoading: any;

    updateCategoriesMinPricesData: any;
    updateCategoriesMinPricesFailData: any;
    updateCategoriesMinPricesLoading: any;
}

export const marketplacesRecord: MarketplacesState = {
    getMarketplacesData: null,
    getMarketplacesFailData: null,
    getMarketplacesLoading: false,

    searchProductCatalogData: null,
    searchProductCatalogFailData: null,
    searchProductCatalogLoading: false,

    searchProductWithVariationsCatalogData: null,
    searchProductWithVariationsCatalogFailData: null,
    searchProductWithVariationsCatalogLoading: false,

    getBrandsData: null,
    getBrandsFailData: null,
    getBrandsLoading: null,

    getCachedMarketById: null,

    getSizesData: null,
    getSizesFailData: null,
    getSizesLoading: null,

    getColorsData: null,
    getColorsFailData: null,
    getColorsLoading: null,

    getFeaturesData: null,
    getFeaturesFailData: null,
    getFeaturesLoading: null,

    getSeasonsData: null,
    getSeasonsFailData: null,
    getSeasonsLoading: null,

    getMarketBrandsData: null,
    getMarketBrandsFailData: null,
    getMarketBrandsLoading: null,

    getMarketSizesData: null,
    getMarketSizesFailData: null,
    getMarketSizesLoading: null,

    getMarketColorsData: null,
    getMarketColorsFailData: null,
    getMarketColorsLoading: null,

    getMarketFeaturesData: null,
    getMarketFeaturesFailData: null,
    getMarketFeaturesLoading: null,

    getMarketSeasonsData: null,
    getMarketSeasonsFailData: null,
    getMarketSeasonsLoading: null,

    getRulesConfigurationsData: null,
    getRulesConfigurationsFailData: null,
    getRulesConfigurationsLoading: null,

    postUpdateZalandoSizeMapWithApiData: null,
    postUpdateZalandoSizeMapWithApiFailData: null,
    postUpdateZalandoSizeMapWithApiLoading: null,

    updateRulesConfigurationsData: null,
    updateRulesConfigurationsFailData: null,
    updateRulesConfigurationsLoading: null,

    updateCategoriesMinPricesData: null,
    updateCategoriesMinPricesFailData: null,
    updateCategoriesMinPricesLoading: null,
};
