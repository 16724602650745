export async function getToken() {
    const getToken = sessionStorage.getItem('accessToken');
    const user = JSON.parse(getToken);
    return user.token;
}

export async function getCurrentTokenAndUserId(): Promise<UserRequestData> {
    const getToken = sessionStorage.getItem('accessToken');
    const accessToken = JSON.parse(getToken);

    const userId = sessionStorage.getItem('userId');
    return {
        token: accessToken.token,
        userId: Number(userId),
    } as UserRequestData;
}

export interface UserRequestData {
    token: string;
    userId: number;
}
