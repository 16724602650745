
export namespace FiltersModel {
    export interface Default {
        id: number;
        reference?: number;
        name: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Reference {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Model {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Color {
        id: number;
        name: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Size {
        id: number;
        reference: number;
        name: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Warehouse {
        id: number;
        reference: string;
        referenceFilter: string;
        name: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Container {
        id: number;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Brand {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Supplier {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Status {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Found {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface Online {
        id: number;
        name: string;
        reference: string;
        value: string;
        checked: boolean;
        hide: boolean;
    }
}
