export namespace WarehouseModel {

    export interface Warehouse {
        id: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        name: string;
        description: string;
        isStore: boolean;
        isMain: boolean;
        isDefective: boolean;
        enabled: boolean;
        companyName: string;
        address1: string;
        address2: string;
        zipCode: string;
        city: string;
        province: string;
        countryId: number;
        countryName: string;
        phone: string;
        allowsReceptions: boolean;
        thresholdShippingStore: number;
        email: string;
        salesReplacement: boolean;
        noForceLocationMainWarehouse: boolean;
        moveWithStockCorrections: boolean;
        noStockDrSync: boolean;
        isReceptionAlertAllowed: boolean;
        reference: string;
        isOutlet: boolean;
        config?: any;
    }

    export interface ResponseModel {
        count: number;
        data: Warehouse[];
        page: number;
        pageCount: number;
        total: number;
    }

}
