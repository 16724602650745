import { Injectable } from '@angular/core';
import { DateTimeParserService } from './date-time-parser.service';

@Injectable({
    providedIn: 'root',
})
export class DownloaderService {

    readonly downloads = {
        directoryBase: 'Download',
        directoryAL: 'AL',
        directoryExpeditions: 'expediciones',
        directoryManifests: 'manifiestos',
        directoryReturns: 'devoluciones'
    };

    constructor(private dateTimeParserService: DateTimeParserService) {}

    public getExpeditionDownloadedFileName(
        extension: string,
        expeditionDeliveryRequest?: string
    ) {
        const currentTime = new Date();

        const requestName = expeditionDeliveryRequest || 'UNKNOWN';
        const date = this.dateTimeParserService
            .globalFormat(currentTime)
            .replace(/\//gi, '');
        const time = this.dateTimeParserService
            .hourMinute(currentTime)
            .replace(/:/gi, '');

        return `${requestName}_${date}_${time}.${extension}`;
    }

    public getExpeditionManifestDownloadedFileName(extension: string) {
        const currentTime = new Date();

        const requestName = 'manifiesto';
        const date = this.dateTimeParserService
            .globalFormat(currentTime)
            .replace(/\//gi, '');
        const time = this.dateTimeParserService
            .hourMinute(currentTime)
            .replace(/:/gi, '');

        return `${requestName}_${date}_${time}.${extension}`;
    }
}
