import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReceptionAvelonModel } from '../../models/receptions-avelon.model';

@Component({
    selector: 'app-size-select',
    templateUrl: './size-select.component.html',
    styleUrls: ['./size-select.component.scss'],
})
export class SizeSelectComponent implements OnInit {
    @Input() item: ReceptionAvelonModel.LoadSizesList = null;
    @Output() sizeSelected = new EventEmitter();

    public bigItems = false;

    constructor() {}

    ngOnInit() {
        // if (app.name == 'sga') {
        this.bigItems = true;
        // }
    }

    public selectValue(item) {
        item.quantity = 1;
        this.sizeSelected.emit(item);
    }
}
