export namespace InternalGroupModel {
    export interface InternalGroup {
        id: number;
        avelonId: number;
        reference: string;
        name: string;
        groupNumber: number;
        datasetHash: string;
    }
}
