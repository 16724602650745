import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthSandbox } from './auth.sandbox';
import { isPlatformBrowser } from '@angular/common';
import { ToastController } from '@ionic/angular';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authSandbox: AuthSandbox,
        private toastController: ToastController,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if(sessionStorage.getItem('accessToken')) {
            const user = JSON.parse(sessionStorage.getItem('accessToken'));
            const permissions = route.data.permissions;
            if(permissions){
                const requiredPermissions: string[] = route.data.permissions;

                if(permissions && requiredPermissions){
                const havePermissions = requiredPermissions.some(permission =>
                    user.permissions.includes(permission)
                  );
                  
                  if (!havePermissions) {
                    this.showToast('No está autorizado a acceder aquí.');
                    this.router.navigate(['/home']);
                    return false;
                  }
                }
            }
            return true;
        }

        if (isPlatformBrowser(this.platformId)) {
            // const token = 'Bearer 385431d8f107a8487022a19949400623a27823c4'; JSON.parse(localStorage.getItem('token'));
            const token = 'Bearer ' + sessionStorage.getItem('accessToken');
            if (token != "Bearer null") {
                console.log('Authorize!');
                return true;
            }else{
                console.log('Unauthorize!');
                this.router.navigate(['/login']);
                return false;
            }
        }          

        console.log('Unauthorize!');
        this.router.navigate(['/login']);

        return false;
    }

    private async showToast(message: string) {
        const toast = await this.toastController.create({
            message,
            duration: 4000,
            position: 'bottom',
            color: 'danger',
        });
    toast.present();
    }

    private handleUnAuthorized() {
        // todo
    }
}
