import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth } from 'src/app/models/auth.models';
import { Api } from '../api';
import { API_ENDPOINTS } from '../api.entities';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends Api {
    doLogin(params: any): Observable<Auth.ResponseModel> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + btoa('sga:none'),
            }),
        };

        return this.http.post<Auth.ResponseModel>(
            this.mgaApiUrl + API_ENDPOINTS.auth.login,
            params,
            httpOptions
        );
    }

    doProfile() {
        return this.http.post(this.mgaApiUrl + API_ENDPOINTS.auth.profile, {});
    }
}
