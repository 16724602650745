import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-marca-select',
    templateUrl: './marca-select.component.html',
    styleUrls: ['./marca-select.component.css'],
})
export class MarcaSelectComponent implements OnInit {
    dataSource = [1, 1, 1, 1, 1, 1, 1, 1];

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
