import { Component, HostListener } from '@angular/core';

@Component({
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent {

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.adjustScrollOnKeyboardClose();
    }

    adjustScrollOnKeyboardClose() {
        // This slides the screen upwards, when the mobile keyboard is closed (login screen)
        const authZoneElement = document.querySelector('.content');
        if (authZoneElement) {
            authZoneElement.scrollTo(0, 0);
        }
    }
}
