import { MenuItemList } from './menu.data';

export const productsMenu: MenuItemList = [
    {
        title: 'Productos',
        open: false,
        type: 'wrapper',
        icon: 'basket-outline',
        permissions: ['core.product.product'],
        isMobile: true,
        isWeb: false,
        children: [
            {
                title: 'Productos',
                id: 'products-al',
                url: '/products',
                icon: 'basket-outline',
                tooltip: 'Listado de productos',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Localizar',
                icon: 'search-outline',
                permissions: ['core.product.product.scan'],
                url: '/locate',
                id: 'locate',
                tooltip: 'Localizar productos en embalajes',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Consulta',
                id: 'products-info',
                url: '/products/info',
                icon: 'information-circle-outline',
                permissions: ['core.product.product.scan'],
                tooltip: 'Consulta Stock de artículos mediante el escáner',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Productos recibidos',
                id: 'print-products-received',
                url: '/print/product/received',
                icon: 'archive-outline',
                permissions: ['core.product.product.received'],
                tooltip: 'Listado de todos los productos recibidos',
                isMobile: true,
            },
            {
                title: 'Productos solicitados',
                id: 'received-products-requested',
                url: '/requested-products',
                icon: 'archive-outline',
                permissions: ['core.product.product.ordered'],
                tooltip:
                    'Listado de todos los productos solicitados que se han recibido',
                isMobile: true,
            },
            {
                title: 'Productos no aptos online',
                id: 'unfit-online-products',
                url: '/unfit-online-products',
                icon: 'archive-outline',
                tooltip: 'Listado de productos no aptos online',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Reetiquetado productos',
                id: 'print-product',
                url: 'print/product/relabel',
                icon: 'barcode-outline',
                permissions: ['core.product.product.relabeled'],
                tooltip: 'Imprimir nuevas etiquetas de productos con cámara',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Reetiquetado productos manual',
                id: 'print-product-manual',
                url: '/print/product/relabel',
                icon: 'barcode-outline',
                permissions: ['core.product.product.relabeled'],
                tooltip:
                    'Imprimir nuevas etiquetas de productos introduciendo el código manualmente',
                isMobile: true,
                isWeb: false,
            },
        ],
    },
];
