import { createAction, props } from '@ngrx/store';

//
export const getMarketplacesAction = createAction(
    'get marketplaces',
    props<any>()
);
export const getMarketplacesSuccessAction = createAction(
    'get marketplaces success',
    props<{ payload: any }>()
);
export const getMarketplacesFailAction = createAction(
    'get marketplaces fail',
    props<any>()
);
//
export const searchProductCatalogAction = createAction(
    'searchProductCatalog',
    props<any>()
);
export const searchProductCatalogSuccessAction = createAction(
    'searchProductCatalog success',
    props<any>()
);
export const searchProductCatalogFailAction = createAction(
    'searchProductCatalog fail',
    props<any>()
);
//
export const searchProductWithVariationsCatalogAction = createAction(
    'searchProductWithVariationsCatalog',
    props<any>()
);
export const searchProductWithVariationsCatalogSuccessAction = createAction(
    'searchProductWithVariationsCatalog success',
    props<any>()
);
export const searchProductWithVariationsCatalogFailAction = createAction(
    'searchProductWithVariationsCatalog fail',
    props<any>()
);
//
export const getBrandsAction = createAction('getBrands', props<any>());
export const getBrandsSuccessAction = createAction(
    'getBrands success',
    props<any>()
);
export const getBrandsFailAction = createAction('getBrands fail', props<any>());
//
export const getCachedMarketByIdAction = createAction(
    'getCachedMarketById',
    props<any>()
);
//
export const getSizesAction = createAction('getSizes', props<any>());
export const getSizesSuccessAction = createAction(
    'getSizes success',
    props<any>()
);
export const getSizesFailAction = createAction('getSizes fail', props<any>());
//
export const getColorsAction = createAction('getColors', props<any>());
export const getColorsSuccessAction = createAction(
    'getColors success',
    props<any>()
);
export const getColorsFailAction = createAction('getColors fail', props<any>());
//
export const getFeaturesAction = createAction('getFeatures', props<any>());
export const getFeaturesSuccessAction = createAction(
    'getFeatures success',
    props<any>()
);
export const getFeaturesFailAction = createAction(
    'getFeatures fail',
    props<any>()
);
//
export const getSeasonsAction = createAction('getSeasons', props<any>());
export const getSeasonsSuccessAction = createAction(
    'getSeasons success',
    props<any>()
);
export const getSeasonsFailAction = createAction(
    'getSeasons fail',
    props<any>()
);
//
export const getMarketBrandsAction = createAction(
    'getMarketBrands',
    props<any>()
);
export const getMarketBrandsSuccessAction = createAction(
    'getMarketBrands success',
    props<any>()
);
export const getMarketBrandsFailAction = createAction(
    'getMarketBrands fail',
    props<any>()
);
//
export const getMarketSizesAction = createAction(
    'getMarketSizes',
    props<any>()
);
export const getMarketSizesSuccessAction = createAction(
    'getMarketSizes success',
    props<any>()
);
export const getMarketSizesFailAction = createAction(
    'getMarketSizes fail',
    props<any>()
);
//
export const getMarketColorsAction = createAction(
    'getMarketColors',
    props<any>()
);
export const getMarketColorsSuccessAction = createAction(
    'getMarketColors success',
    props<any>()
);
export const getMarketColorsFailAction = createAction(
    'getMarketColors fail',
    props<any>()
);
//
export const getMarketFeaturesAction = createAction(
    'getMarketFeatures',
    props<any>()
);
export const getMarketFeaturesSuccessAction = createAction(
    'getMarketFeatures success',
    props<any>()
);
export const getMarketFeaturesFailAction = createAction(
    'getMarketFeatures fail',
    props<any>()
);
//
export const getMarketSeasonsAction = createAction(
    'getMarketSeasons',
    props<any>()
);
export const getMarketSeasonsSuccessAction = createAction(
    'getMarketSeasons success',
    props<any>()
);
export const getMarketSeasonsFailAction = createAction(
    'getMarketSeasons fail',
    props<any>()
);
//
export const getRulesConfigurationsActions = createAction(
    'getRulesConfigurations',
    props<any>()
);
export const getRulesConfigurationsSuccessAction = createAction(
    'getRulesConfigurations success',
    props<any>()
);
export const getRulesConfigurationsFailAction = createAction(
    'getRulesConfigurations fail',
    props<any>()
);
//
export const postUpdateZalandoSizeMapWithApiAction = createAction(
    'postUpdateZalandoSizeMapWithApi',
    props<any>()
);
export const postUpdateZalandoSizeMapWithApiSuccessAction = createAction(
    'postUpdateZalandoSizeMapWithApi success',
    props<any>()
);
export const postUpdateZalandoSizeMapWithApiFailAction = createAction(
    'postUpdateZalandoSizeMapWithApi fail',
    props<any>()
);
//
export const updateRulesConfigurationsAction = createAction(
    'updateRulesConfigurations',
    props<any>()
);
export const updateRulesConfigurationsSuccessAction = createAction(
    'updateRulesConfigurations success',
    props<any>()
);
export const updateRulesConfigurationsFailAction = createAction(
    'updateRulesConfigurations fail',
    props<any>()
);
//
export const updateCategoriesMinPricesAction = createAction(
    'updateCategoriesMinPrices',
    props<any>()
);
export const updateCategoriesMinPricesSuccessAction = createAction(
    'updateCategoriesMinPrices success',
    props<any>()
);
export const updateCategoriesMinPricesFailAction = createAction(
    'updateCategoriesMinPricesAction fail action',
    props<any>()
);
