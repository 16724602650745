import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Marketplaces } from 'src/app/models/marketplaces.models';
import { ResponseModel } from 'src/app/models/response.model';
import { Api } from '../../api';
import { API_RULES_ENDPOINTS } from '../../api-rule/apiRule.urls';
import { API_MIDDLEWARE_ENDPOINTS } from '../middleware.urls';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MarketplacesService extends Api {
    private getPlatformOrdersUrl = this.apiCrm + '/orders/platform/';
    private postUpdateTrackingOrderUrl =
        this.apiCrm + '/orders/{{id}}/expedition-tracking-exchange';
    private patchOrderLineUrl = this.apiCrm + '/orders/lines/{{id}}';

    getMarketplaces(): Observable<Marketplaces.MarketItem[]> {
        return this.http.get<Marketplaces.MarketItem[]>(
            this.apiMiddleware + API_MIDDLEWARE_ENDPOINTS.marketplacesUrl,
            {}
        );
    }

    searchProductCatalog(
        params: any
    ): Observable<ResponseModel<Marketplaces.ProductCatalog[]>> {
        return this.http.post<ResponseModel<Marketplaces.ProductCatalog[]>>(
            this.apiMiddleware + API_MIDDLEWARE_ENDPOINTS.searchCatalogUrl,
            params
        );
    }

    searchProductWithVariationsCatalog(params: any): Observable<any> {
        return this.http.post(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.searchCatalogVariationsUrl,
            params
        );
    }

    getBrands(): Observable<any> {
        return this.http.get(
            this.apiRule + API_RULES_ENDPOINTS.getBrandsRuleFilters,
            {}
        );
    }

    getSizes(): Observable<any> {
        return this.http.get(
            this.apiRule + API_RULES_ENDPOINTS.getSizesRuleFilters
        );
    }

    getColors(): Observable<any> {
        return this.http.get(
            this.apiRule + API_RULES_ENDPOINTS.getColorsRuleFilters
        );
    }

    getFeatures(): Observable<any> {
        return this.http.get(
            this.apiRule + API_RULES_ENDPOINTS.getFeaturesRuleFilters
        );
    }

    getSeasons(): Observable<any> {
        return this.http.get(
            this.apiRule + API_RULES_ENDPOINTS.getSeasonsRuleFilters
        );
    }

    getMarketBrands(market: string): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketBrandsUrl +
                market
        );
    }

    getMarketSizes(market: string): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketSizesUrl +
                market
        );
    }

    getMarketColors(market: string): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketColorsUrl +
                market
        );
    }

    getMarketFeatures(market: string): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketFeaturesUrl +
                market
        );
    }

    getMarketSeasons(market: string): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketSeasonsUrl +
                market
        );
    }

    getRulesConfigurations(market: string): Observable<any> {
        return this.http.get(
            this.apiRule +
                API_RULES_ENDPOINTS.getRulesConfigurationsUrl +
                market
        );
    }

    postUpdateZalandoSizeMapWithApi(): Observable<any> {
        return this.http.post(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.postUpdateZalandoSizeMapWithApi,
            {}
        );
    }

    updateRulesConfigurations(id, data): Observable<any> {
        return this.http.put(
            this.apiRule +
                API_RULES_ENDPOINTS.updateRulesConfigurationsUrl +
                id,
            data
        );
    }

    updateCategoriesMinPrices(params): Observable<any> {
        return this.http.post(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.updateCategoriesMinPricesUrl,
            params
        );
    }

    getMarketCategories(): Observable<any> {
        return this.http.get<any>(
            this.apiRule + API_RULES_ENDPOINTS.getCategories,
            {}
        );
    }

    postRulesConfigurations(data): Observable<any> {
        return this.http.post<any>(
            this.apiRule + API_RULES_ENDPOINTS.postRulesConfigurationsUrl,
            data,
            {}
        );
    }

    deleteRule(ruleId: string): Observable<any> {
        return this.http.delete<any>(
            this.apiRule + API_RULES_ENDPOINTS.deleteRuleUrl + ruleId,
            {}
        );
    }

    getAllProductCatalog(filters): Observable<any> {
        return this.http.post<any>(
            this.apiMiddleware + API_MIDDLEWARE_ENDPOINTS.searchCatalogUrl,
            filters
        );
    }

    getMapDataRules(): Observable<any> {
        return this.http.get<any>(
            this.apiRule + API_RULES_ENDPOINTS.getMapDataRulesUrl,
            {}
        );
    }

    deleteMapDataRules(mapDataId: number): Observable<any> {
        return this.http.delete<any>(
            this.apiRule +
                API_RULES_ENDPOINTS.deleteMapDataRulesUrl +
                mapDataId.toString(),
            {}
        );
    }

    update_data(dataToUpdate): Observable<any> {
        return this.http.post<any>(
            this.apiMiddleware + API_MIDDLEWARE_ENDPOINTS.updateDataUrl,
            dataToUpdate
        );
    }

    updateMapDataRules(mapDataId: number, data): Observable<any> {
        return this.http.put<any>(
            this.apiRule +
                API_RULES_ENDPOINTS.updateMapDataRulesUrl +
                mapDataId.toString(),
            data,
            {}
        );
    }

    postMapDataRules(data): Observable<any> {
        return this.http.post<any>(
            this.apiRule + API_RULES_ENDPOINTS.postMapDataRulesUrl,
            data,
            {}
        );
    }

    getMarketProductProperty(query = ''): Observable<any> {
        return this.http.get<any>(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getMarketProductPropertyUrl +
                query,
            {}
        );
    }

    getZalandoSizeMap(query = ''): Observable<any> {
        return this.http.get<any>(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.getZalandoSizeMapUrl +
                query,
            {}
        );
    }

    postUpdateZalandoSizeMap(sizeDomainId, body): Observable<any> {
        return this.http.post<any>(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.postZalandoSizeMapUpdateUrl +
                sizeDomainId,
            body
        );
    }

    getAllProductCatalogMarketplaces(filters): Observable<any> {
        return this.http.post<any>(
            this.apiMiddleware + API_MIDDLEWARE_ENDPOINTS.searchCatalogUrl,
            filters
        );
    }

    getInformes(params): Observable<any> {
        return this.http.get(
            this.apiMiddleware +
                API_MIDDLEWARE_ENDPOINTS.info +
                '?sort=updatedAt,DESC&limit=15'
        );
    }

    postSearchPlatformOrders(body) {
        return this.http
            .post<any>(this.getPlatformOrdersUrl, body)
            .pipe(map(response => response.data));
    }

    postUpdateTrackingOrder(orderId, body) {
        return this.http
            .post<any>(
                this.postUpdateTrackingOrderUrl.replace(
                    '{{id}}',
                    orderId.toString()
                ),
                body
            )
            .pipe(map(response => response.data));
    }

    patchOrderLine(orderLineId, body) {
        return this.http
            .patch<any>(
                this.patchOrderLineUrl.replace(
                    '{{id}}',
                    orderLineId.toString()
                ),
                body
            )
            .pipe(map(response => response.data));
    }
}
