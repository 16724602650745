export namespace UserModel {

    export interface User {
        id: number;
        createdAt: string;
        updatedAt: string;
        email: string;
        name: string;
        address: string;
        phone: string;
        password: string;
        salt: string;
        employee: any;
        employeeId: number;
        resetPasswordToken: string;
        tokens: any[];
        membership: any[];
        flatPermissions: any[];
        enabled: boolean;
    }

}
