import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export const initializeAppFactory = (
    httpClient: HttpClient
): (() => Observable<any>) => {
    console.log('Init app');

    return () => of(true);

    // todo init
    // return () => httpClient.get("https://someUrl.com/api/user")
    //     .pipe(
    //         tap(user => { ... })
    // );
};
