import { Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController, AlertController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { menuSGA } from '../../../conf/menu.data';
import { ZALANDO_ID } from '../../../environments/environment';

import { MarketplacesSandbox } from '../../core/api-middleware/marketplaces/sandbox';
import { CommonSandbox } from '../../core/common/common.sandbox';

import { AuthenticationService, MgaService, MobileCheckService, ProductInfoMlkitService, ReceptionMlkitService } from '../../core';

import { PositioningManualProvider, ToolbarProvider } from '../../../providers';

import { getToken } from '../helper-functions/helper-functions';
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'visible'
            })),
            state('closed', style({
                height: '0',
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.3s ease')
            ])
        ])
    ]
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
    private subs: Subscription[] = [];

    menuPagesFiltered;
    loadAppPage;
    selectedItem;
    small = false;

    activeMarketplaces = [];
    isMobile: boolean;
    currentRoute: string = '';

    isActive: boolean;

    @Output() menuTitle = new EventEmitter();

    constructor(
        private router: Router,
        private platform: Platform,
        private cd: ChangeDetectorRef,

        private alertController: AlertController,
        private menuController: MenuController,

        private commonSandbox: CommonSandbox,
        private marketplacesSandbox: MarketplacesSandbox,

        private positioningManualProvider: PositioningManualProvider,
        private toolbarProvider: ToolbarProvider,

        private authenticationService: AuthenticationService,
        private mgaService: MgaService,
        private mobileCheckService: MobileCheckService,
        private productInfoMlkitService: ProductInfoMlkitService,
        private receptionMlkitService: ReceptionMlkitService,
    ) {}

    async ngOnInit() {
        await this.menu();
        this.loadAppPage = this.router.url;
        this.menuPagesFiltered.map(el => {
            if ('type' in el && el.type === 'wrapper') {
                if (!!el && !!el.children && el.children.length > 0) {
                    const child = el.children.find(
                        item => 'url' in item && item.url === this.loadAppPage
                    );
                    if (child) {
                        el.open = true;
                        this.selectedItem = child;
                    }
                }
            } else {
                if ('url' in el && el.url) {
                    if (el.url === this.loadAppPage) {
                        this.selectedItem = el;
                    }
                }
            }
        });

        this.subs.push(
            this.commonSandbox.sidebarToggle$.subscribe(() => {
                this.small = !this.small;
            })
        );
        this.subs.push(
            this.marketplacesSandbox.getMarketplacesData$.subscribe(res => {
                if (res) {
                    this.activeMarketplaces = res.filter(
                        el => el.status === 'active' || el.status === 'enabled'
                    );
                    this.fillMarketplaces();
                }
            })
        );
    }

    private async menu() {
        this.isMobile = this.mobileCheckService.checkIsMobile();
        this.menuPagesFiltered = menuSGA;
        if (this.isMobile) {
            this.menuPagesFiltered = this.menuPagesFiltered.filter(
                el => el.isMobile === true && el.isWeb != true
            );
            this.menuPagesFiltered.forEach(el => {
                if (el.children) {
                    el.children.forEach(() => {
                        el.children = el.children.filter(
                            child =>
                                child.isMobile === true || child.isWeb === false
                        );
                    });
                }
            });
        } else {
            this.menuPagesFiltered = this.menuPagesFiltered.filter(
                el => el.isWeb !== false
            );
            this.menuPagesFiltered.forEach(el => {
                if (el.children) {
                    el.children.forEach(() => {
                        el.children = el.children.filter(
                            child =>
                                child.isMobile !== true || child.isWeb !== false
                        );
                    });
                }
            });
        }

        this.menuPagesFiltered.forEach(el => {
            const user = JSON.parse(sessionStorage.getItem('accessToken'));
            const permissions = el.permissions;

            if (!permissions) {
                return;
            }

            const havePermissions = permissions.some(permission =>
                user.permissions.includes(permission)
            );

            if (!havePermissions) {
                this.menuPagesFiltered = this.menuPagesFiltered.filter(
                    item => item.permissions !== permissions
                );
            }
        });

        this.menuPagesFiltered.forEach(el => {
            if (el.children) {
                el.children.forEach(item => {
                    const user = JSON.parse(
                        sessionStorage.getItem('accessToken')
                    );
                    const permissions = item.permissions;

                    if (!permissions) {
                        return;
                    }

                    const havePermissions = permissions.some(permission =>
                        user.permissions.includes(permission)
                    );

                    if (!havePermissions) {
                        el.children = el.children.filter(
                            child => child.permissions !== permissions
                        );
                    }
                });
            }
        });
    }

    private fillMarketplaces() {
        const menuItem = this.menuPagesFiltered.find(
            el => el.title === 'Marketplaces'
        );
        if (menuItem && menuItem.children) {
            if (!!this.activeMarketplaces.length) {
                this.activeMarketplaces.map(market => {
                    const isAvailable = menuItem.children.find(
                        el => el.title === market.name
                    );
                    if (!isAvailable) {
                        const item = {
                            title: market.name,
                            id: market.id,
                            url: 'marketplaces/info/' + market.id,
                            icon: 'add-circle-outline',
                            open: false,
                            children: [
                                {
                                    title: 'Mapeos',
                                    url: 'marketplaces/mapping/' + market.id,
                                    icon: 'code-outline',
                                    id: market.id + 1,
                                    additional: true,
                                },
                                {
                                    title: 'Reglas',
                                    url: 'marketplaces/rules/' + market.id,
                                    icon: 'code-working-outline',
                                    id: market.id + 2,
                                },
                                {
                                    title: 'Catálogo',
                                    url: 'marketplaces/catalog/' + market.id,
                                    icon: 'apps-outline',
                                    id: market.id + 3,
                                },
                            ],
                        };
                        if (market.reId === ZALANDO_ID) {
                            item.children.push({
                                title: 'Reembolsos',
                                url: 'marketplaces/refunds/' + market.id,
                                icon: 'checkmark-circle-outline',
                                id: market.id + 4,
                            });
                        }
                        menuItem.children.push(item);
                    }
                });
            }
            // TODO if needed
            // if (!menuItem.children.find(el => el.id === 'store-priority')) {
            //     menuItem.children.push({
            //         title: 'Prioridad de Tienda',
            //         id: 'store-priority',
            //         url: '/marketplaces/store-priority',
            //         icon: 'ios-albums',
            //     });
            // }
            if (
                !menuItem.children.find(el => el.id === 'logistics-operators')
            ) {
                menuItem.children.push({
                    title: 'Parametrización Logística',
                    id: 'logistics-operators',
                    url: '/marketplaces/logistics-operators',
                    icon: 'cube-outline',
                });
            }
            if (
                !menuItem.children.find(el => el.id === 'parameterization-iva')
            ) {
                menuItem.children.push({
                    title: 'Destinos e impuestos',
                    id: 'parameterization-iva',
                    url: '/marketplaces/parameterization-iva',
                    icon: 'information-circle-outline',
                });
            }
            if (!menuItem.children.find(el => el.id === 'informes')) {
                menuItem.children.push({
                    title: 'Informes',
                    id: 'informes',
                    url: '/marketplaces/reports',
                    icon: 'document-outline',
                });
            }
            this.cd.markForCheck();
        }
    }

    ngAfterViewInit(): void {}

    tapOption(a) {}

    openSubMenuItem(menuItem): void {
        menuItem.open = !menuItem.open;
    }

    checkIfChildrenNotification(a) {}

    async tapOptionSubitem(p) {
        if (sessionStorage.getItem('pageHome') === 'true') {
            sessionStorage.setItem('iconMenu', 'assets/icon/menu-right.svg');
        }
        sessionStorage.setItem('pageHome', 'false');

        this.positioningManualProvider.isActive.subscribe(active => {
            this.isActive = active;
        });
        if (this.isActive == true) {
            this.currentRoute = 'Ubicar/escanear con láser';
            await this.router.navigate(['/positioning/manual']);
            this.toolbarProvider.currentPage.next('Ubicar/escanear con láser');
            this.menuTitle.emit('Ubicar/escanear con láser');
        } else {
            await this.menuController.close();
            if (p.url === 'print/tag/ref') {
            } else if (p.url == '/products/info') {
                await this.productInfoMlkitService.init();
            } else if (p.url == 'reception/pair-by-pair') {
                await this.checkAlertsAndRedirect();
            } else {
                this.returnTitle(p);
            }
        }
    }

    async checkAlertsAndRedirect() {
        const token = await getToken();
        const userWarehouseId = this.authenticationService.getStoreCurrentUser();
        if (userWarehouseId) {
            this.mgaService.api.catalog.warehouse
                .byId(userWarehouseId, token)
                .subscribe(async warehouse => {
                    if (!warehouse.isMain) {
                        if (warehouse.isReceptionAlertAllowed) {
                            await sessionStorage.setItem('hideAlerts', 'true');

                            const alert = await this.alertController.create({
                                header: '¡Atención!',
                                message: '¿Desea ocultar las alertas de nuevos pares y redirigir a la sección de nuevos al finalizar el proceso?',
                                buttons: [
                                    {
                                        text: 'Sí',
                                        handler: () => {
                                            alert.dismiss(true);
                                        },
                                    },
                                    {
                                        text: 'No',
                                        handler: () => {
                                            alert.dismiss(false);
                                        },
                                    },
                                ],
                            });

                            alert.onDidDismiss().then(async response => {
                                await sessionStorage.setItem('hideAlerts', response.toString());

                                await this.receptionMlkitService.reception(2);
                            });

                            await alert.present();
                        } else {
                            await sessionStorage.setItem('hideAlerts', 'false');

                            await this.receptionMlkitService.reception(2);
                        }
                    } else {
                        const alert = await this.alertController.create({
                            header: '¡Atención!',
                            message: 'Debe iniciar sesión en una tienda para usar esta función.',
                            buttons: [
                                {
                                    text: 'Cerrar',
                                },
                            ],
                        });
                        await alert.present();
                    }
                }, error => {
                    console.error(error);
                });
        } else {
            const alert = await this.alertController.create({
                header: '¡Atención!',
                message: 'Debe iniciar sesión en una tienda para usar esta función.',
                buttons: [
                    {
                        text: 'Cerrar',
                    },
                ],
            });
            await alert.present();
        }
    }

    checkIfChildrenHasNewTariffs(a) {}

    tapOptionSubSubitem(a) {}

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    returnTitle(item) {
        this.currentRoute = item.title;
        this.toolbarProvider.currentPage.next(item.title);
        this.toolbarProvider.optionsActions.next([]);
        this.menuTitle.emit(item.title);
    }

    isTextTooLong(text: string): boolean {
        const maxLength = 25;
        return text && text.length > maxLength;
    }
}
