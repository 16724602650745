import { InventoryModel, OrderModel } from './';

export namespace OrderPackageModel {

    export interface OrderPackage {
        tracking: string;
        uniqueCode: string;
        successful: boolean;
        order: OrderModel.Order;
        orderId: number;
        status: OrderPackageStatus;
        inventories: InventoryModel.Inventory[];
    }

}

export enum OrderPackageStatus {
    pendingCollected = 'pendingCollected',
    collected = 'collected',
    sorterIn = 'sorterIn',
    sorterOut = 'sorterOut',
    jailIn = 'jailIn',
    jailOut = 'jailOut',
    sorterRackIn = 'sorterRackIn',
    received = 'received',
    warehouseOutput = 'warehouseOutput',
}
