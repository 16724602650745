import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { environment } from '../../environments/environment';

import { ItemReferencesProvider, MlkitProvider, ReceptionProvider } from '../../providers';

import { AuthenticationService, PrinterService } from '../core';

import { ReceptionModel } from '../models';

import { getToken } from '../shared';

import { AfterPackingReceptionComponent } from '../modules/after-packing-reception/after-packing-reception.component';

declare let mlkitMatrixSimple;

@Injectable({
    providedIn: 'root',
})
export class ReceptionMlkitService {
    private timeoutHideText;
    private scannerPaused: boolean = false;
    private lastCodeScanned: string;
    private typeReception: number = 1;

    private isStoreUser: boolean = false;

    private lastResData;

    private readonly timeMillisToResetScannedCode: number = 2000;

    private referenceProductToPrint: string = null;
    private requestedProductId: number = null;
    private lastPackingReference: string = null;
    private storeUserObj: any;
    private token;
    public counter = 0;

    constructor(
        private router: Router,
        private modalController: ModalController,

        private itemReferencesProvider: ItemReferencesProvider,
        private mlkitProvider: MlkitProvider,
        private receptionProvider: ReceptionProvider,

        private authenticationService: AuthenticationService,
        private printerService: PrinterService,
    ) {
        this.timeMillisToResetScannedCode =
            environment.time_millis_reset_scanned_code;
    }

    async reception(typeReception: number) {
        this.token = await getToken();
        this.isStoreUser = await this.authenticationService.isStoreUser();
        if (this.isStoreUser) {
            this.storeUserObj = this.authenticationService.getStoreCurrentUser();
        }

        this.lastCodeScanned = 'start';
        this.receptionProvider.resumeProcessStarted = false;
        this.typeReception = typeReception;

        mlkitMatrixSimple.init(
            async response => {
                let code = '';
                if (response) {
                    if (
                        response.actionIonic &&
                        response.actionIonic == 'lastCodeScannedStart'
                    ) {
                        this.lastCodeScanned = 'start';
                    }

                    if (response.barcode && response.barcode.data) {
                        code = response.barcode.data;
                        if (
                            !this.scannerPaused &&
                            code != this.lastCodeScanned
                        ) {
                            this.lastCodeScanned = code;

                            mlkitMatrixSimple.setTimeout(
                                'lastCodeScannedStart',
                                this.timeMillisToResetScannedCode,
                                ''
                            );

                            this.scannerPaused = true;
                            if (
                                !this.itemReferencesProvider.checkSpecificCodeValue(
                                    code,
                                    this.itemReferencesProvider.codeValue
                                        .PACKAGE
                                )
                            ) {
                                switch (
                                    this.itemReferencesProvider.checkCodeValue(
                                        code
                                    )
                                ) {
                                    case this.itemReferencesProvider.codeValue
                                        .PACKING:
                                        this.processPackingScanned(code);
                                        break;
                                    case this.itemReferencesProvider.codeValue
                                        .PRODUCT:
                                        mlkitMatrixSimple.showFixedTextBottom(
                                            false,
                                            ''
                                        );
                                        this.processProductScanned(code);
                                        break;
                                    default:
                                        mlkitMatrixSimple.setText(
                                            'Necesita escanear un código de Jaula o Pallet para hacer una recepción.',
                                            this.mlkitProvider.colorsMessage
                                                .error.color,
                                            this.mlkitProvider.colorText.color,
                                            18
                                        );
                                        this.hideTextMessage(1500);
                                        setTimeout(
                                            () => (this.scannerPaused = false),
                                            1.5 * 1000
                                        );
                                }
                            } else {
                                mlkitMatrixSimple.showFixedTextBottom(
                                    false,
                                    ''
                                );
                                this.processProductScanned(code);
                            }
                        }
                    } else if (response.action) {
                        switch (response.action) {
                            case 'matrix_simple':
                                if (this.typeReception == 2) {
                                    mlkitMatrixSimple.showButtonFinishReception(
                                        true
                                    );
                                } else {
                                    mlkitMatrixSimple.showButtonFinishReception(
                                        false
                                    );
                                }
                                break;
                            case 'finish_reception':
                                // Request user scan packing to set as empty
                                mlkitMatrixSimple.showFixedTextBottom(
                                    true,
                                    'Escanea la jaula que desea marcar como vacía.'
                                );
                                break;
                            case 'force_scanning':
                                if (response.force) {
                                    // Force product scanning
                                    this.setProductAsReceived(
                                        response.barcode,
                                        true
                                    );
                                } else if (response.avoid) {
                                    this.scannerPaused = false;
                                    // Avoid product and don't make anything
                                }
                                break;
                            case 'reception_incomplete':
                                if (response.response) {
                                    // Continue scanning products and don't make anything
                                    this.scannerPaused = false;
                                } else {
                                    // No more products in packing, create incidence in backend
                                    this.incidenceNotReceived(
                                        this.receptionProvider.referencePacking
                                    );
                                }
                                break;
                            case 'order_codes':
                                if (response.response) {
                                    mlkitMatrixSimple.finish();
                                    this.router.navigate(['/package-received']);
                                }
                                break;
                            case 'wrong_code_msg':
                                this.scannerPaused = false;
                                break;
                            case 'new_product_expo':
                                this.scannerPaused = false;
                                if (response.response) {
                                    this.printNewProductPriceTag();
                                }
                                break;
                            case 'requested_attended':
                                this.scannerPaused = false;
                                if (response.response) {
                                    this.attendProductReceived(
                                        this.requestedProductId
                                    );
                                } else {
                                    this.requestedProductId = null;
                                }
                                break;
                            case 'action_select':
                                if (response.response == 'products') {
                                    mlkitMatrixSimple.finish();
                                    const routeFragments = [
                                        'print',
                                        'product',
                                        'received',
                                        'scandit',
                                        this.lastResData.hasNewProducts,
                                    ];
                                    if (this.lastResData.hasRequestedProducts) {
                                        routeFragments.push(true);
                                    }
                                    this.router.navigate(routeFragments);
                                } else {
                                    if (response.response == 'packages') {
                                        mlkitMatrixSimple.finish();
                                        this.router.navigate([
                                            '/package-received',
                                        ]);
                                    }
                                }
                                break;
                            case 'request':
                                let responseData = null;
                                switch (response.requestType) {
                                    case 'postReceiveProduct':
                                        mlkitMatrixSimple.hideLoadingDialog();
                                        if (response.code === 201) {
                                            responseData = JSON.parse(
                                                response.data
                                            );
                                            mlkitMatrixSimple.setText(
                                                `Producto recepcionado.`,
                                                this.mlkitProvider.colorsMessage
                                                    .success.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                18
                                            );
                                            this.hideTextMessage(1500);

                                            Promise.resolve(
                                                sessionStorage.getItem(
                                                    'hideAlerts'
                                                )
                                            ).then(hide => {
                                                let hideAlerts = JSON.parse(
                                                    String(hide)
                                                );

                                                if (responseData.orderCode) {
                                                    mlkitMatrixSimple.showWarning(
                                                        true,
                                                        'Recibido pedido ' +
                                                            responseData.orderCode +
                                                            '. ¿Quiere ir a la sección de pedidos recibidos para procesarlos?',
                                                        'order_codes',
                                                        'Sí',
                                                        'No'
                                                    );
                                                } else if (
                                                    responseData.hasRequestedProducts
                                                ) {
                                                    this.requestedProductId =
                                                        responseData.productRequestedId;
                                                    mlkitMatrixSimple.showWarning(
                                                        true,
                                                        'El producto escaneado corresponde a un producto solicitado a otra tienda.',
                                                        'requested_attended',
                                                        'Atender',
                                                        'No atender'
                                                    );
                                                } else if (
                                                    responseData.hasNewProducts &&
                                                    !hideAlerts
                                                ) {
                                                    mlkitMatrixSimple.showWarning(
                                                        true,
                                                        `El producto escaneado es nuevo en la tienda. ¿Quiere imprimir su código de exposición ahora?`,
                                                        'new_product_expo',
                                                        'Sí',
                                                        'No'
                                                    );
                                                } else if (
                                                    responseData.isNoOnline
                                                ) {
                                                    mlkitMatrixSimple.showWarning(
                                                        true,
                                                        'El producto escaneado [' +
                                                            this
                                                                .referenceProductToPrint +
                                                            '] no es apto para la venta online.',
                                                        'no_online',
                                                        'Aceptar',
                                                        ''
                                                    );
                                                }
                                            });
                                            this.scannerPaused = false;
                                        } else if (
                                            response.code >= 400 &&
                                            response.code < 500
                                        ) {
                                            this.scannerPaused = true;
                                            mlkitMatrixSimple.showWarningToForce(
                                                true,
                                                this.referenceProductToPrint
                                            );
                                        } else {
                                            responseData = JSON.parse(
                                                response.data
                                            );
                                            mlkitMatrixSimple.setText(
                                                responseData.message,
                                                this.mlkitProvider.colorsMessage
                                                    .error.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(1500);
                                            this.scannerPaused = false;
                                        }
                                        break;

                                    case 'postUpdateStock':
                                        break;

                                    case 'postReceive':
                                        if (
                                            response.code === 200 ||
                                            response.code === 201
                                        ) {
                                            responseData = JSON.parse(
                                                response.data
                                            );

                                            // Update the stock of all the products form this packing in this warehouse.
                                            mlkitMatrixSimple.request(
                                                'GET',
                                                environment.mgaApiUrl +
                                                    '/catalog/packing/with-inventories/' +
                                                    this.lastPackingReference,
                                                {},
                                                this.token,
                                                'getCheckProductsPacking'
                                            );
                                            if (this.typeReception === 1) {
                                                mlkitMatrixSimple.showFixedTextBottom(
                                                    false,
                                                    ''
                                                );
                                                this.receptionProvider.referencePacking = null;
                                                this.receptionProvider.typePacking = 0;
                                                this.receptionProvider.processStarted = false;

                                                if (responseData.quantity > 0) {
                                                    const count = {
                                                        packingReference: this
                                                            .lastPackingReference,
                                                        total:
                                                            responseData.quantity,
                                                        packages: 0,
                                                        receivedProducts: 0,
                                                        newProducts: 0,
                                                        requestedProducts: 0,
                                                        noOnlineProducts: 0,
                                                    };

                                                    count.packages =
                                                        responseData.orderCodes &&
                                                        responseData.orderCodes
                                                            .length > 0
                                                            ? responseData
                                                                  .orderCodes
                                                                  .length
                                                            : 0;
                                                    count.newProducts =
                                                        responseData.newQuantity;
                                                    count.requestedProducts =
                                                        responseData.requestedQuantity;
                                                    count.noOnlineProducts =
                                                        responseData.noOnlineProducts;
                                                    count.receivedProducts =
                                                        responseData.quantity -
                                                        count.packages -
                                                        count.newProducts -
                                                        count.requestedProducts;
                                                    count.total =
                                                        count.packages +
                                                        count.newProducts +
                                                        count.requestedProducts +
                                                        count.receivedProducts;

                                                    mlkitMatrixSimple.hideLoadingDialog();
                                                    setTimeout(
                                                        () =>
                                                            (this.scannerPaused = false),
                                                        1.5 * 1000
                                                    );
                                                    mlkitMatrixSimple.finish();

                                                    this.modalController
                                                        .create({
                                                            component: AfterPackingReceptionComponent,
                                                            componentProps: {
                                                                count: count,
                                                                receptionScanditService: this,
                                                            },
                                                        })
                                                        .then(async modal => {
                                                            await modal.present();
                                                        });
                                                } else {
                                                    mlkitMatrixSimple.hideLoadingDialog();
                                                    setTimeout(
                                                        () =>
                                                            (this.scannerPaused = false),
                                                        1.5 * 1000
                                                    );
                                                }
                                            } else {
                                                mlkitMatrixSimple.hideLoadingDialog();
                                                setTimeout(
                                                    () =>
                                                        (this.scannerPaused = false),
                                                    1.5 * 1000
                                                );
                                            }
                                        } else {
                                            responseData = JSON.parse(
                                                response.data
                                            );
                                            mlkitMatrixSimple.hideLoadingDialog();
                                            mlkitMatrixSimple.setText(
                                                responseData.errors,
                                                this.mlkitProvider.colorsMessage
                                                    .error.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(2000);
                                            setTimeout(
                                                () =>
                                                    (this.scannerPaused = false),
                                                1.5 * 1000
                                            );
                                        }

                                        break;

                                    case 'getCheckProductsPacking':
                                        break;

                                    case 'getCheckPacking':
                                        mlkitMatrixSimple.hideLoadingDialog();
                                        if (
                                            response.code === 200 ||
                                            response.code === 201
                                        ) {
                                            mlkitMatrixSimple.setText(
                                                'Embalaje ' +
                                                    this.receptionProvider
                                                        .referencePacking +
                                                    ' vaciado',
                                                this.mlkitProvider.colorsMessage
                                                    .success.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(1500);
                                            setTimeout(
                                                () =>
                                                    (this.scannerPaused = false),
                                                1.5 * 1000
                                            );
                                            mlkitMatrixSimple.showFixedTextBottom(
                                                false,
                                                ''
                                            );
                                            Promise.resolve(
                                                sessionStorage.getItem(
                                                    'hideAlerts'
                                                )
                                            ).then(hide => {
                                                let hideAlerts = JSON.parse(
                                                    String(hide)
                                                );

                                                if (hideAlerts) {
                                                    mlkitMatrixSimple.finish();
                                                    this.router.navigate([
                                                        '/new-products',
                                                    ]);
                                                }
                                            });
                                        } else if (
                                            response.code >= 400 &&
                                            response.code < 500
                                        ) {
                                            // Process custom status-code response to check packing
                                            mlkitMatrixSimple.showWarning(
                                                true,
                                                '¿Quedan productos sin recepcionar en ' +
                                                    this.receptionProvider
                                                        .referencePacking +
                                                    '?',
                                                'reception_incomplete',
                                                'Sí, continuar',
                                                'No, embalaje vacío'
                                            );
                                        } else {
                                            this.lastCodeScanned = 'start';
                                            responseData = JSON.parse(
                                                response.data
                                            );
                                            mlkitMatrixSimple.setText(
                                                responseData.errors,
                                                this.mlkitProvider.colorsMessage
                                                    .error.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(2000);
                                            setTimeout(
                                                () =>
                                                    (this.scannerPaused = false),
                                                1.5 * 1000
                                            );
                                        }
                                        break;

                                    case 'getNotReceivedProducts':
                                        mlkitMatrixSimple.hideLoadingDialog();
                                        if (response.length > 0) {
                                            mlkitMatrixSimple.setText(
                                                `${
                                                    this.receptionProvider
                                                        .literalsJailPallet
                                                        .packing_emptied
                                                }`,
                                                this.mlkitProvider.colorsMessage
                                                    .success.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(2000);
                                            setTimeout(
                                                () =>
                                                    (this.scannerPaused = false),
                                                1.5 * 1000
                                            );
                                        } else {
                                            this.scannerPaused = false;
                                            console.error(
                                                'Error::Subscribe::Set products as not received after empty packing::',
                                                JSON.stringify(response)
                                            );
                                        }
                                        break;

                                    case 'promisePutAttendReceivedProductsRequested':
                                        if (response.code == 201) {
                                            mlkitMatrixSimple.setText(
                                                'Producto atendido correctamente',
                                                this.mlkitProvider.colorsMessage
                                                    .success.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(1500);
                                        } else {
                                            mlkitMatrixSimple.setText(
                                                'Ha ocurrido un error al intentar marcar como atendido el producto.',
                                                this.mlkitProvider.colorsMessage
                                                    .error.color,
                                                this.mlkitProvider.colorText
                                                    .color,
                                                16
                                            );
                                            this.hideTextMessage(4000);
                                        }
                                        break;

                                    case 'postPricesByProductsReferences':
                                        if (response.data) {
                                            responseData = JSON.parse(
                                                response.data
                                            );
                                            let dataToPrint = this.printerService.processProductToPrintTagPrice(
                                                responseData
                                            );
                                            console.debug(
                                                'PRINT::printTagPrices 3 [' +
                                                    new Date().toJSON() +
                                                    ']',
                                                responseData
                                            );

                                            let printRes = await this.printerService.toPrintFromString(
                                                dataToPrint.valuePrint
                                            );

                                            let dataToPrintMulti = [];
                                            dataToPrint.options.forEach(
                                                element => {
                                                    dataToPrintMulti.push({
                                                        modelId:
                                                            element.product
                                                                .productShoeUnit
                                                                .model.id,
                                                        numRange:
                                                            element.price
                                                                .numRange,
                                                        hash:
                                                            element.price.hash,
                                                        tariffId:
                                                            element.price
                                                                .tariffId,
                                                    });
                                                }
                                            );

                                            if (printRes) {
                                                mlkitMatrixSimple.request(
                                                    'POST',
                                                    environment.mgaApiUrl +
                                                        '/tariff/price-filter/set-printed-multi',
                                                    dataToPrintMulti,
                                                    this.token,
                                                    'printNotify'
                                                );
                                            }
                                        }
                                        break;

                                    case 'printNotify':
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            },
            'Recepción',
            this.mlkitProvider.colorsHeader.background.color,
            this.mlkitProvider.colorsHeader.color.color
        );
    }

    private processPackingScanned(code: string) {
        if (
            this.itemReferencesProvider.checkSpecificCodeValue(
                code,
                this.itemReferencesProvider.codeValue.JAIL
            )
        ) {
            this.receptionProvider.typePacking = 1;
        } else if (
            this.itemReferencesProvider.checkSpecificCodeValue(
                code,
                this.itemReferencesProvider.codeValue.PALLET
            )
        ) {
            this.receptionProvider.typePacking = 2;
        } else if (
            this.itemReferencesProvider.checkSpecificCodeValue(
                code,
                this.itemReferencesProvider.codeValue.PACKAGE
            )
        ) {
            this.receptionProvider.typePacking = 3;
        }
        if (this.typeReception == 1) {
            mlkitMatrixSimple.showLoadingDialog(
                'Recepcionando embalaje ' + code + ' ...'
            );
            setTimeout(() => {
                this.setPackingAsReceived(code);
            }, 500);
        } else if (this.typeReception == 2) {
            mlkitMatrixSimple.showFixedTextBottom(false, '');
            this.receptionProvider.referencePacking = code;
            mlkitMatrixSimple.showLoadingDialog(
                'Comprobando embalaje a recepcionar...'
            );
            mlkitMatrixSimple.request(
                'POST',
                environment.mgaApiUrl + '/catalog/packing/check/' + code,
                {},
                this.token,
                'getCheckPacking'
            );
        }
    }

    private processProductScanned(code: string) {
        if (this.typeReception == 1) {
            // Warning message notice that this method is only to scan jail or pallet, not products
            mlkitMatrixSimple.showWarning(
                true,
                `Este método es para escanear únicamente jaulas o pallets, no productos.`,
                'wrong_code_msg'
            );
            this.scannerPaused = false;
        } else {
            this.setProductAsReceived(code);
        }
    }

    // Reception of packing in MGA and all products associated
    private setPackingAsReceived(packingReference: string) {
        this.lastPackingReference = packingReference;
        mlkitMatrixSimple.request(
            'POST',
            environment.mgaApiUrl + '/processes/receive-store',
            { packingReference: packingReference },
            this.token,
            'postReceive'
        );
    }

    // Reception of product in MGA
    private setProductAsReceived(
        referenceProduct: string,
        force: boolean = false
    ) {
        let params: ReceptionModel.ReceptionProduct = {
            reference: referenceProduct,
        };

        if (force) {
            params.force = force;
        }

        this.referenceProductToPrint = referenceProduct;
        mlkitMatrixSimple.showLoadingDialog('Recepcionando producto...');
        mlkitMatrixSimple.request(
            'POST',
            environment.mgaApiUrl + '/inventory/receive-products',
            params,
            this.token,
            'postReceiveProduct'
        );
    }

    private incidenceNotReceived(packingReference: string) {
        const token = getToken();
        mlkitMatrixSimple.showLoadingDialog(
            'Notificando productos no recepcionados...'
        );
        mlkitMatrixSimple.request(
            'POST',
            environment.mgaApiUrl +
                '/catalog/packing/' +
                packingReference +
                '/empty-not-received',
            {},
            this.token,
            'getNotReceivedProducts'
        );
    }

    private printNewProductPriceTag() {
        // this.printerService.printTagPrices([this.referenceProductToPrint]);
        console.debug('PRINT::printTagPrices 1 [' + new Date().toJSON() + ']', [
            this.referenceProductToPrint,
        ]);
        console.debug('PRINT::printTagPrices 2 [' + new Date().toJSON() + ']', [
            this.referenceProductToPrint,
        ]);
        mlkitMatrixSimple.request(
            'POST',
            environment.mgaApiUrl + '/tariff/price-filter/get-by-product-refs',
            { references: [this.referenceProductToPrint] },
            this.token,
            'postPricesByProductsReferences'
        );
    }

    private attendProductReceived(requestedProductId: number) {
        if (requestedProductId) {
            mlkitMatrixSimple.request(
                'POST',
                environment.mgaApiUrl +
                    '/reception/store-received-product-requested/set-attended',
                [requestedProductId],
                this.token,
                'promisePutAttendReceivedProductsRequested'
            );
        }
    }

    private hideTextMessage(delay: number) {
        if (this.timeoutHideText) {
            clearTimeout(this.timeoutHideText);
        }
        this.timeoutHideText = setTimeout(() => {
            mlkitMatrixSimple.showText(false);
        }, delay);
    }

}
