import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'app-loading-button',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnInit {
    @Input() label: string = null;
    @Input() labelLoading: string = null;
    @Input() icon: string = null;
    @Input() tooltip: string = null;
    @Output() clickButton = new EventEmitter();
    @Input() isDisabled = true;

    private _isLoading = false;
    get isLoading(): boolean {
        return this._isLoading;
    }
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    constructor() {}

    ngOnInit() {}

    public clickBtn(event) {
        this.clickButton.next(event);
    }
}
