import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavParams, PopoverController } from '@ionic/angular';
import { MgaService } from '../../../core';
import { TagModel } from "../../../modules/ngx-chips/core/tag-model";

@Component({
  selector: 'suite-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.scss'],
})
export class FiltersListComponent implements OnInit {

  items: TagModel[] = [];
  warehouses: TagModel[] = [];
  packingReference = '';
  form = this.formBuilder.group({
    products: [],
    warehouses: [],
    orderBy: this.formBuilder.group({
      type: 'reference',
      order: 'ASC',
    }),
  });
  token: string;

  constructor(
    private formBuilder: FormBuilder,
    private navParams: NavParams,

    private popoverController: PopoverController,

    private mgaService: MgaService,
  ) {
      this.form = this.navParams.get('form');
      this.packingReference = this.navParams.get('packingReference');
      this.token = this.navParams.get('token');
  }

  ngOnInit() {
    this.getFilters();
  }

  clearFilters() {
    this.form = this.formBuilder.group({
      products: [],
      warehouses: [],
      orderBy: this.formBuilder.group({
        type: 'reference',
        order: 'ASC',
      }),
    });
  }

  async applyFilters() {
    const data: FilterListData = { items: this.getDismissData(), form: this.form };
    await this.popoverController.dismiss(data);
  }

  getDismissData(): FilterListItems {
      return {
        products: this.form.value.products ? this.form.value.products.map((p: FormProduct) => ({
            display: p.display,
            value: p.value,
            type: p.type,
        })) : [],
        warehouses: this.form.value.warehouses ? this.form.value.warehouses.map((w: FormWarehouse) => ({
            display: w.display,
            value: w.value,
        })) : [],
        orderBy: {
            field: this.form.value.orderBy.type === 'reference' ? 'reference' : 'destination',
            order: this.form.value.orderBy.order === 'ASC' ? 'ASC' : 'DESC',
        },
    };
  }

  getFilters() {
    this.mgaService.api.catalog.packing.getPackingInventoriesFilters(this.packingReference, this.token).subscribe((filters) => {
      this.items = filters.items.map(p => ({ display: p.reference, value: p.id, type: p.type }));
      this.warehouses = filters.destinationWarehouses.map(w => ({ display: w.name, value: w.id }));
    });
  }

}

export interface FilterListData {
    items: FilterListItems;
    form: FormGroup;
}

interface FilterListItems {
    products: FormProduct[];
    warehouses: FormWarehouse[];
    orderBy: {
        field: 'reference' | 'destination';
        order: 'ASC' | 'DESC';
    };
}

interface FormProduct {
    display: string;
    value: number;
    type: 'product' | 'package';
}

interface FormWarehouse {
    display: string;
    value: number;
}
