import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addTodo, loadTodos } from './todos.actions';
import { selectAllTodos } from './todos.selectors';

@Injectable({
    providedIn: 'root',
})
export class TodosSandbox {
    public allTodos$ = this.store.select(selectAllTodos);

    constructor(private store: Store) {}

    getTodosList(): void {
        this.store.dispatch(loadTodos());
    }

    doAddTodo(): void {
        this.store.dispatch(addTodo({ content: 'test' }));
    }
}
