import { MenuItemList } from './menu.data';
import { environment } from 'src/environments/environment';
import { ERPType } from "../app/models/erp.enum";

export const onlineOrdersMenu: MenuItemList = [
    {
        title: 'Pedidos Online',
        open: false,
        type: 'wrapper',
        icon: 'build-outline',
        isMobile: true,
        children: [
            ...(environment.ERP === ERPType.Odoo ? [{
                title: 'Embalajes',
                id: 'online-orders-packaging',
                url: '/packaging',
                icon: 'cube-outline',
                tooltip: 'Embalajes',
                isMobile: true,
                isWeb: true,
            }] : []),
            {
                title: 'Generar etiquetas de envio',
                id: 'online-orders-generate-labels',
                url: '/order-preparation',
                icon: 'basket-outline',
                tooltip: 'Imprimir etiquetas de contenedores',
                isMobile: true,
                isWeb: false,
            },
            {
                title: 'Incidencias',
                id: 'online-orders-incidences-sga',
                url: '/expedition-incidences',
                icon: 'notifications-outline',
                tooltip: 'Lista de incidencias',
                isWeb: false,
            },
            {
                title: 'Expediciones por recoger',
                id: 'online-orders-delivery-to-be-picked-up',
                url: '/expedition-collected',
                icon: 'cog-outline',
                tooltip: 'Lista de expediciones por recoger',
                isMobile: true,
            },
            {
                title: 'Histórico de Envíos',
                id: 'online-orders-shipping-history',
                url: '/transport-orders',
                icon: 'car-outline',
                tooltip: 'Ordenes',
                isMobile: true,
            },
            {
                title: 'Histórico pedidos cancelados',
                id: 'online-orders-cancelled-history',
                url: '/expedition-cancelled',
                icon: 'remove-circle-outline',
                tooltip: 'Lista de expediciones canceladas',
                isMobile: true,
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Logística Interna',
            //     id: 'online-orders-logistic-internal',
            //     url: '/package-history',
            //     icon: 'cube-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Generar expedición',
            //     id: 'online-orders-manual-shipping',
            //     url: '/expedition-manual',
            //     icon: 'add-outline',
            //     tooltip: 'Genere expediciones manualmente',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Contratación Seur',
            //     id: 'online-orders-hiring-seur',
            //     url: '/hiring-seur',
            //     icon: 'copy-outline',
            //     tooltip: 'Modifique datos de Seur',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Contratación Correos Express',
            //     id: 'online-orders-hiring-correosexpress',
            //     url: '/hiring-correosexpress',
            //     icon: 'copy-outline',
            //     tooltip: 'Modifique datos de Correos Express',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Contratación DHL',
            //     id: 'online-orders-hiring-dhl',
            //     url: '/hiring-dhl',
            //     icon: 'copy-outline',
            //     tooltip: 'Modifique datos de DHL',
            // },
            //  TODO: Uncomment when ready
            // {
            //     title: 'Cambio de Dirección',
            //     id: 'online-orders-address-change',
            //     url: '/orders/online/change-address',
            //     icon: 'repeat-outline',
            //     tooltip:
            //         'Cambie, actualice o ajuste la dirección de un pedido antes de su procesado y envío',
            // },
            {
                title: 'Pedidos Recibidos',
                id: 'online-orders-package-received',
                url: '/package-received',
                icon: 'cube-outline',
                tooltip: 'Lista de bultos recibidos por Logística Interna',
                isMobile: true,
                isWeb: false,
            },
        ],
    },
];
