import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageProvider {
    public KEYS = {
        USERNAME: 'username',
        ACCESS_TOKEN: 'access_token',
        USER_ID: 'user_id',
        USER: 'user',
        REFRESH_TOKEN: 'refreshToken',
        DICTIONARY_ACCESS: 'dictionaryAccess',
    };

    public set(key: string, value: any): Promise<void> {
        return new Promise((resolve) => {
            localStorage.setItem(key, value);
            resolve();
        });
    }

    public get(key: string) {
        return new Promise((resolve) => {
            const value = localStorage.getItem(key);
            if (typeof value != 'undefined' && value != null) {
                resolve(value);
            } else {
                resolve('');
            }
        });
    }

    public remove(key: string): Promise<void> {
        return new Promise((resolve) => {
            localStorage.removeItem(key);
            resolve();
        });
    }

    public clear(): Promise<void> {
        return new Promise((resolve) => {
            localStorage.clear();
            sessionStorage.clear();
            resolve();
        });
    }
}
