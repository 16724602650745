export namespace Auth {
    export interface ResponseModel {
        accessToken: string;
        accessTokenExpiresAt: string;
        client: {
            grants: string[];
            id: string;
            name: string;
        };
        permissions: string[];
        refreshToken: string;
        refreshTokenExpiresAt: string;
        scope: {
            id: number;
            name: string;
        };
        user: {
            email: string;
            id: number;
            name: string;
        };
    }

    export interface RequestModel {
        username: string;
        password: string;
        grant_type: string;
        scope: string;
    }
}
