import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ItemReferencesProvider, MlkitProvider } from '../../providers';

import { MlkitModel } from '../models';
import { getToken } from '../shared';

declare let mlkitMatrixSimple;

@Injectable({
    providedIn: 'root',
})
export class ProductInfoMlkitService {
    private timeoutHideText;

    private readonly timeMillisToResetScannedCode: number = 1000;

    constructor(
        private mlkitProvider: MlkitProvider,
        private itemReferencesProvider: ItemReferencesProvider
    ) {
        this.timeMillisToResetScannedCode =
            environment.time_millis_reset_scanned_code;
    }

    async init() {
        let lastCodeScanned: string = 'start';
        let lastProductReferenceScanned: string = 'start';
        let codeScanned: string = null;
        let timeoutStarted = null;
        const token = await getToken();

        mlkitMatrixSimple.initProductInfo(
            (response: MlkitModel.ResponseProductInfo) => {
                if (response && response.result) {
                    if (
                        response.barcode &&
                        response.barcode.data != lastCodeScanned
                    ) {
                        codeScanned = response.barcode.data;
                        lastCodeScanned = codeScanned;
                        lastProductReferenceScanned = codeScanned;

                        if (timeoutStarted) {
                            clearTimeout(timeoutStarted);
                        }
                        timeoutStarted = setTimeout(
                            () => (lastCodeScanned = 'start'),
                            this.timeMillisToResetScannedCode
                        );

                        if (
                            this.itemReferencesProvider.checkCodeValue(
                                codeScanned
                            ) ==
                                this.itemReferencesProvider.codeValue.PRODUCT ||
                            this.itemReferencesProvider.checkCodeValue(
                                codeScanned
                            ) ==
                                this.itemReferencesProvider.codeValue
                                    .PRODUCT_MODEL
                        ) {
                            mlkitMatrixSimple.request(
                                'GET',
                                environment.mgaApiUrl +
                                    '/inventory/product-info/' +
                                    codeScanned,
                                {},
                                token,
                                'getExtendedInfo'
                            );
                        } else {
                            mlkitMatrixSimple.showProgressBarProductExtendedInfo(
                                false
                            );
                            mlkitMatrixSimple.setText(
                                'El código escaneado no es válido para la operación que se espera realizar.',
                                this.mlkitProvider.colorsMessage.error.color,
                                this.mlkitProvider.colorText.color,
                                16
                            );
                            this.hideTextMessage(1500);
                        }
                    } else if (
                        response.barcode &&
                        response.barcode.data == lastProductReferenceScanned
                    ) {
                        mlkitMatrixSimple.showProgressBarProductExtendedInfo(
                            false
                        );
                    } else {
                        if (response.action == 'matrix_simple') {
                            // Mlkit started
                        } else if (response.action === 'request') {
                            switch (response.requestType) {
                                case 'getExtendedInfo':
                                    let responseData = null;
                                    if (response.data) {
                                        responseData = JSON.parse(
                                            response.data
                                        );
                                    }
                                    if (response.code == 200) {
                                        mlkitMatrixSimple.showProductExtendedInfo(
                                            true,
                                            responseData
                                        );
                                    } else {
                                        if (responseData) {
                                            console.error(
                                                'Error::Subscribe::GetExtendedInfo::',
                                                responseData
                                            );
                                        }
                                        mlkitMatrixSimple.showProgressBarProductExtendedInfo(
                                            false
                                        );
                                        mlkitMatrixSimple.setText(
                                            'Ha ocurrido un error al intentar consultar la información del producto.',
                                            this.mlkitProvider.colorsMessage
                                                .error.color,
                                            this.mlkitProvider.colorText.color,
                                            16
                                        );
                                        this.hideTextMessage(1500);
                                    }
                                    break;
                            }
                        }
                    }
                }
            },
            'Info. producto',
            this.mlkitProvider.colorsHeader.background.color,
            this.mlkitProvider.colorsHeader.color.color,
            environment.mgaApiUrl
        );
    }

    private hideTextMessage(delay: number) {
        if (this.timeoutHideText) {
            clearTimeout(this.timeoutHideText);
        }
        this.timeoutHideText = setTimeout(() => {
            mlkitMatrixSimple.showText(false);
        }, delay);
    }
}
