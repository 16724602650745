import { MenuItemList } from './menu.data';

export const userManagementMenu: MenuItemList = [
    {
        title: 'Gestión de usuarios',
        open: false,
        type: 'wrapper',
        icon: 'people-outline',
        permissions: ['#globalAdmin'],
        children: [
            {
                title: 'Gestión de usuarios',
                url: '/users/menu',
                id: 'user-management',
                icon: 'people-outline',
            },
            {
                title: 'Asignación de roles',
                id: 'role-assignment',
                url: '/role-assignment',
                icon: 'key-outline',
            },
            {
                title: 'Roles',
                id: 'roles',
                url: '/roles/menu',
                icon: 'person-outline',
            },
        ],
    },
];
