import { MenuItemList } from './menu.data';

export const tariffMenu: MenuItemList = [
    {
        title: 'Tarifas',
        id: 'tariff-sga',
        url: '/tariff',
        icon: 'logo-usd',
        permissions: ['tariff'],
        isWeb: true,
    },
    // TODO: Uncomment when ready
    /*{
      title: 'Tarifas',
      id: 'tariff-sga',
      open: false,
      type: 'wrapper',
      icon: 'logo-usd',
      permissions: ['tariff'],
      isMobile: true,
      isWeb: false,
      children: [
        {
          title: 'Tarifas',
          id: 'tariff-al',
          url: '/tariff',
          icon: 'logo-usd',
        //   notification: this.isNewTariff,
          tooltip: 'Listado de tarifas habilitadas',
          isMobile: true
        },
        {
          title: 'Código exposición',
          id: 'print-price-tag',
          url: 'print/tag/price',
          icon: 'pricetags',
          tooltip: 'Imprimir etiquetas de exposición escaneando los productos con cámara',
          isMobile: true
        },
        {
          title: 'Código exposición manual',
          id: 'print-price-tag-manual',
          url: '/print-tag/manual/price',
          icon: 'pricetags',
          tooltip: 'Impresión de códigos de exposición indicando manualmente el código de los productos y el motivo si fuese necesario',
          isMobile: true
        },
        {
          title: 'Nuevos Productos',
          id: 'new-products',
          url: '/new-products',
          icon: 'basket',
          tooltip: 'Listado de nuevos productos recibidos que aún no han sido impresos',
          isMobile: true
        }
      ]
  }*/
];
