export namespace Marketplaces {
    export interface MarketItem {
        configurationData: {
            apiOptions: {
                accessKeyId: string;
                clientId: string;
                clientSecret: string;
                marketplaceId: string;
                refreshToken: string;
                secretAccessKey: string;
                sellerId: string;
            };
            batching: {
                price: number;
                product: number;
                stock: number;
                timeoutSeconds: number;
            };
            catalog: {
                mappings: {
                    id: number;
                    name: string;
                }[];
            };
            rate: {
                minutes: number;
                requestNumber: number;
            };
        };
        createdAt: string;
        domainId: string;
        externalId: string;
        id: number;
        isExternal: boolean;
        name: string;
        platform: string;
        reId: string;
        status: string;
        updatedAt: string;
    }

    export interface ProductCatalog {
        absentInSc: boolean;
        batchId: string;
        brand: string;
        color: string;
        createdAt: string;
        description: string;
        family: string;
        id: number;
        model: string;
        productsMarkets: any[];
        reference: string;
        refreshedAt: string;
        scProductId: number;
        status: string;
        updateRequested: false;
        updatedAt: string;
    }
}
