import { MenuItemList } from './menu.data';

export const packingControlMenu = [{
      title: 'Control de embalajes',
      open: false,
      type: 'wrapper',
      icon: 'ribbon-outline',
      isMobile: true,
      isWeb: false,
      children: [
        {
          title: 'Lista Control Embalajes',
          id: 'packing-control-audit-al',
          url: '/audits',
          icon: 'list-box-outline',
          tooltip: 'Listado de controles de embalajes realizados'
        },
        {
          title: 'Revisiones Pendientes',
          id: 'packing-control-audit-rv',
          url: '/audits/pending-revisions',
          icon: 'list-box-outline',
          tooltip: 'Listado de revisiones de embalajes pendientes de realizar'
        },
        {
          title: 'Escaneo total (láser)',
          id: 'packing-control-add-audits',
          url: '/audits/add',
          icon: 'qr-scanner-outline',
          tooltip: 'Auditoría de embalaje mediante láser escaeando todos los productos'
        },
        {
          title: 'Escaneo aleatorio (cámara)',
          id: 'packing-control-audit-scan',
          url: 'audits/scan',
          icon: 'aperture-outline',
          tooltip: 'Auditoría mediante escáner de la cámara sin necesidad de escaner todos los productos'
        }
      ]
    }];
