import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TodoEffects } from './core/todos/todos.effects';
import { reducers } from './core/app.reducer';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { initializeAppFactory } from './core/initialize';
import { HomeLayoutComponent } from './layouts/home-layout/main-layout.component';
import { AuthEffect } from './core/auth/auth.effects';
import { CatalogEffect } from './core/catalog/effects';
import { AuthGuard } from './core/auth/auth.guard';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import { MarketplacesEffect } from './core/api-middleware/marketplaces/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorTranslator } from './mat-paginator-local';

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        AuthLayoutComponent,
        HomeLayoutComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        EffectsModule.forRoot([
            TodoEffects,
            AuthEffect,
            CatalogEffect,
            MarketplacesEffect,
        ]),
        StoreModule.forRoot(reducers),
        IonicStorageModule.forRoot(),
        SharedModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        Keyboard,
        NativeAudio,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [HttpClient],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        AuthGuard,
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorTranslator,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
