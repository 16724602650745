export const API_MIDDLEWARE_ENDPOINTS = {
    marketplacesUrl: '/market',
    searchCatalogUrl: '/product/search',
    searchCatalogVariationsUrl: '/product/search-with-variations',
    getMarketBrandsUrl: '/market/product-property/brands/by-market/',
    getMarketSizesUrl: '/market/product-property/sizes/by-market/',
    getMarketColorsUrl: '/market/product-property/colors/by-market/',
    getMarketFeaturesUrl: '/market/product-property/features/by-market/',
    getMarketSeasonsUrl: '/market/product-property/seasons/by-market/',
    postUpdateZalandoSizeMapWithApi: '/zalando-size-map/update-with-api',
    updateCategoriesMinPricesUrl: '/product/remap-for-market',
    updateDataUrl: '/product/remap-rule-filter',
    getMarketProductPropertyUrl: '/market/product-property',
    getZalandoSizeMapUrl: '/zalando-size-map',
    postZalandoSizeMapUpdateUrl: '/zalando-size-map/update-size/',
    info: '/oddjob',
};
