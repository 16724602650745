import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSelect from './auth.selector';
import * as actions from './auth.actions';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class AuthSandbox {
    //
    public loginLoading$ = this.store.select(fromSelect.loginLoading);
    public loginData$ = this.store.select(fromSelect.loginData);
    public loginFailData$ = this.store.select(fromSelect.loginFailData);
    //
    public getProfileData$ = this.store.select(fromSelect.getProfileData);
    public getProfileFailData$ = this.store.select(
        fromSelect.getProfileFailData
    );
    public getProfileLoading$ = this.store.select(fromSelect.getProfileLoading);

    private _accessToken: string;
    public get accessToken(): string {
        if (!this._accessToken && isPlatformBrowser(this.platformId)) {
            this._accessToken = JSON.parse(localStorage.getItem('token'));
        }
        return this._accessToken;
    }
    private set accessToken(value) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('token', JSON.stringify(value));
        }
        this._accessToken = value;
    }

    public profileData: any;

    constructor(
        private store: Store,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.loginData$.subscribe(res => {
            if (res) {
                this.accessToken = res.accessToken || null;
            }
        });
    }

    doLogin(params: any): void {
        this.store.dispatch(actions.doLoginAction({ params }));
    }

    doLogout(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
            this.accessToken = null;
            sessionStorage.clear();
        }
    }

    doGetProfile(): void {
        this.store.dispatch(actions.getProfileAction());
    }
}
