export namespace GlobalVariableModel {

    export interface GlobalVariable{
        id?: number;
        created_at?: string;
        updated_at?: string;
        type: string;
        value: string;
    }

    export interface GlobalVariableTypeInfo {
        name: string;
        type: string;
        tooltip: string;
    }

    export interface GlobalVariableType {
        identifier: string;
        name: string;
        type: string;
        tooltip: string;
    }

    export interface ListGlobalVariablesResponse {
        db: {
            type: string;
            value: string;
        }[];
        effective: {
            storeDaySalesForAlgorithm: number;
            otMinimumAllocationThreshold: number;
            rejectReasonRequestsWoStock: string;
            storeSalesCountLimit: number;
            rejectReasonDeliveryAndRequestsStores: string;
            sorterEnabled: boolean;
            hoursToCheckDuplicateOutputScanAvelon: number;
            minutesForReserveExpiration: number;
            email: {
                to: {
                    stockPackingSealNotifyAvelonError: string;
                    admins: string;
                };
            };
        };
    }

    export type TypeDescriptorsResponse = Record<string, GlobalVariableTypeInfo>;

}
