import { TariffWarehouseModel } from './tariff-warehouse.model';

export namespace TariffModel {

    export interface Tariff {
        id: number;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date;
        avelonId: number;
        name: string;
        activeFrom: Date;
        activeTill: Date;
        activeFromChange: Date;
        activeTillChange: Date;
        discountReasonId: number;
        hash: string;
        enabled: boolean;
        tariffPrices: any[];
        tariffWarehouses: TariffWarehouseModel.TariffWarehouse[];
    }

    export interface Pagination {
        page: number;
        lastPage: number;
        limit: number;
        count: number;
    }

    export interface GetTariffFilters {
        name: string[];
        activeFrom: string[];
        activeTill: string[];
        activeFromChange: string[];
        activeTillChange: string[];
        enabled: number[];
    }

    export interface GetTariffsParameters {
        filters: GetTariffsFilters;
        pagination: GetTariffsPagination;
        orderBy: GetTariffsOrderBy;
    }

    export interface GetTariffsPagination {
        page: number;
        limit: number;
    }

    export interface GetTariffsOrderBy {
        field: TypeFilter;
        order: 'ASC' | 'DESC';
    }

    export interface GetTariffsFilters {
        names: string[];
        activeFrom: string[];
        activeTill: string[];
        activeFromChange: string[];
        activeTillChange: string[];
        enabled: boolean;
    }

    export interface FilterItem {
        value: string;
        checked: boolean;
        hide: boolean;
    }

    export interface GetTariffs {
        records: Tariff[];
        pagination: Pagination;
    }

    export interface UpdateFilterPriceParameters {
        warehouseId: number;
        status?: PriceFilterStatus;
    }

    export interface ItemModel {
        id: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;

        avelonId: number;
        name: string;
        activeFrom: string;
        activeTill: string;
        activeFromChange: string;
        activeTillChange: string;
        discountReasonId: number;
        hash: string;
        enabled: boolean;
    }

    export enum TypeFilter {
        Name = 'name',
        ActiveFrom = 'activeFrom',
        ActiveTill = 'activeTill',
        ActiveFromChange = 'activeFromChange',
        ActiveTillChange = 'activeTillChange',
        Enabled = 'enabled',
    }

    export interface IStatusToSend {
        tariffId: number;
        warehouseId: number;
        status: string; // En mga ... PriceFilterStatus;
    }

}

export enum PriceFilterStatus {
    new = 'new',
    updated = 'updated',
    deleted = 'deleted',
    printed = 'printed',
    noPrinted = 'noPrinted',
    all = 'all',
    deletedOutdatedTariff = 'deletedOutdatedTariff',
}
