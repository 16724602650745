import { ProductModel } from './product.model';
import { HttpRequestModel } from './HttpRequest';

export namespace ReceptionModel {
    export interface ReceptionProduct {
        force?: boolean;
        reference: string;
    }

    export interface Reception {
        force?: boolean;
        packingReference: string;
    }

    export interface ResponseReceive extends HttpRequestModel.Response {
        data: {
            pickingId: number;
            quantity: number;
            hasNewProducts: boolean;
            hasRequestedProducts?: boolean;
            orderCodes?: string[];
            newQuantity?: number;
            requestedQuantity?: number;
            noOnlineProducts?: number;
        };
        message: string;
        code: number;
        errors: any;
    }

    export interface ResponseCheckPacking extends HttpRequestModel.Response {
        data: any;
        message: string;
        code: number;
        errors: any;
    }

    export interface ResponseCheckProductsPacking {
        data: {
            quantity: number;
            products: ProductModel.Product[];
        };
        message: string;
        code: number;
        errors: any;
    }

    export interface ResponseReceptionProduct
        extends HttpRequestModel.Response {
        data: {
            remainingProducts: number;
            inventoryPacking: any;
            hasNewProducts: boolean;
            hasRequestedProducts?: boolean;
            productRequestedId?: number;
            isNoOnline?: boolean;
            orderCode: string;
        };
        message: string;
        code: number;
        errors: any;
    }

    export interface ResponseNotReceivedProducts
        extends HttpRequestModel.Response {
        data: string;
        message: string;
        code: number;
        errors: any;
    }

    export interface ErrorResponse {
        errors: string;
        message: string;
        code: number;
    }
}
