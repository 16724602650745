import { BrandModel } from './brand.model';
import { PhotoModel } from './photo.model';
import { SeasonModel } from './season.model';
import { FamilyModel } from './family.model';
import { LifestyleModel } from './lifestyle.model';
import { ColorModel } from './color.model';

export namespace ModelModel {
    export interface Model {
        id?: number;
        reference: string;
        color?: ColorModel.Color;
        name?: string;
        createdAt?: string;
        updatedAt?: string;
        hash?: string;
        avelonInternalBrandId?: number;
        brand?: BrandModel.Brand;
        photos?: Array<PhotoModel.Photo>;
        domainSize?: any;
        has_photos?: boolean;
        season?: SeasonModel.Season;
        family?: FamilyModel.Family;
        lifestyle?: LifestyleModel.Lifestyle;
        supplierReference?: string;
        multipleField?: 'name' | 'supplierReference';
        linkedItem?: Model;
    }

    export interface ResponseIndex {
        data: Model[];
    }

    export interface ErrorResponseIndex {
        statusCode: number;
        status: number;
        code: number;
        message: string;
        name: string;
    }
}
