import { BrandModel } from './brand.model';
import Brand = BrandModel.Brand;

export namespace ProviderModel {
    export interface Provider {
        id: number;
        name: string;
        hash?: string;
        avelonId?: number;
        brands?: Brand[];
    }
}
