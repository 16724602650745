import { MenuItemList } from './menu.data';

export const factoryReceptionMenu = [
    {
        title: 'Recepción de fábrica',
        open: false,
        type: 'wrapper',
        icon: 'archive-outline',
        isMobile: true,
        children: [
            {
                title: 'Proceso recepción',
                id: 'factory-reception-process',
                url: '/receptions-avelon',
                icon: 'pricetags-outline',
            },
            {
                title: 'Recepción sin pedido',
                id: 'factory-reception-process-without-order',
                url: '/receptions-avelon/free',
                icon: 'pricetags-outline',
                isWeb: false,
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Predistribuciones',
            //     id: 'factory-reception-predistributions',
            //     url: '/predistributions',
            //     icon: 'options-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Stock Reservado',
            //     id: 'factory-reception-reserved-stock',
            //     url: '/receptions',
            //     icon: 'hand-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Pendiente notificar Avelon',
            //     id: 'factory-reception-pending-notification-avelon',
            //     url: '/pr-ta-se-av',
            //     icon: 'cloud-upload-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Estados de expediciones',
            //     id: 'factory-reception-management-states-expedition',
            //     url: '/state-expedition-avelon/menu',
            //     icon: 'cog-outline',
            // },
            // TODO: Uncomment when ready
            // {
            //     title: 'Temporadas habilitadas',
            //     id: 'factory-reception-management-seasons-enabled',
            //     url: '/seasons-enabled',
            //     icon: 'cog-outline',
            // },
            {
                title: 'Habilitar campos comerciales',
                id: 'factory-reception-management-commercial-fields',
                url: '/commercial-fields',
                icon: 'cog-outline',
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Marcas habilitadas recepción sin pedido',
            //     id: 'factory-reception-management-brands-enabled-reception',
            //     url: '/brands-enabled-reception',
            //     icon: 'cog-outline',
            // },
            {
                title: 'Recepción de mercancía',
                id: 'factory-reception-process',
                url: '/expeditions/pending/app',
                icon: 'pricetags-outline',
                tooltip:
                    'Realiza la recepción de mercancía recibida de fábrica mediante una expedición o proveedor.',
                isMobile: true,
                isWeb: false,
            },
            // TODO: Uncomment when ready
            // {
            //     title: 'Incidencias',
            //     id: 'incidences-reception',
            //     url: '/incidences-reception',
            //     icon: 'notifications-outline',
            //     permissions: ['reception.incidence'],
            // },
        ],
    },
];
