import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { ApiMga } from './api-mga';

@Injectable({
    providedIn: 'root',
})
export class MgaService {
    readonly api: ApiMga;
    constructor(http: HttpClient) {
        this.api = new ApiMga(http, environment.mgaApiUrl);
    }
}
