import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-almacen-select',
    templateUrl: './almacen-select.component.html',
    styleUrls: ['./almacen-select.component.css'],
})
export class AlmacenSelectComponent implements OnInit {
    data = [1, 1, 1, 1, 1, 1, 1, 1];

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
