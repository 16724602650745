import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CatalogSandbox } from 'src/app/core/catalog/sandbox';
import { MessageService } from '../../message.service';
import {MgaService} from "../../../core/mga.service";
import {getToken} from "../../helper-functions/helper-functions";

@Component({
    selector: 'app-edit-rack-size',
    templateUrl: './edit-rack-size.component.html',
    styleUrls: ['./edit-rack-size.component.css'],
})
export class EditRackSizeComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    public item;

    form: FormGroup;
    rows: FormControl = new FormControl(0, Validators.required);
    columns: FormControl = new FormControl(0, Validators.required);

    constructor(
        private modalCtrl: ModalController,
        private fb: FormBuilder,
        public catalogSandbox: CatalogSandbox,
        private message: MessageService,
        public service: MgaService,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            rows: this.rows,
            columns: this.columns,
        });

        if (this.item) {
            this.rows.patchValue(this.item.rows);
            this.columns.patchValue(this.item.columns);
        }
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    async submit() {
        if (
            +this.item?.rows === +this.rows.value &&
            +this.item?.columns === +this.columns.value
        ) {
            this.modalCtrl.dismiss();
            return;
        }

        if (this.form.valid && this.item?.id) {
            const params: any = {
                rows: this.rows.value || 0,
                columns: this.columns.value || 0,
            };
            const token = await getToken();
            this.service.api.catalog.rack.rackResizeByRackId(this.item.id, params, token).subscribe(async res => {
                if(res.rows === params.rows && res.columns === params.columns){
                    this.message.log('Pasillo actualizado con éxito');
                    this.modalCtrl.dismiss(1);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
