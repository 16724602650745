import { Component, Input, OnInit } from '@angular/core';
import { Type } from '../../modules/factory-reception/receptions-avelon/enums/type.enum';
import { VirtualKeyboardService } from '../virtual-keyboard/virtual-keyboard.service';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-size-input',
    templateUrl: './size-input.component.html',
    styleUrls: ['./size-input.component.scss'],
})
export class SizeInputComponent implements OnInit {
    @Input() item: {
        id: number;
        number: string;
        name: string;
        reference: string;
        available: boolean;
        quantity: number;
        color: string;
    } = null;

    constructor(
        private virtualKeyboardService: VirtualKeyboardService,
        public platform: Platform
    ) {}

    ngOnInit() {}

    public selectValue(item) {
        this.platform.ready().then(() => {
            if (this.platform.is('mobile')) {
                setTimeout(() => {
                    (
                        document.getElementById('input_' + item.id) as any
                    ).select();
                }, 200);
            } else {
                const keyboardEventEmitterSubscribe =
                    this.virtualKeyboardService.eventEmitter.subscribe(data => {
                        if (data.keyPress && data.keyPress != '') {
                            item.quantity = parseInt(data.keyPress);
                        } else {
                            item.quantity = 0;
                        }
                    });

                this.virtualKeyboardService.openVirtualKeyboard(
                    {
                        type: Type.SIZE_INPUT,
                        layout_type: 'number',
                        placeholder: 'Seleccione la cantidad',
                        initialValue: item.quantity.toString(),
                    },
                    () => keyboardEventEmitterSubscribe.unsubscribe()
                );
            }
        });
    }
}
