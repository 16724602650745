import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { catalogRecord, CatalogState } from './state';

const currentState = (state: AppState) => state.catalog;
//
export const syncSizesLoading = createSelector(
    currentState,
    (state: CatalogState) => state.syncSizesLoading
);
export const syncSizesData = createSelector(
    currentState,
    (state: CatalogState) => state.syncSizesData
);
export const syncSizesFailData = createSelector(
    currentState,
    (state: CatalogState) => state.syncSizesFailData
);
//
export const getWarehouseListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseListLoading
);
export const getWarehouseListData = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseListData
);
export const getWarehouseListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseListFailData
);
//
export const getWarehouseItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseItemLoading
);
export const getWarehouseItemData = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseItemData
);
export const getWarehouseItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getWarehouseItemFailData
);
//
export const updateWarehouseItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.updateWarehouseItemLoading
);
export const updateWarehouseItemData = createSelector(
    currentState,
    (state: CatalogState) => state.updateWarehouseItemData
);
export const updateWarehouseItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.updateWarehouseItemFailData
);
//
export const syncWarehouseLoading = createSelector(
    currentState,
    (state: CatalogState) => state.syncWarehouseLoading
);
export const syncWarehouseData = createSelector(
    currentState,
    (state: CatalogState) => state.syncWarehouseData
);
export const syncWarehouseFailData = createSelector(
    currentState,
    (state: CatalogState) => state.syncWarehouseFailData
);
//
export const getRackListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getRackListLoading
);
export const getRackListData = createSelector(
    currentState,
    (state: CatalogState) => state.getRackListData
);
export const getRackListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getRackListFailData
);
//
export const getRackItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getRackItemLoading
);
export const getRackItemData = createSelector(
    currentState,
    (state: CatalogState) => state.getRackItemData
);
export const getRackItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getRackItemFailData
);
//
export const resizeRackItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.resizeRackItemLoading
);
export const resizeRackItemData = createSelector(
    currentState,
    (state: CatalogState) => state.resizeRackItemData
);
export const resizeRackItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.resizeRackItemFailData
);
//
export const getContainerListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerListLoading
);
export const getContainerListData = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerListData
);
export const getContainerListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerListFailData
);
//
export const getContainerItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerItemLoading
);
export const getContainerItemData = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerItemData
);
export const getContainerItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getContainerItemFailData
);
//
export const setLockedContainerLoading = createSelector(
    currentState,
    (state: CatalogState) => state.setLockedContainerLoading
);
export const setLockedContainerData = createSelector(
    currentState,
    (state: CatalogState) => state.setLockedContainerData
);
export const setLockedContainerFailData = createSelector(
    currentState,
    (state: CatalogState) => state.setLockedContainerFailData
);
//
export const getSizesListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getSizesListLoading
);
export const getSizesListData = createSelector(
    currentState,
    (state: CatalogState) => state.getSizesListData
);
export const getSizesListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getSizesListFailData
);
//
export const getDomainSizesListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getDomainSizesListLoading
);
export const getDomainSizesListData = createSelector(
    currentState,
    (state: CatalogState) => state.getDomainSizesListData
);
export const getDomainSizesListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getDomainSizesListFailData
);
//
export const getPackingItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingItemLoading
);
export const getPackingItemData = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingItemData
);
export const getPackingItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingItemFailData
);
//
export const getPackingListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingListLoading
);
export const getPackingListData = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingListData
);
export const getPackingListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getPackingListFailData
);
//
export const updatePackingItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.updatePackingItemLoading
);
export const updatePackingItemData = createSelector(
    currentState,
    (state: CatalogState) => state.updatePackingItemData
);
export const updatePackingItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.updatePackingItemFailData
);
//
export const getMarketListLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketListLoading
);
export const getMarketListData = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketListData
);
export const getMarketListFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketListFailData
);
//
export const deleteMarketItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.deleteMarketItemLoading
);
export const deleteMarketItemData = createSelector(
    currentState,
    (state: CatalogState) => state.deleteMarketItemData
);
export const deleteMarketItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.deleteMarketItemFailData
);
//
export const updateMarketItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.updateMarketItemLoading
);
export const updateMarketItemData = createSelector(
    currentState,
    (state: CatalogState) => state.updateMarketItemData
);
export const updateMarketItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.updateMarketItemFailData
);
//
export const getMarketItemLoading = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketItemLoading
);
export const getMarketItemData = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketItemData
);
export const getMarketItemFailData = createSelector(
    currentState,
    (state: CatalogState) => state.getMarketItemFailData
);
