import { ERPType } from "../app/models/erp.enum";

export const environment = {
    production: true,
    ERP: ERPType.Odoo,
    time_millis_reset_scanned_code: 2 * 1000,
    customToken: '385431d8f107a8487022a19949400623a27823c4',
    mgaApiUrl: 'https://nutribiotica-mga-web.moodular.io',
    apiMiddleware: 'https://nutribiotica-re-middleware.moodular.io',
    apiRule: 'https://nutribiotica-rule-engine.moodular.io',
    apiCrm: 'https://nutribiotica-crm.moodular.io',
    apiLogisticOperator: 'https://nutribiotica-operadores-logisticos.moodular.io/api/',
};

export let app: { name: 'sga' | 'al' } = {
    name: 'sga',
};

export const ZALANDO_ID = '';

export const arrayCategories = [2, 4, 6, 3, 9, 7];
export const group = [
    {
        id: 1,
        name: 'Sección',
    },
    {
        id: 2,
        name: 'Familia',
    },
    {
        id: 4,
        name: 'Categorias',
    },
    {
        id: 6,
        name: 'Tacón',
    },
    {
        id: 3,
        name: 'Descripción',
    },
    {
        id: 9,
        name: 'Mat. Exterior',
    },
    {
        id: 7,
        name: 'Mat. Interior',
    },
    {
        id: 12,
        name: 'Comercial',
    },
    {
        id: 15,
        name: 'Marca',
    },
    {
        id: 16,
        name: 'Color',
    },
    {
        id: 17,
        name: 'Talla',
    },
    {
        id: 18,
        name: 'Precio',
    },
    {
        id: 19,
        name: 'Añadir referencias',
    },
    {
        id: 20,
        name: 'Excluír referencias',
    },
];

export const environmentPrinter = {
    production: true,
    printer: 'QLn220',
    printLicense:
        '-----BEGIN CERTIFICATE-----\n' +
        'MIIE4zCCAs2gAwIBAgIFMTAyMjAwCwYJKoZIhvcNAQEFMIGYMQswCQYDVQQGEwJV\n' +
        'UzELMAkGA1UECAwCTlkxGzAZBgNVBAoMElFaIEluZHVzdHJpZXMsIExMQzEbMBkG\n' +
        'A1UECwwSUVogSW5kdXN0cmllcywgTExDMRkwFwYDVQQDDBBxemluZHVzdHJpZXMu\n' +
        'Y29tMScwJQYJKoZIhvcNAQkBFhhzdXBwb3J0QHF6aW5kdXN0cmllcy5jb20wHhcN\n' +
        'MjEwMjIxMDUwMDAwWhcNMjIwMjIyMDUwMDAwWjCBrTELMAkGA1UEBgwCRVMxEzAR\n' +
        'BgNVBAgMClBvbnRldmVkcmExDTALBgNVBAcMBFZpZ28xGzAZBgNVBAoMEkdhbHZp\n' +
        'biBUZWNub2xvZ2ljYTEbMBkGA1UECwwSR2FsdmluIFRlY25vbG9naWNhMRswGQYD\n' +
        'VQQDDBJHYWx2aW4gVGVjbm9sb2dpY2ExIzAhBgkqhkiG9w0BCQEMFG1vaXNlc0Bn\n' +
        'YWx2aW50ZWMuY29tMIIBIDALBgkqhkiG9w0BAQEDggEPADCCAQoCggEBAK2u6Rvc\n' +
        'YXtSAKWRZGJpy2UIJmRS7iTLaOVbBCaeEwA8kY2CT93hAtVXoCoUbRk/2EzXHD1H\n' +
        '5i5tL9HRZOqvPQYeez6GW2NN1sM8DMKQgG9YQ5pB8o9APIQD7/649Kf00yUk0Blk\n' +
        'mVIfO3idODIen6JsMHFAktHFH5NiH2Tt2l7oTwf8+GzIHzlxF5ELoMXKLz8V5fqX\n' +
        'N5YB8nENrC7Gtau4IzmQuVCwIIWHWcS3B/v52z7R0FMPS/PQm1T2QavMB7zf3f2f\n' +
        'd2HnWjWLADb0a1AOzWKcreM1W63Qv1mn5YzkBewB5cW62tf+RGrLPHaiqN/lCkwm\n' +
        '9zvJ4olY1RL9ws0CAwEAAaMjMCEwHwYDVR0jBBgwFoAUkKZQt4TUuepf8gWEE3hF\n' +
        '6Kl1VFwwCwYJKoZIhvcNAQEFA4ICAQClBM+GeGMZqbmdii22aKAF4LNiOh4bbN5V\n' +
        'rMIZOgA5L2EummzcoCjRQ49dCAhy7I3cblF2ip7ZJ8FGnRh0TiM71iTyNig/C3qK\n' +
        'qHRWhETBqX5PfB4UFVOQ6Hqckq+0pRu6Delg5KbxuflmbWr+DAc7I4iVh+l+mOk6\n' +
        'QxldTNnE0aAJhn3SxtDhkIoQUftDzi8WJ7aBcqH4ERmLX0mUx1IPSj4iF1QYZw+x\n' +
        'h2DE0WRsawpnYP0XiCqh1BWfKnuUwZy+HRgCILePyNngUb7ZHt7YGWBwrSJG3w8p\n' +
        'gNsRoF5B2WC2O6gn5lM6XofLC4yyaQLrrtGQ5W2pD719euup1OVo++PKZFHIA31I\n' +
        'GrPbrVOdrbvi/cJlL5QKL2kiq8io2qZjjnshVyzECEgw+DZcoHPIsNuGT5meFSvO\n' +
        'k4KWA39fozCLJEXUpTHXtCpYvDWj6bj+spnZC6tRJECVPn6s0WDJesJKEbkN0p8J\n' +
        '9nffAOWA6fmMTd3opmJTEPfsEOCf1yB6UUHJ+Vv3s5vRq400YENzJ+HKMF/hrQ+C\n' +
        'Dq9X8VxOXMO2nNvJsj28vGsut5b47u+SEbaasWAb6R2SpkTDxsIJ+zMrzKYiemcv\n' +
        '5+A0EXrDxjXgxMjm4kLelC+NcnGLBmSE7W7pywbIRrcuSQYXoXQQ3k9w3oVppzWi\n' +
        '+qNbl0tWmw==\n' +
        '-----END CERTIFICATE-----\n' +
        '--START INTERMEDIATE CERT--\n' +
        '-----BEGIN CERTIFICATE-----\n' +
        'MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n' +
        'MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n' +
        'bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n' +
        'A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n' +
        'emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n' +
        'gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n' +
        'cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n' +
        'EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n' +
        'c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n' +
        'iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n' +
        'YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n' +
        'GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n' +
        'EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n' +
        '0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n' +
        'xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n' +
        '9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n' +
        'ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n' +
        '2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n' +
        '6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n' +
        '5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n' +
        'plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n' +
        'BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n' +
        'eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n' +
        'gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n' +
        'x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n' +
        'pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n' +
        '2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n' +
        'rbO7BnjW\n' +
        '-----END CERTIFICATE-----\n',
    qzTrayPrivateKey:
        '-----BEGIN RSA PRIVATE KEY-----\n' +
        'MIIEowIBAAKCAQEAra7pG9xhe1IApZFkYmnLZQgmZFLuJMto5VsEJp4TADyRjYJP\n' +
        '3eEC1VegKhRtGT/YTNccPUfmLm0v0dFk6q89Bh57PoZbY03WwzwMwpCAb1hDmkHy\n' +
        'j0A8hAPv/rj0p/TTJSTQGWSZUh87eJ04Mh6fomwwcUCS0cUfk2IfZO3aXuhPB/z4\n' +
        'bMgfOXEXkQugxcovPxXl+pc3lgHycQ2sLsa1q7gjOZC5ULAghYdZxLcH+/nbPtHQ\n' +
        'Uw9L89CbVPZBq8wHvN/d/Z93YedaNYsANvRrUA7NYpyt4zVbrdC/WafljOQF7AHl\n' +
        'xbra1/5Eass8dqKo3+UKTCb3O8niiVjVEv3CzQIDAQABAoIBAARMCJw7RIB7USTy\n' +
        'LFW1+zBVoQjdjLhFfbkiRRTv12sA/0iNEeJHVp1bdLeNzVGGWkOJFTuxO3E9xiTZ\n' +
        'WsyRR3/PAcAUgrl50RzVRRXhjYKTtbUg5A97aJ6LMA/Gg4EN8NUMAd0FojhyqYiX\n' +
        'T9myQcNQN2uZ4XgF+efDfKDaifWhRyDZjvn/q9Mr899+ZBW5VHnaLob00eJhUBu9\n' +
        'O9yvq90MrDm0GrfIDtb505VnQZ0DyiR8oxQf8GveKxImKVOrCyyXJC1EX4AXI/bf\n' +
        '/Q4RpuNSJchwROp9L/+eK2i6urotiTPzm5K4p3zJEKa9AR2fHHN0oVpZoedvCBxC\n' +
        'eAQOP/kCgYEA2bkP3aXl7IKNsiZMRuyiUJGaRlifkv7A6/78gzLiyTzKTDAItsUZ\n' +
        'DwXUeL7GUoAKzeemZd2pSEQHArZrPmFg9+/XJbscC1v1gxVNnx3GXjvnu2wogepz\n' +
        'YGEpbMF0Av6YD6qaLjfTMn7ybh9K2vjMS+tUrSuatf0BU/+NusXwmsUCgYEAzDfH\n' +
        'irWf0kv7RPrRGc6d+hhIDZrwcd1PteAtq0DGuEhfaL8kFIJ+kEoiwjLzOCUmpJC9\n' +
        'rJ/XQ7Ng39eEPZ4DaGFlzer70ifDSA1Jo8UjbUaDSS7geog76Vt+pkA86uzhwzUb\n' +
        'UoHvd2Ldy/wB3vO6zOmoK0pqnHeKaIVBOWaZqGkCgYB1dEOd5DCMjcWZNNXzcRcD\n' +
        'dfkPOCIl767t4Woh8SKtQ1EzFrqsttmU5CVCWlCtEwxBFlYLIDCBnslVakmipm/k\n' +
        'cYvUfANeEWb6J1TvoOVx0slpr6iOpYdGmEP7usU2FFi+It/4TP9vf1KTm+gLBytX\n' +
        'TYYpwZs90FaWFQhFmSD6rQKBgQCB9w26rRPlVg23PJhOgDvxqfpRDkXvcRUFfoIR\n' +
        '9suBBC4UD3D6zIGXelbcmGhJVvtcx/CuJ5trroD8c7/pJuKsTy/m+KECgeO5uxse\n' +
        'apu9M57huD+MO59FR8E2NLOQHukevnGA9MhdhkH5S4w1Q4+iUSGBS37FzR38nrF/\n' +
        'wlgP4QKBgDdV8okwamLdR5Yb83vVQOkHZt5MErOsBDbmAqF7tiIShHF4p9jW2+2c\n' +
        'E3DmdiDU+dF6x4G4cdtM386o6c0lhc2xvmcz648lMwl7VqHrVBbOtPXMjr6VbgjD\n' +
        'cEMIXjHhbWlTha/+33oIplIxdocUnQiS6U/su4RceEVhE98c9kGI\n' +
        '-----END RSA PRIVATE KEY-----',
};
