import { HttpRequestModel } from './HttpRequest';

export namespace ReceptionAvelonModel {
    export interface Data {
        id: number;
        name: string;
        selected: boolean;
        newSelected?: boolean;
        modelIds?: Array<number>;
        state?: number;
        ids?: number[];
        photoUrlsByIds?: any;
        color?: string;
        learned?: boolean;
        avelonId?: number;
    }

    export interface Reception {
        brands: Array<Data>;
        models: Array<Data>;
        colors: Array<Data>;
        lines: {
            id: number;
            state: string;
            brandId: number;
            modelId: number;
            colorId: number;
        }[];
        ean?: string;
        image?: string;
    }

    export interface Providers {
        id?: string;
        name: string;
        avelonId: number;
    }

    export interface CheckProvider {
        providerId: number;
        expedition: string;
        providerAvelonId: number;
    }

    export interface GetProvider {
        providerId: number;
        typeVisualization: number;
    }

    export interface GetAllByProvider {
        modelVisualization: any;
        colorVisualization: any;
    }

    export interface Print {
        reference: string;
        ean: any;
        expedition: string;
        modelId: number;
        sizeId: number;
        colorId: number;
        providerId: number;
        brandId: number;
    }

    export interface ParamsToPrint {
        modelVisualization: string;
        providerId: number;
        items: any[];
    }

    //region CheckExpeditionsByNumberAndProvider
    export interface ParamsCheckExpeditionsByNumberAndProvider {
        providerId: number;
        expeditionNumber: string;
    }
    export interface CheckExpedition {
        reference: string;
        providerId: number;
        providerAvelonId: number;
        providerName: string;
        isAvailable: boolean;
        hasExpeditions: boolean;
        expedition: {
            reference: string;
            packingAmount: number;
            deliveryDate: string;
            shipper: string;
            states: [];
            receptionPallets: number;
            receptionPackings: number;
            receptionStates: [number];
            receptionBlocked: boolean;
            noPendingReceptions: boolean;
            noPendingConfirms: boolean;
            receptionDisabled: boolean;
        };
        expeditions: [];
    }
    export interface CheckExpeditionsByProvider {
        providerId: number;
        providerAvelonId: number;
        providerName: string;
        expeditions: Expedition[];
    }

    export interface ResponseCheckExpeditionByReference
        extends HttpRequestModel.CrudPaginatedResponse {
        reference: string;
        providerId: number;
        providerAvelonId: number;
        providerName: string;
        isAvailable: boolean;
        hasExpeditions: boolean;
        expedition: {
            providerId: number;
            providerAvelonId: number;
            providerName: string;
            reference: string;
            packingAmount: number;
            deliveryDate: string;
            shipper: string;
            states: [];
            receptionPallets: number;
            receptionPackings: number;
            receptionStates: [number];
            receptionBlocked: boolean;
            noPendingReceptions: boolean;
            noPendingConfirms: boolean;
            receptionDisabled: boolean;
        };
        expeditions: [];
    }
    export interface ResponseCheckExpeditionsByProvider {
        providerId: number;
        providerAvelonId: number;
        providerName: string;
        expeditions: Expedition[];
    }
    //endregion

    //region LoadSizesList
    export interface ParamsLoadSizesList {
        modelId: number;
        colorId: number;
    }
    export interface LoadSizesList {
        id: number;
        number: string;
        name: string;
        reference: string;
        available: boolean;
        quantity: number;
        color?: string;
    }
    export interface ResponseLoadSizesList {
        data: LoadSizesList[];
    }
    //endregion

    //region
    export interface ParamsReloadModelsList {
        typeVisualization: number;
        providerId: number;
    }
    export interface ReloadModelsList {
        id: number;
        name: string;
        selected: boolean;
        color: string;
        photos_models: any;
        available_ids: number[];
    }
    export interface ResponseReloadModelsList
        extends HttpRequestModel.CrudPaginatedResponse {
        data: ReloadModelsList[];
    }
    //endregion

    //region
    export interface ParamsReloadColorsList {
        typeVisualization: number;
        providerId: number;
    }
    export interface ReloadColorsList {
        id: number;
        name: string;
        selected: boolean;
        color: string;
        belongsModels: number[];
    }
    export interface ResponseReloadColorsList
        extends HttpRequestModel.CrudPaginatedResponse {
        data: ReloadColorsList[];
    }
    //endregion

    export interface Expedition {
        reference: string;
        providerName: string;
        providerId: number;
        providerAvelonId: number;
        packingAmount: number;
        deliveryDate: string;
        shipper: string;
        states: number[];
        receptionPallets: number;
        receptionPackings: number;
        receptionStates: number[];
        receptionBlocked: boolean;
        noPendingReceptions: boolean;
        noPendingConfirms: boolean;
        receptionDisabled: boolean;
        disabledReason: string;
    }

    export interface ResponsePrintReceptionLabel {
        products?: Array<ResponsePrintReceptionLabelProducts>;
        hasErrors?: boolean;
        errors?: any;
    }

    export interface ResponsePrintReceptionLabelProducts {
        locationType: string;
        product: {
            id: number;
            reference: string;
            size: {
                name: string;
            };
            manufacturer: {
                name: string;
            };
            model: {
                reference: string;
                name: string;
                color: {
                    name: string;
                };
                season: {
                    name: string;
                };
            };
        };
    }
}
