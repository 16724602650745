import { ExpeditionPackageModel, HttpRequestModel, LabelModel, WarehouseModel } from './';

export namespace ExpeditionModel {

    export interface Expedition {
        hash: string;
        invoicedAt: Date;
        barcode: string;
        deliveryRequestExternalId: string;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        status: ExpeditionStatus;
        market: any;
        marketId: string;
        randomUuid: string;
        notifyCrmAt: Date;
        packages: ExpeditionPackageModel.ExpeditionPackage[];
        elExpeditionOrdersSent: any[];
    }

    export interface ParamsCheckIncidenceForExpedition {
        incidenceCode: string;
    }

    export interface ParamsCheckPackageForExpedition {
        packageCode: string;
    }

    export interface ResponseCheckIncidenceForExpedition extends HttpRequestModel.Response {
        data: boolean;
    }

    export interface ResponseCheckPackageForExpedition extends HttpRequestModel.Response {
        data: IExpeditionInfo;
    }

    export interface ResponseCheckProductsForExpedition extends HttpRequestModel.Response {
        products: IExpeditionInfoPackageProductCheck[];
        hiringInfo: IExpeditionHiringInfo;
    }

    export interface IExpeditionInfo {
        id: number;
        deliveryRequestExternalId: string;
        packages: IExpeditionInfoPackage[];
        hiringInfo?: IExpeditionHiringInfo;
        message?: string;
    }

    export interface IExpeditionInfoPackage {
        id: number;
        products: IExpeditionInfoPackageProduct[];
    }

    export interface IExpeditionInfoPackageProduct {
        barcode: string;
    }

    export interface IExpeditionInfoPackageProductCheck {
        barcode: string;
        status: number;
    }

    export interface IPackage {
        createdAt: string;
        updatedAt: string;
        id: number;
        containerId: number;
        status: number;
        trackingInfo: string;
        note: string;
        shippingHandler: string;
        shippingMode: number;
        shippingServiceAux: string;
        phone: string;
        email: string;
        shippingNote: string;
        shippingService: string;
        deliveryRequestExternalId: string;
        deliveryRequestId: number;
        marketAvelonPlatformId: string;
        externalPlatformId: string;
        expeditionTransport: IInfoTransport;
        shop: any;
    }

    export interface ITransport {
        createdAt: string;
        updatedAt: string;
        id: number;
        name: string;
        reference: number;
        logistic_internal: boolean;
        address: string;
        phone: string;
    }

    export interface IInfoTransport {
        createdAt: string;
        updatedAt: string;
        id: number;
        shippingCompanyName: string;
        shippingAddressee1: string;
        shippingAddressee2: string;
        shippingAddress1: string;
        shippingAddress2: string;
        shippingZipCode: string;
        shippingCity: string;
        shippingProvince: string;
        shippingCountry: string;
        shippingPhone: string;
        invoiceCompanyName: string;
        invoiceAddressee1: string;
        invoiceAddressee2: string;
        invoiceAddress1: string;
        invoiceAddress2: string;
        invoiceZipCode: string;
        invoiceCity: string;
        invoiceProvince: string;
        invoiceCountry: string;
        transport_response: string;
        transport: ITransport;
    }

    export interface IExpedition {
        createdAt: string;
        updatedAt: string;
        id: number;
        hash: string;
        invoiceDateTime: string;
        status: number;
        randomUuid: string;
        packages: IPackage[];
        deliveryRequestExternalId: string;
        warehouse: IWarehouse;
    }

    export interface IMarketInstructionMarket {
        id: number;
        name: string;
    }

    export interface IMarketInstruction {
        market: IMarketInstructionMarket;
        label: string;
    }

    export interface IExpeditionHiringInfo {
        incidence: boolean;
        incidenceCode?: string;
        expedition: IExpedition;
        marketInstruction: IMarketInstruction;
        printInfo: LabelModel.IExpeditionDocToPrint[];
        shippingPackageCodes: string[];
    }

    export interface IExpeditionShippingPackageCode {
        uniqueCode: string;
        checked: boolean;
    }

    export interface IWarehouse {
        id: number;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string;
        name: string;
        description?: string;
        reference?: string;
        isStore: boolean;
        isMain: boolean;
        isDefective: boolean;
        allowsReceptions: boolean;
        thresholdShippingStore: null;
        enabled: boolean;
        companyName?: string;
        address1?: string;
        address2?: string;
        zipCode?: string;
        city?: string;
        province?: string;
        countryId?: string;
    }

}

export enum ExpeditionStatus {
    generatedInAc = 'generatedInAc',
    locked = 'locked',
    unlocked = 'unlocked',
    generatedTag = 'generatedTag',
    labelGenerationError = 'labelGenerationError',
    labelErrorResolved = 'labelErrorResolved',
    pickedUp = 'pickedUp',
    sent = 'sent',
    generatedTagLogisticInternal = 'generatedTagLogisticInternal',
    pickedUpIntern = 'pickedUpIntern',
    toPickInWarehouseAttend = 'toPickInWarehouseAttend',
    relabelling = 'relabelling',
}
