import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthSandbox } from './core/auth/auth.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { AudioProvider } from '../providers/audio-provider.provider';
@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public appPages = [
        { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
        { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
        { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
        { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
        { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
        { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    ];
    public labels = [
        'Family',
        'Friends',
        'Notes',
        'Work',
        'Travel',
        'Reminders',
    ];

    private subs: Subscription[] = [];

    constructor(
        private platform: Platform,
        private authSandbox: AuthSandbox,
        private translate: TranslateService,
        public audioProvider: AudioProvider
    ) {
        this.initApp();

        this.translate.setDefaultLang('es');
        this.translate.use('es');

        // RequestQueryBuilder.setOptions({
        //     delim: "||",
        //     delimStr: ",",
        //     paramNamesMap: {
        //         fields: ["fields", "select"],
        //         search: "s",
        //         filter: ["filter[]", "filter"],
        //         or: ["or[]", "or"],
        //         join: ["join[]", "join"],
        //         sort: ["sort[]", "sort"],
        //         limit: ["per_page", "limit"],
        //         offset: ["offset"],
        //         page: ["page"],
        //         cache: ["cache"]
        //     }
        // });
    }

    async initApp() {
        await this.platform.ready();

        this.audioProvider.preload(
            'incidenceBeep',
            'assets/audio/incidence_beep.mp3'
        );
        this.audioProvider.preload(
            'responseRequestError',
            'assets/audio/response_request_error.wav'
        );
        this.audioProvider.preload(
            'responseRequestOk',
            'assets/audio/response_request_ok.wav'
        );
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
