import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    template: '',
})
export class BaseComponent implements OnDestroy {
    public subs: Subscription[] = [];

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
