import { createReducer, on } from '@ngrx/store';
import { marketplacesRecord } from './state';
import * as actions from './actions';

export const marketplacesReducer = createReducer(
    marketplacesRecord,
    //
    on(actions.getMarketplacesAction, (state, payload) => ({
        ...state,
        getMarketplacesData: null,
        getMarketplacesFailData: null,
        getMarketplacesLoading: true,
    })),
    on(actions.getMarketplacesSuccessAction, (state, { payload }) => ({
        ...state,
        getMarketplacesData: payload,
        getMarketplacesFailData: null,
        getMarketplacesLoading: null,
    })),
    on(actions.getMarketplacesFailAction, (state, payload) => ({
        ...state,
        getMarketplacesData: null,
        getMarketplacesFailData: payload,
        getMarketplacesLoading: null,
    })),
    //
    on(actions.searchProductCatalogAction, (state, payload) => ({
        ...state,
        searchProductCatalogData: null,
        searchProductCatalogFailData: null,
        searchProductCatalogLoading: true,
    })),
    on(actions.searchProductCatalogSuccessAction, (state, payload) => ({
        ...state,
        searchProductCatalogData: payload,
        searchProductCatalogFailData: null,
        searchProductCatalogLoading: false,
    })),
    on(actions.searchProductCatalogFailAction, (state, payload) => ({
        ...state,
        searchProductCatalogData: null,
        searchProductCatalogFailData: payload,
        searchProductCatalogLoading: null,
    })),
    //
    on(actions.searchProductWithVariationsCatalogAction, (state, payload) => ({
        ...state,
        searchProductWithVariationsCatalogData: null,
        searchProductWithVariationsCatalogFailData: null,
        searchProductWithVariationsCatalogLoading: true,
    })),
    on(
        actions.searchProductWithVariationsCatalogSuccessAction,
        (state, payload) => ({
            ...state,
            searchProductWithVariationsCatalogData: payload,
            searchProductWithVariationsCatalogFailData: null,
            searchProductWithVariationsCatalogLoading: false,
        })
    ),
    on(
        actions.searchProductWithVariationsCatalogFailAction,
        (state, payload) => ({
            ...state,
            searchProductWithVariationsCatalogData: null,
            searchProductWithVariationsCatalogFailData: payload,
            searchProductWithVariationsCatalogLoading: false,
        })
    ),
    //
    on(actions.getBrandsAction, (state, payload) => ({
        ...state,
        getBrandsData: null,
        getBrandsFailData: null,
        getBrandsLoading: true,
    })),
    on(actions.getBrandsSuccessAction, (state, { payload }) => ({
        ...state,
        getBrandsData: payload,
        getBrandsFailData: null,
        getBrandsLoading: null,
    })),
    on(actions.getBrandsFailAction, (state, payload) => ({
        ...state,
        getBrandsData: null,
        getBrandsFailData: payload,
        getBrandsLoading: null,
    })),
    //
    on(actions.getCachedMarketByIdAction, (state, payload) => {
        const collection = state.getMarketplacesData || [];
        const item = collection.find(el => +el.id === +payload.id);

        return {
            ...state,
            getCachedMarketById: item,
        };
    }),
    //
    on(actions.getSizesAction, (state, payload) => ({
        ...state,
        getSizesData: null,
        getSizesFailData: null,
        getSizesLoading: true,
    })),
    on(actions.getSizesSuccessAction, (state, { payload }) => ({
        ...state,
        getSizesData: payload,
        getSizesFailData: null,
        getSizesLoading: null,
    })),
    on(actions.getSizesFailAction, (state, payload) => ({
        ...state,
        getSizesData: null,
        getSizesFailData: payload,
        getSizesLoading: null,
    })),
    //
    on(actions.getColorsAction, (state, payload) => ({
        ...state,
        getColorsData: null,
        getColorsFailData: null,
        getColorsLoading: true,
    })),
    on(actions.getColorsSuccessAction, (state, { payload }) => ({
        ...state,
        getColorsData: payload,
        getColorsFailData: null,
        getColorsLoading: null,
    })),
    on(actions.getColorsFailAction, (state, payload) => ({
        ...state,
        getColorsData: null,
        getColorsFailData: payload,
        getColorsLoading: null,
    })),
    //
    on(actions.getFeaturesAction, (state, payload) => ({
        ...state,
        getFeaturesData: null,
        getFeaturesFailData: null,
        getFeaturesLoading: true,
    })),
    on(actions.getFeaturesSuccessAction, (state, { payload }) => ({
        ...state,
        getFeaturesData: payload,
        getFeaturesFailData: null,
        getFeaturesLoading: null,
    })),
    on(actions.getFeaturesFailAction, (state, payload) => ({
        ...state,
        getFeaturesData: null,
        getFeaturesFailData: payload,
        getFeaturesLoading: null,
    })),
    //
    on(actions.getSeasonsAction, (state, payload) => ({
        ...state,
        getSeasonsData: null,
        getSeasonsFailData: null,
        getSeasonsLoading: true,
    })),
    on(actions.getSeasonsSuccessAction, (state, payload) => ({
        ...state,
        getSeasonsData: payload,
        getSeasonsFailData: null,
        getSeasonsLoading: null,
    })),
    on(actions.getSeasonsFailAction, (state, payload) => ({
        ...state,
        getSeasonsData: null,
        getSeasonsFailData: payload,
        getSeasonsLoading: null,
    })),
    //
    on(actions.getMarketBrandsAction, (state, payload) => ({
        ...state,
        getMarketBrandsData: null,
        getMarketBrandsFailData: null,
        getMarketBrandsLoading: true,
    })),
    on(actions.getMarketBrandsSuccessAction, (state, payload) => ({
        ...state,
        getMarketBrandsData: payload,
        getMarketBrandsFailData: null,
        getMarketBrandsLoading: null,
    })),
    on(actions.getMarketBrandsFailAction, (state, payload) => ({
        ...state,
        getMarketBrandsData: null,
        getMarketBrandsFailData: payload,
        getMarketBrandsLoading: null,
    })),
    //
    on(actions.getMarketSizesAction, (state, payload) => ({
        ...state,
        getMarketSizesData: null,
        getMarketSizesFailData: null,
        getMarketSizesLoading: true,
    })),
    on(actions.getMarketSizesSuccessAction, (state, payload) => ({
        ...state,
        getMarketSizesData: payload,
        getMarketSizesFailData: null,
        getMarketSizesLoading: null,
    })),
    on(actions.getMarketSizesFailAction, (state, payload) => ({
        ...state,
        getMarketSizesData: null,
        getMarketSizesFailData: payload,
        getMarketSizesLoading: null,
    })),
    //
    on(actions.getMarketColorsAction, (state, payload) => ({
        ...state,
        getMarketColorsData: null,
        getMarketColorsFailData: null,
        getMarketColorsLoading: true,
    })),
    on(actions.getMarketColorsSuccessAction, (state, payload) => ({
        ...state,
        getMarketColorsData: payload,
        getMarketColorsFailData: null,
        getMarketColorsLoading: null,
    })),
    on(actions.getMarketColorsFailAction, (state, payload) => ({
        ...state,
        getMarketColorsData: null,
        getMarketColorsFailData: payload,
        getMarketColorsLoading: null,
    })),
    //
    on(actions.getMarketFeaturesAction, (state, payload) => ({
        ...state,
        getMarketFeaturesData: null,
        getMarketFeaturesFailData: null,
        getMarketFeaturesLoading: true,
    })),
    on(actions.getMarketFeaturesSuccessAction, (state, payload) => ({
        ...state,
        getMarketFeaturesData: payload,
        getMarketFeaturesFailData: null,
        getMarketFeaturesLoading: null,
    })),
    on(actions.getMarketFeaturesFailAction, (state, payload) => ({
        ...state,
        getMarketFeaturesData: null,
        getMarketFeaturesFailData: payload,
        getMarketFeaturesLoading: null,
    })),
    //
    on(actions.getMarketSeasonsAction, (state, payload) => ({
        ...state,
        getMarketSeasonsData: null,
        getMarketSeasonsFailData: null,
        getMarketSeasonsLoading: true,
    })),
    on(actions.getMarketSeasonsSuccessAction, (state, payload) => ({
        ...state,
        getMarketSeasonsData: payload,
        getMarketSeasonsFailData: null,
        getMarketSeasonsLoading: null,
    })),
    on(actions.getMarketSeasonsFailAction, (state, payload) => ({
        ...state,
        getMarketSeasonsData: null,
        getMarketSeasonsFailData: payload,
        getMarketSeasonsLoading: null,
    })),
    //
    on(actions.getRulesConfigurationsActions, (state, payload) => ({
        ...state,
        getRulesConfigurationsData: null,
        getRulesConfigurationsFailData: null,
        getRulesConfigurationsLoading: true,
    })),
    on(actions.getRulesConfigurationsSuccessAction, (state, { payload }) => ({
        ...state,
        getRulesConfigurationsData: payload,
        getRulesConfigurationsFailData: null,
        getRulesConfigurationsLoading: null,
    })),
    on(actions.getRulesConfigurationsFailAction, (state, payload) => ({
        ...state,
        getRulesConfigurationsData: null,
        getRulesConfigurationsFailData: payload,
        getRulesConfigurationsLoading: null,
    })),
    //
    on(actions.postUpdateZalandoSizeMapWithApiAction, (state, payload) => ({
        ...state,
        postUpdateZalandoSizeMapWithApiData: null,
        postUpdateZalandoSizeMapWithApiFailData: null,
        postUpdateZalandoSizeMapWithApiLoading: true,
    })),
    on(
        actions.postUpdateZalandoSizeMapWithApiSuccessAction,
        (state, payload) => ({
            ...state,
            postUpdateZalandoSizeMapWithApiData: payload,
            postUpdateZalandoSizeMapWithApiFailData: null,
            postUpdateZalandoSizeMapWithApiLoading: null,
        })
    ),
    on(actions.postUpdateZalandoSizeMapWithApiFailAction, (state, payload) => ({
        ...state,
        postUpdateZalandoSizeMapWithApiData: null,
        postUpdateZalandoSizeMapWithApiFailData: payload,
        postUpdateZalandoSizeMapWithApiLoading: null,
    })),
    //
    on(actions.updateRulesConfigurationsAction, (state, payload) => ({
        ...state,
        updateRulesConfigurationsData: null,
        updateRulesConfigurationsFailData: null,
        updateRulesConfigurationsLoading: true,
    })),
    on(actions.updateRulesConfigurationsSuccessAction, (state, payload) => ({
        ...state,
        updateRulesConfigurationsData: payload,
        updateRulesConfigurationsFailData: null,
        updateRulesConfigurationsLoading: null,
    })),
    on(actions.updateRulesConfigurationsFailAction, (state, payload) => ({
        ...state,
        updateRulesConfigurationsData: null,
        updateRulesConfigurationsFailData: payload,
        updateRulesConfigurationsLoading: null,
    })),
    //
    on(actions.updateCategoriesMinPricesAction, (state, payload) => ({
        ...state,
        updateCategoriesMinPricesData: null,
        updateCategoriesMinPricesFailData: null,
        updateCategoriesMinPricesLoading: true,
    })),
    on(actions.updateCategoriesMinPricesSuccessAction, (state, payload) => ({
        ...state,
        updateCategoriesMinPricesData: payload,
        updateCategoriesMinPricesFailData: null,
        updateCategoriesMinPricesLoading: null,
    })),
    on(actions.updateCategoriesMinPricesFailAction, (state, payload) => ({
        ...state,
        updateCategoriesMinPricesData: null,
        updateCategoriesMinPricesFailData: payload,
        updateCategoriesMinPricesLoading: null,
    }))
);
