import { productsMenu } from './menu.products.data';
import { pickingStoreMenu } from './menu.picking-store.data';
import { returnsPendingMenu } from './menu.return-pending.data';
import { logoutMenu } from './menu.logout.data';
import { clientsMenu } from './menu.clients.data';
import { regularizationsMenu } from './menu.regularizations.data';
import { configMenu } from './menu.config.data';
import { expositionControl } from './menu.exposition-control.data';
import { factoryReceptionMenu } from './menu.factory-reception.data';
import { defectivesMenu } from './menu.defective.data';
import { dropFileMenu } from './menu.drop-file.data';
import { logisticsMenu } from './menu.logistic.menu.data';
import { marketplacesMenu } from './menu.marketplaces.data';
import { onlineOrdersMenu } from './menu.online-orders.data';
import { packingControlMenu } from './menu.packing-control.data';
import { pickingVentilationMenu } from './menu.picking-ventilation.data';
import { refundsMenu } from './menu.refunds.data';
import { regionsMenu } from './menu.regions.data';
import { returnsMenu } from './menu.returns.data';
import { workwavesMenu } from './menu.workwave.data';
import { userManagementMenu } from './menu.user-management.data';
import { tariffMenu } from './menu.tariff.data';
import { sorterMenu } from './menu.sorter.data';

export type MenuItemList = (MenuSectionGroupItem | MenuSectionItem)[];

export interface MenuSectionGroupItem {
    title: string;
    open: boolean;
    type: 'wrapper';
    children: (MenuSectionGroupItem | MenuSectionItem)[];
    thirdLevel?: boolean;
    tooltip?: string;
    amount?: number;
    id?: string;
    isMobile?: boolean;
    isWeb?: boolean;
}

export interface MenuSectionItem {
    title: string;
    id?: string;
    url: string;
    icon: string;
    notification?: boolean;
    children?: (MenuSectionGroupItem | MenuSectionItem)[];
    header?: boolean;
    tooltip?: string;
    amount?: number;
    permissions?: string[];
    isMobile?: boolean;
    isWeb?: boolean;
}

export const menuSGA = [
    ...productsMenu,
    // TODO: Uncomment when ready
    // ...regularizationsMenu,
    ...logisticsMenu,
    ...workwavesMenu,
    ...userManagementMenu,
    // TODO: Uncomment when ready
    // ...expositionControl,
    // TODO: Uncomment when ready
    // ...defectivesMenu,
    // TODO: Uncomment when ready
    //...returnsMenu,
    ...pickingStoreMenu,
    // TODO: Uncomment when ready
    // ...returnsPendingMenu,
    // TODO: Uncomment when ready
    // ...pickingVentilationMenu,
    ...packingControlMenu,
    ...configMenu,
    ...tariffMenu,
    // TODO: Uncomment when ready
    // ...sorterMenu,
    ...factoryReceptionMenu,
    // ...regionsMenu,
    // ...dropFileMenu,
    ...marketplacesMenu,
    ...onlineOrdersMenu,
    // ...clientsMenu,
    // ...refundsMenu,
    ...logoutMenu,
];
