import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { WaitForDataGuard } from './wait-for-data.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/home/home.module').then(
                        m => m.HomeModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/user/user.module').then(
                        m => m.UserModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'folder/:id',
                loadChildren: () =>
                    import('./folder/folder.module').then(
                        m => m.FolderPageModule
                    ),
            },
            // {
            //     path: '',
            //     loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
            // },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/logistics/logistics.module').then(
                        m => m.LogisticModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/workwaves/workwaves.module').then(
                        m => m.WorkWaveModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/user-administer/user-administer.module').then(
                        m => m.UserAdministerModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/exposition-control/exposition-control.module').then(
                        m => m.ExpositionControlModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/defectives/defectives.module').then(
                        m => m.DefectivesModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/factory-returns/factory-returns.module').then(
                        m => m.FactoryReturnsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/store-picking/store-picking.module').then(
                        m => m.StorePickingModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/configuration/configuration.module').then(
                        m => m.ConfigurationModule
                    ),
            },
            {
                path: '',
                canActivate: [AuthGuard],
                data: {
                    permissions: ['tariff'],
                },
                loadChildren: () =>
                    import('./modules/tariffs/tariffs.module').then(
                        m => m.TariffsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/sorter/sorter.module').then(
                        m => m.SorterModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/factory-reception/factory-reception.module').then(
                        m => m.FactoryReceptionModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/regions/regions.module').then(
                        m => m.RegionsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/drop-files/drop-files.module').then(
                        m => m.DropFilesModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/marketplaces/marketplaces.module').then(
                        m => m.MerketplaceModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/online-orders/online-orders.module').then(
                        m => m.OnlineOrdersModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/clients/clients.module').then(
                        m => m.ClientsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/refunds/refunds.module').then(
                        m => m.RefundsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/manual-picking/manual-picking.module').then(
                        m => m.ManualPickingModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/auth/auth.module').then(
                        m => m.AuthModule
                    ),
            },
        ],
    },
    {
        path: '**',
        canActivate: [WaitForDataGuard],
        component: NotFoundComponent,
    },
    /*{
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/user/user.module').then(
                        m => m.UserModule
                    ),
            },
        ],
    },*/
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
    declarations: [NotFoundComponent],
})
export class AppRoutingModule {}
