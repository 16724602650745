import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-tiendas-selection',
    templateUrl: './tiendas-selection.component.html',
    styleUrls: ['./tiendas-selection.component.css'],
})
export class TiendasSelectionComponent implements OnInit {
    data = [1, 1, 1, 1, 1, 1, 1, 1];

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close(): void {
        this.modalCtrl.dismiss();
    }

    submit(): void {
        this.modalCtrl.dismiss(1);
    }
}
