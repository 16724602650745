import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NavParams, Platform, PopoverController } from '@ionic/angular';
import Keyboard from 'simple-keyboard';
import layout from 'simple-keyboard-layouts/build/layouts/spanish';
import { KeyboardFilteringService } from '../../core/keyboard-filtering/keyboard-filtering.service';
import { Type } from '../../modules/factory-reception/receptions-avelon/enums/type.enum';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MgaService } from '../../core/mga.service';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Component({
    selector: 'app-virtual-keyboard',
    templateUrl: './virtual-keyboard.component.html',
    styleUrls: ['./virtual-keyboard.component.scss'],
})
export class VirtualKeyboardComponent implements OnInit, AfterViewInit {
    value = '';
    keyboard: Keyboard;
    searchTerm = '';
    items: any;
    type: number;
    @Output() eventOnKeyPress = new EventEmitter<any>();
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
    @ViewChild('scrollViewport') scrollViewport: CdkVirtualScrollViewport;

    layout_type: 'qwerty' | 'number';
    placeholder = 'Ingrese el texto';
    initialValue: string = null;

    result = {
        keyPress: '',
        selected: null,
    };
    page = 1;
    resetScroll = false;
    pageCount = 0;

    constructor(
        private keyboardFilteringService: KeyboardFilteringService,
        private navParams: NavParams,
        private popoverController: PopoverController,
        private platform: Platform,
        private renderer: Renderer2,
        private el: ElementRef,
        public service: MgaService
    ) {
        const items = this.navParams.get('data');
        this.type = this.navParams.get('type');
        this.keyboardFilteringService.setItems(items);
    }

    ngAfterViewInit() {
        const keyboardConfiguration: any = {
            onChange: input => this.onChange(input),
            onKeyPress: button => this.onKeyPress(button),
        };

        if (this.layout_type == 'qwerty') {
            keyboardConfiguration.layout = layout;
        } else {
            keyboardConfiguration.layout = {
                default: [
                    '7 8 9',
                    '4 5 6',
                    '1 2 3',
                    '{down_hide} 0 {backspace}',
                ],
            };

            keyboardConfiguration.display = {
                '{down_hide}': '▼',
                '{backspace}': '◄',
            };
            keyboardConfiguration.theme =
                'hg-theme-default hg-layout-numeric numeric-theme';
            keyboardConfiguration.mergeDisplay = true;
        }

        this.keyboard = new Keyboard(keyboardConfiguration);

        if (
            this.initialValue &&
            this.initialValue != '' &&
            this.initialValue != '0'
        ) {
            this.searchTerm = this.initialValue;
            this.result.keyPress = this.initialValue;
            this.keyboard.setInput(this.initialValue);
        }
    }

    setFilteredItems() {
        this.items = this.keyboardFilteringService.filterItems(this.searchTerm);
        if (this.type == Type.PROVIDER) {
            // Reset the scroll when the search input  interacts. Has an if condition to not execute the first time.
            this.resetScroll
                ? this.viewport.scrollToIndex(0)
                : (this.resetScroll = true);
            this.page = 1;

            const filterParams = this.searchTerm
                ? RequestQueryBuilder.create({
                      filter: {
                          field: 'name',
                          operator: '$cont',
                          value: this.searchTerm,
                      },
                  }).query(false)
                : '';
            this.service.api.dictionary.providers
                .list('?' + filterParams)
                .subscribe(res => {
                    this.pageCount = res.pageCount;
                    const dataItems = res.data.map(p => ({
                        id: p.id,
                        value: p.name,
                    }));
                    this.items = dataItems;
                });
        }
    }

    onChange = (input: string) => {
        this.searchTerm = input;
        this.result.keyPress = input;
        this.eventOnKeyPress.emit(this.result);
    };

    onKeyPress = (button: string) => {
        if (button === '{shift}' || button === '{lock}') {
            this.handleShift();
        }
        if (
            button === '{enter}' &&
            (!this.type ||
                this.type == Type.EXPEDITION_NUMBER ||
                this.type == Type.EAN_CODE ||
                this.type == Type.DELIVERY_NOTE)
        ) {
            this.selectItem(this.searchTerm);
        }
        if (button === '{down_hide}') {
            this.popoverController.dismiss();
        }
    };

    handleShift = () => {
        const currentLayout = this.keyboard.options.layoutName;
        const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

        this.keyboard.setOptions({
            layoutName: shiftToggle,
        });
    };

    ngOnInit(): void {
        if (this.platform.is('mobile')) {
            const simpleKeyboard =
                this.el.nativeElement.querySelector('.simple-keyboard');
            this.renderer.removeChild(this.el.nativeElement, simpleKeyboard);
        }
        this.setFilteredItems();
    }

    itemClick(item) {
        if (this.type == 5) {
            this.selectItem(item.id);
        } else if (this.type == Type.MODEL) {
            this.selectItem(item.ids);
        } else {
            this.selectItem(item.id);
        }
    }

    async selectItem(id: any) {
        this.result.selected = { id, type: this.type };
        this.eventOnKeyPress.emit(this.result);
        await this.popoverController.dismiss();
    }

    onScrolledIndexChange(event) {
        // If there are no more pages to be collected, do not execute the code.
        if (this.page <= this.pageCount) {
            if (this.type == Type.PROVIDER) {
                // The event does not count the first 6 items in the list. So, we sum them
                const firstItems = 6;
                event = event + firstItems;
                // The last item is subtracted 3 positions so that it enters earlier in the if and the elements are updated faster.
                const lastIndex =
                    this.scrollViewport?.getRenderedRange()?.end - 3;
                if (event === lastIndex) {
                    this.page = this.page + 1;
                    let filterParams;
                    if (this.searchTerm) {
                        filterParams = RequestQueryBuilder.create({
                            page: this.page,
                            filter: {
                                field: 'name',
                                operator: '$cont',
                                value: this.searchTerm,
                            },
                        }).query(false);
                    } else {
                        filterParams = RequestQueryBuilder.create({
                            page: this.page,
                        }).query(false);
                    }

                    this.service.api.dictionary.providers
                        .list('?' + filterParams)
                        .subscribe(res => {
                            const dataItems = res.data.map(p => ({
                                id: p.id,
                                value: p.name,
                            }));
                            this.items = this.items.concat(dataItems);
                        });
                }
            }
        }
    }
}
