import { ContainerModel, OrderPackageModel, PackingModel, PackingType, ProductModel, RackModel, SorterControlProductModel, UserModel, WarehouseModel } from './';

export namespace InventoryModel {

    export interface Inventory {
        id: number;
        createdAt: string;
        updatedAt: string;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        rack: RackModel.Rack;
        rackId: number;
        container: ContainerModel.Container;
        containerId: number;
        packing: PackingModel.Packing;
        packingId: number;
        packingType: PackingType;
        contentType: ContentType;
        package: OrderPackageModel.OrderPackage;
        packageId: number;
        product: ProductModel.Product;
        productId: number;
        inventoryProcess: InventoryProcess;
        inventoryProcessId: number;
        status: InventoryStatus;
        user: UserModel.User;
        userId: number;
        availabilityStatus: AvailabilityStatus;
        locationType: LocationType;
        relabel: boolean;
        productRelabeled: any;
        deliveryRequestExternalId: string;
        movementsOutsideMainWarehouse: boolean;
        shoePickings: any[];
        tempCalcShoePickings: any[];
        sorterControlProduct?: SorterControlProductModel.SorterControlProduct;
    }

    export interface InventoryProcess {
        actionType: string;
        dispatched: boolean;
        originWarehouse: WarehouseModel.Warehouse;
        originWarehouseId: number;
        originRack: RackModel.Rack;
        originRackId: number;
        originContainer: ContainerModel.Container;
        originContainerId: number;
        originPacking: PackingModel.Packing;
        originPackingId: number;
        originPackingType: string;
        destinationWarehouse: WarehouseModel.Warehouse;
        destinationWarehouseId: number;
        destinationRack: RackModel.Rack;
        destinationRackId: number;
        destinationContainer: ContainerModel.Container;
        destinationContainerId: number;
        destinationPacking: PackingModel.Packing;
        destinationPackingId: number;
        destinationPackingType: string;
        workwaveOrder: any;
        workwaveOrderId: number;
        status: string;
        user: any;
        userId: number;
        package: any;
        packageId: number;
        product: ProductModel.Product;
        productId: number;
        oldProductReference: string;
        locationType: string;
        deliveryRequestExternalId: string;
    }

    export interface MoveProductParameters {
        isForce?: boolean;
        isLocateOutRack?: boolean;
        isRollback?: boolean;
        isVentilationProcess?: boolean;
        isVentilationProcessFromLocalSorter?: boolean;
        mustAvoidAvelonMovement?: boolean;
        mustForceAvelonMovement?: boolean;
        noOnline?: boolean;
        app?: string;
        containerReference?: string;
        packingReference?: string;
        warehouseReference: string;
        productReference: string;
    }

    export interface MoveProductResponse {
        resultStatus: string;
        inventoryProcess: InventoryProcess;
        modelSyncTask: ITask;
        inventory: Inventory;
    }

    export interface ITask {
        queueName: string;
        taskName: string;
        status: string;
        argsId: number;
        errorId: number;
        keepUntil: Date;
        startedAt: Date;
        doneAt: Date;
        artifacts: any[];
    }

}

export enum ContentType {
    product = 'product',
    orderPackageLogIntern = 'orderPackageLogIntern',
}

export enum AvailabilityStatus {
    available = 'available',
    firstWarning = 'firstWarning',
    secondWarning = 'secondWarning',
}

export enum InventoryStatus {
    stockFree = 'stockFree',
    stockPreassigned = 'stockPreassigned',
    stockAssigned = 'stockAssigned',
    stockPreventilated = 'stockPreventilated',
    calculation = 'calculation',
    incidence = 'incidence',
    calculationTemp = 'calculationTemp',
    stockPreassignedTemp = 'stockPreassignedTemp',
    stockPreassignedTempDirect = 'stockPreassignedTempDirect',
    stockPreassignedTempOt = 'stockPreassignedTempOt',
    calculationTempOt = 'calculationTempOt',
    stockPreverified = 'stockPreverified',
    stockDefective = 'stockDefective',
    stockUnfitOnline = 'stockUnfitOnline',
    stockVerified = 'stockVerified',
    stockPreverifiedTempOt = 'stockPreverifiedTempOt',
    stockBlocked = 'stockBlocked',
    stockOplIntern = 'stockOplIntern',
    associatedToOrder = 'associatedToOrder',
    factoryReturn = 'factoryReturn',
    preparedReturn = 'preparedReturn',
    locationCancelled = 'locationCancelled',
}

export enum LocationType {
    positioned = 'positioned',
    carried = 'carried',
    sorter = 'sorter',
    predistribution = 'predistribution',
    reception = 'reception',
    sorterRack = 'sorterRack',
    removedFromPacking = 'removedFromPacking',
    withoutPosition = 'withoutPosition',
    receptionSorter = 'receptionSorter',
    receptionWarehouse = 'receptionWarehouse',
    workbench = 'workbench',
    oplIntern = 'oplIntern',
    factoryReturn = 'factoryReturn',
    locationCancelled = 'locationCancelled',
}
