import { Subject } from "rxjs";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PositioningManualProvider {

  private _isActive: Subject<boolean> = new Subject();

  get isActive(): Subject<boolean> {
    return this._isActive;
  }

}
