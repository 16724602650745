import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonSandbox } from 'src/app/core/common/common.sandbox';

@Component({
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
    public appPages = [
        { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
        { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
        { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
        { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
        { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
        { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    ];
    public labels = [
        'Family',
        'Friends',
        'Notes',
        'Work',
        'Travel',
        'Reminders',
    ];

    small = false;

    constructor(private commonSandbox: CommonSandbox) {}

    ngOnInit(): void {
        this.commonSandbox.sidebarToggle$.subscribe(res => {
            this.small = !this.small;
        });
    }
}
