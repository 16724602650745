import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSelect from './selector';
import * as actions from './actions';

@Injectable({
    providedIn: 'root',
})
export class MarketplacesSandbox {
    //
    public getMarketplacesData$ = this.store.select(
        fromSelect.getMarketplacesData
    );
    public getMarketplacesFailData$ = this.store.select(
        fromSelect.getMarketplacesFailData
    );
    public getMarketplacesLoading$ = this.store.select(
        fromSelect.getMarketplacesLoading
    );
    //
    public searchProductCatalogData$ = this.store.select(
        fromSelect.searchProductCatalogData
    );
    public searchProductCatalogFailData$ = this.store.select(
        fromSelect.searchProductCatalogFailData
    );
    public searchProductCatalogLoading$ = this.store.select(
        fromSelect.searchProductCatalogLoading
    );
    //
    public searchProductWithVariationsCatalogData$ = this.store.select(
        fromSelect.searchProductWithVariationsCatalogData
    );
    public searchProductWithVariationsCatalogFailData$ = this.store.select(
        fromSelect.searchProductWithVariationsCatalogFailData
    );
    public searchProductWithVariationsCatalogLoading$ = this.store.select(
        fromSelect.searchProductWithVariationsCatalogLoading
    );
    //
    public getBrandsData$ = this.store.select(fromSelect.getBrandsData);
    public getBrandsFailData$ = this.store.select(fromSelect.getBrandsFailData);
    public getBrandsLoading$ = this.store.select(fromSelect.getBrandsLoading);
    //
    public getCachedMarketById$ = this.store.select(
        fromSelect.getCachedMarketById
    );
    //
    public getSizesData$ = this.store.select(fromSelect.getSizesData);
    public getSizesFailData$ = this.store.select(fromSelect.getSizesFailData);
    public getSizesLoading$ = this.store.select(fromSelect.getSizesLoading);
    //
    public getColorsData$ = this.store.select(fromSelect.getColorsData);
    public getColorsFailData$ = this.store.select(fromSelect.getColorsFailData);
    public getColorsLoading$ = this.store.select(fromSelect.getColorsLoading);
    //
    public getFeaturesData$ = this.store.select(fromSelect.getFeaturesData);
    public getFeaturesFailData$ = this.store.select(
        fromSelect.getFeaturesFailData
    );
    public getFeaturesLoading$ = this.store.select(
        fromSelect.getFeaturesLoading
    );
    //
    public getSeasonsData$ = this.store.select(fromSelect.getSeasonsData);
    public getSeasonsFailData$ = this.store.select(
        fromSelect.getSeasonsFailData
    );
    public getSeasonsLoading$ = this.store.select(fromSelect.getSeasonsLoading);
    //
    public getMarketBrandsData$ = this.store.select(
        fromSelect.getMarketBrandsData
    );
    public getMarketBrandsFailData$ = this.store.select(
        fromSelect.getMarketBrandsFailData
    );
    public getMarketBrandsLoading$ = this.store.select(
        fromSelect.getMarketBrandsLoading
    );
    //
    public getMarketSizesData$ = this.store.select(
        fromSelect.getMarketSizesData
    );
    public getMarketSizesFailData$ = this.store.select(
        fromSelect.getMarketSizesFailData
    );
    public getMarketSizesLoading$ = this.store.select(
        fromSelect.getMarketSizesLoading
    );
    //
    public getMarketColorsData$ = this.store.select(
        fromSelect.getMarketColorsData
    );
    public getMarketColorsFailData$ = this.store.select(
        fromSelect.getMarketColorsFailData
    );
    public getMarketColorsLoading$ = this.store.select(
        fromSelect.getMarketColorsLoading
    );
    //
    public getMarketFeaturesData$ = this.store.select(
        fromSelect.getMarketFeaturesData
    );
    public getMarketFeaturesFailData$ = this.store.select(
        fromSelect.getMarketFeaturesFailData
    );
    public getMarketFeaturesLoading$ = this.store.select(
        fromSelect.getMarketFeaturesLoading
    );
    //
    public getMarketSeasonsData$ = this.store.select(
        fromSelect.getMarketSeasonsData
    );
    public getMarketSeasonsFailData$ = this.store.select(
        fromSelect.getMarketSeasonsFailData
    );
    public getMarketSeasonsLoading$ = this.store.select(
        fromSelect.getMarketSeasonsLoading
    );
    //
    public getRulesConfigurationsData$ = this.store.select(
        fromSelect.getRulesConfigurationsData
    );
    public getRulesConfigurationsFailData$ = this.store.select(
        fromSelect.getRulesConfigurationsFailData
    );
    public getRulesConfigurationsLoading$ = this.store.select(
        fromSelect.getRulesConfigurationsLoading
    );
    //
    public postUpdateZalandoSizeMapWithApiData$ = this.store.select(
        fromSelect.postUpdateZalandoSizeMapWithApiData
    );
    //
    public updateRulesConfigurationsData$ = this.store.select(
        fromSelect.updateRulesConfigurationsData
    );

    constructor(private store: Store) {}

    doGetMarketplaces(): void {
        this.store.dispatch(actions.getMarketplacesAction({}));
    }

    doSearchProductCatalog(params: any): void {
        this.store.dispatch(actions.searchProductCatalogAction({ params }));
    }

    doSearchProductWithVariationsCatalog(params: any): void {
        this.store.dispatch(
            actions.searchProductWithVariationsCatalogAction({ params })
        );
    }

    doGetBrands(): void {
        this.store.dispatch(actions.getBrandsAction({}));
    }

    doGetCachedMarketByIdAction(id: number): void {
        this.store.dispatch(actions.getCachedMarketByIdAction({ id }));
    }

    doGetSizes(): void {
        this.store.dispatch(actions.getSizesAction({}));
    }

    doGetColors(): void {
        this.store.dispatch(actions.getColorsAction({}));
    }

    doGetFeatures(): void {
        this.store.dispatch(actions.getFeaturesAction({}));
    }

    doGetSeasons(): void {
        this.store.dispatch(actions.getSeasonsAction({}));
    }

    doGetMarketBrands(market: string) {
        this.store.dispatch(actions.getMarketBrandsAction({ market }));
    }

    doGetMarketSizes(market: string) {
        this.store.dispatch(actions.getMarketSizesAction({ market }));
    }

    doGetMarketColors(market: string) {
        this.store.dispatch(actions.getMarketColorsAction({ market }));
    }

    doGetMarketFeatures(market: string) {
        this.store.dispatch(actions.getMarketFeaturesAction({ market }));
    }

    doGetMarketSeasons(market: string) {
        this.store.dispatch(actions.getMarketSeasonsAction({ market }));
    }

    doGetRulesConfigurations(market: string) {
        this.store.dispatch(actions.getRulesConfigurationsActions({ market }));
    }

    doPostUpdateZalandoSizeMapWithApi() {
        this.store.dispatch(actions.postUpdateZalandoSizeMapWithApiAction({}));
    }

    doUpdateRulesConfigurationsData(id, params) {
        this.store.dispatch(
            actions.updateRulesConfigurationsAction({ id, params })
        );
    }
}
