import { WarehouseModel } from './warehouse.model';
import { ExpeditionModel } from './expedition.model';

export namespace ExpeditionPackageModel {

    export interface ExpeditionPackage {
        containerId: number;
        expedition: ExpeditionModel.Expedition;
        expeditionId: number;
        expeditionTransport: any;
        expeditionTransportId: number;
        status: ExpeditionPackageStatus;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        trackingPackage: any;
        trackingPackageId: number;
        trackingInfo: string;
        note: string;
        shippingHandler: string;
        shippingMode: number;
        shippingServiceAux: string;
        phone: string;
        email: string;
        shippingNote: string;
        shippingService: string;
        products: any[];
        deliveryRequestExternalId: string;
        deliveryRequestId: number;
        marketAvelonPlatformId: string;
        externalPlatformId: number;
        contractingDateTracking: Date;
    }

    export interface GetExpeditionsPackagesParameters {
        isAl?: boolean;
        transportId: number;
        warehouseIds?: number[];
        containerIds?: number[];
        uniqueCodes?: string[];
        deliveryRequestExternalIds?: string[];
        dates?: string[];
        pagination?: {
            page: number;
            limit: number;
        };
        orderBy?: {
            field: ExpeditionPackageOrderType;
            order: 'ASC' | 'DESC';
        };
    }

}

export enum ExpeditionPackageOrderType {
    deliveryRequestExternalId = 'deliveryRequestExternalId',
    uniqueCode = 'uniqueCode',
    warehouseName = 'warehouseName',
    containerId = 'containerId',
    date = 'date',
}

export enum ExpeditionPackageStatus {
    pendingCollected = 'pendingCollected',
    collected = 'collected',
    sent = 'sent',
    cancelled = 'cancelled',
    returned = 'returned',
    partiallyCancelled = 'partiallyCancelled',
    partiallyReturned = 'partiallyReturned',
}
