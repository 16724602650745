import { Todo } from './todos.model';

export interface TodoState {
    todos: Todo[];
    error: string;
    status: 'pending' | 'loading' | 'error' | 'success';
}

export const todoState: TodoState = {
    todos: [],
    error: null,
    status: 'pending',
};
