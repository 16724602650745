import { Directive, OnInit, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[scrollbar]',
})
export class ScrollbarDirective implements OnInit {
    @Input() scrollbar: string;
    @Input() allowAllScreens: boolean | string;

    hostElement: HTMLElement;

    constructor(public elementRef: ElementRef) {}

    ngOnInit() {
        this.hostElement = this.elementRef.nativeElement;
        if (
            this.hostElement &&
            this.hostElement.tagName &&
            this.hostElement.tagName === 'ION-CONTENT'
        ) {
            const el = document.createElement('style');
            el.innerText = this.scrollbar || this.getCustomStyle();
            this.hostElement.shadowRoot.appendChild(el);
        }
    }

    getCustomStyle() {
        if (this.allowAllScreens === true || this.allowAllScreens === 'true') {
            return `::-webkit-scrollbar {
                    width: 6px !important;
                }
                ::-webkit-scrollbar-track {
                    background: white;
                    //border-radius: 9px!important;
                    //border: 1px solid #707070;
                    //width: 14px
                }
                ::-webkit-scrollbar-thumb {
                    background: #D8D8D8;
                    //border-radius: 9px;
                    //border: 1px solid white;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background: #707070;
                }
                // ::-webkit-scrollbar-track:hover {
                //   background: #f7f7f7;
                // }

                // .inner-scroll {
                //   scrollbar-width: thin
                // }
            `;
        } else {
            return `@media(pointer: fine) {
                ::-webkit-scrollbar {
                    width: 1rem !important;
                }
                ::-webkit-scrollbar-track {
                    background: #23232e;
                }
                ::-webkit-scrollbar-thumb {
                    background: #df49a6;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background: #ff7eef;
                }
                // ::-webkit-scrollbar-track:hover {
                //   background: #f7f7f7;
                // }
                // .inner-scroll {
                //   scrollbar-width: thin
                // }
            }`;
        }
    }
}
