import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { AuthState } from './auth.state';

const currentState = (state: AppState) => state.auth;
//
export const loginLoading = createSelector(
    currentState,
    (state: AuthState) => state.loginLoading
);
export const loginData = createSelector(
    currentState,
    (state: AuthState) => state.loginData
);
export const loginFailData = createSelector(
    currentState,
    (state: AuthState) => state.loginFailData
);
//
export const getProfileData = createSelector(
    currentState,
    (state: AuthState) => state.getProfileData
);
export const getProfileFailData = createSelector(
    currentState,
    (state: AuthState) => state.getProfileFailData
);
export const getProfileLoading = createSelector(
    currentState,
    (state: AuthState) => state.getProfileLoading
);
