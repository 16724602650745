/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarketplacesService } from './service';
import * as actions from './actions';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MarketplacesEffect {
    constructor(
        private actions$: Actions,
        private service: MarketplacesService
    ) {}

    getMarketplaces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketplacesAction),
            switchMap(() => {
                return this.service.getMarketplaces().pipe(
                    map(data =>
                        actions.getMarketplacesSuccessAction({ payload: data })
                    ),
                    catchError(err =>
                        of(actions.getMarketplacesFailAction(err))
                    )
                );
            })
        )
    );

    searchProductCatalog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.searchProductCatalogAction),
            switchMap(state => {
                return this.service.searchProductCatalog(state.params).pipe(
                    map(data => {
                        return actions.searchProductCatalogSuccessAction(data);
                    }),
                    catchError(err =>
                        of(actions.searchProductCatalogFailAction(err))
                    )
                );
            })
        )
    );

    searchProductWithVariationsCatalog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.searchProductWithVariationsCatalogAction),
            switchMap(state => {
                return this.service
                    .searchProductWithVariationsCatalog(state.params)
                    .pipe(
                        map(data =>
                            actions.searchProductWithVariationsCatalogSuccessAction(
                                data
                            )
                        ),
                        catchError(err =>
                            of(
                                actions.searchProductWithVariationsCatalogFailAction(
                                    err
                                )
                            )
                        )
                    );
            })
        )
    );
    getBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getBrandsAction),
            switchMap(state => {
                return this.service.getBrands().pipe(
                    map(data =>
                        actions.getBrandsSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getBrandsFailAction(err)))
                );
            })
        )
    );
    getSizes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSizesAction),
            switchMap(state => {
                return this.service.getSizes().pipe(
                    map(data =>
                        actions.getSizesSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getSizesFailAction(err)))
                );
            })
        )
    );
    getColors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getColorsAction),
            switchMap(state => {
                return this.service.getColors().pipe(
                    map(data =>
                        actions.getColorsSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getColorsFailAction(err)))
                );
            })
        )
    );
    getFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getFeaturesAction),
            switchMap(state => {
                return this.service.getFeatures().pipe(
                    map(data =>
                        actions.getFeaturesSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getFeaturesFailAction(err)))
                );
            })
        )
    );
    getSeasons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSeasonsAction),
            switchMap(state => {
                return this.service.getSeasons().pipe(
                    map(data =>
                        actions.getSeasonsSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getSeasonsFailAction(err)))
                );
            })
        )
    );
    getMarketBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketBrandsAction),
            switchMap(state => {
                return this.service.getMarketBrands(state.market).pipe(
                    map(data =>
                        actions.getMarketBrandsSuccessAction({ payload: data })
                    ),
                    catchError(err =>
                        of(actions.getMarketBrandsFailAction(err))
                    )
                );
            })
        )
    );
    getMarketSizes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketSizesAction),
            switchMap(state => {
                return this.service.getMarketSizes(state.market).pipe(
                    map(data =>
                        actions.getMarketSizesSuccessAction({ payload: data })
                    ),
                    catchError(err => of(actions.getMarketSizesFailAction(err)))
                );
            })
        )
    );
    getMarketColors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketColorsAction),
            switchMap(state => {
                return this.service.getMarketColors(state.market).pipe(
                    map(data =>
                        actions.getMarketColorsSuccessAction({ payload: data })
                    ),
                    catchError(err =>
                        of(actions.getMarketColorsFailAction(err))
                    )
                );
            })
        )
    );
    getMarketFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketFeaturesAction),
            switchMap(state => {
                return this.service.getMarketFeatures(state.market).pipe(
                    map(data =>
                        actions.getMarketFeaturesSuccessAction({
                            payload: data,
                        })
                    ),
                    catchError(err =>
                        of(actions.getMarketFeaturesFailAction(err))
                    )
                );
            })
        )
    );
    getMarketSeasons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMarketSeasonsAction),
            switchMap(state => {
                return this.service.getMarketSeasons(state.market).pipe(
                    map(data =>
                        actions.getMarketSeasonsSuccessAction({ payload: data })
                    ),
                    catchError(err =>
                        of(actions.getMarketSeasonsFailAction(err))
                    )
                );
            })
        )
    );
    getRulesConfigurations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRulesConfigurationsActions),
            switchMap(state => {
                return this.service.getRulesConfigurations(state.market).pipe(
                    map(data =>
                        actions.getRulesConfigurationsSuccessAction({
                            payload: data,
                        })
                    ),
                    catchError(err =>
                        of(actions.getRulesConfigurationsFailAction(err))
                    )
                );
            })
        )
    );
    postUpdateZalandoSizeMapWithApi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.postUpdateZalandoSizeMapWithApiAction),
            switchMap(state => {
                return this.service.postUpdateZalandoSizeMapWithApi().pipe(
                    map(data =>
                        actions.postUpdateZalandoSizeMapWithApiSuccessAction({
                            payload: data,
                        })
                    ),
                    catchError(err =>
                        of(
                            actions.postUpdateZalandoSizeMapWithApiFailAction(
                                err
                            )
                        )
                    )
                );
            })
        )
    );
    updateRulesConfigurations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateRulesConfigurationsAction),
            switchMap(state => {
                return this.service
                    .updateRulesConfigurations(state.id, state.params)
                    .pipe(
                        map(data =>
                            actions.updateRulesConfigurationsSuccessAction({
                                payload: data,
                            })
                        ),
                        catchError(err =>
                            of(actions.updateRulesConfigurationsFailAction(err))
                        )
                    );
            })
        )
    );
    updateCategoriesMinPrices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateCategoriesMinPricesAction),
            switchMap(state => {
                return this.service
                    .updateCategoriesMinPrices(state.params)
                    .pipe(
                        map(data =>
                            actions.updateCategoriesMinPricesSuccessAction({
                                payload: data,
                            })
                        ),
                        catchError(err =>
                            of(actions.updateCategoriesMinPricesFailAction(err))
                        )
                    );
            })
        )
    );
}
