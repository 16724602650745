import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileCheckService {
  private isMobile: boolean;

  constructor() { }

  checkIsMobile() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.match(/android/i) || userAgent.match(/iphone|ipad|ipod/i)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    return this.isMobile;
  }
}
