import { ContainerModel, WarehouseModel } from './';

export namespace RackModel {

    export interface Rack {
        id: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        idx: number;
        enabled: boolean;
        rows: number;
        columns: number;
        containers: ContainerModel.Container[];
    }

    export interface ResponseModel {
        count: number;
        data: any;
        page: number;
        pageCount: number;
        total: number;
    }

    export interface ItemModel {
        columns: number;
        createdAt: string;
    }

}
