import { BehaviorSubject, Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';

import { WarehouseModel } from '../models';
import { LocalStorageProvider } from '../../providers';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    authenticationState = new BehaviorSubject(false);
    dictionaryAccessState = new BehaviorSubject(null);

    constructor(private localStorageProvider: LocalStorageProvider) {
        this.getDictionaryAccess();
    }

    /**
     * Get the last successful login username
     *
     * @returns the last username logged from this terminal
     */
    getUsername(): Observable<any> {
        return from(
            this.localStorageProvider.get(
                this.localStorageProvider.KEYS.USERNAME
            )
        );
    }

    async checkToken() {
        return await this.localStorageProvider
            .get(this.localStorageProvider.KEYS.ACCESS_TOKEN)
            .then(
                res => {
                    if (res) {
                        this.authenticationState.next(true);
                        return true;
                    } else {
                        this.authenticationState.next(false);
                        return false;
                    }
                },
                () => false
            );
    }

    async getDictionaryAccess() {
        return await this.localStorageProvider
            .get(this.localStorageProvider.KEYS.DICTIONARY_ACCESS)
            .then((access: string) => {
                if (access) this.dictionaryAccessState.next(JSON.parse(access));
            });
    }

    async login(
        accessToken: string,
        user?: any,
        dictionary?,
        refreshToken?: string
    ) {
        if (user) {
            await this.localStorageProvider.set(
                this.localStorageProvider.KEYS.USER_ID,
                user.id
            );
            await this.localStorageProvider.set(
                this.localStorageProvider.KEYS.USER,
                JSON.stringify(user)
            );
        }
        if (refreshToken) {
            await this.localStorageProvider.set(
                this.localStorageProvider.KEYS.REFRESH_TOKEN,
                refreshToken
            );
        }
        if (dictionary) {
            await this.localStorageProvider
                .set(
                    this.localStorageProvider.KEYS.DICTIONARY_ACCESS,
                    JSON.stringify(dictionary)
                )
                .then(() => this.getDictionaryAccess());
        }
        return this.localStorageProvider
            .set(
                this.localStorageProvider.KEYS.ACCESS_TOKEN,
                `Bearer ${accessToken}`
            )
            .then(() => {
                this.authenticationState.next(true);
            });
    }

    logout() {
        this.localStorageProvider.remove(
            this.localStorageProvider.KEYS.USER_ID
        );
        this.localStorageProvider.remove(this.localStorageProvider.KEYS.USER);

        return this.localStorageProvider
            .remove(this.localStorageProvider.KEYS.ACCESS_TOKEN)
            .then(() => {
                if (this.authenticationState.value) {
                    this.authenticationState.next(false);
                }
            });
    }

    isAuthenticated() {
        return this.authenticationState.getValue();
    }

    async getCurrentToken(): Promise<string> {
        return await this.localStorageProvider
            .get(this.localStorageProvider.KEYS.ACCESS_TOKEN)
            .then(res => {
                if (res && typeof res == 'string') {
                    return res;
                } else {
                    return null;
                }
            });
    }

    /**
     * @returns the stored refresh token
     */
    getCurrentRefreshToken(): Promise<string> {
        return this.localStorageProvider
            .get(this.localStorageProvider.KEYS.REFRESH_TOKEN)
            .then(res => {
                if (res && typeof res == 'string') {
                    return res;
                } else {
                    return null;
                }
            });
    }

    getCurrentUserId(): Promise<number> {
        return this.localStorageProvider
            .get(this.localStorageProvider.KEYS.USER_ID)
            .then(res => {
                if (res && typeof res == 'string') {
                    const resAsInt = parseInt(res);
                    if (resAsInt) {
                        return resAsInt;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
    }

    getCurrentUser(): Promise<any> {
        let user = sessionStorage.getItem('userData');
        return new Promise(resolve => {
            if (user) {
                resolve(JSON.parse(user));
            } else {
                return false;
            }
        });
    }

    /**
     * @returns the warehouseId of the current user's scope
     */
    getStoreCurrentUser(): number {
        return parseInt(sessionStorage.getItem('scope'));
    }

    async isStoreUser(): Promise<boolean> {
        return (await this.getCurrentUser()).scope;
    }

    async getCurrentTokenAndUserId(): Promise<UserRequestData> {
        const token: any = await this.localStorageProvider.get(
            this.localStorageProvider.KEYS.ACCESS_TOKEN
        );
        const userId: any = await this.localStorageProvider.get(
            this.localStorageProvider.KEYS.USER_ID
        );
        return { token, userId } as UserRequestData;
    }
}

export interface UserRequestData {
    token: string;
    userId: number;
}
