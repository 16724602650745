import { MenuItemList } from './menu.data';

export const pickingStoreMenu:MenuItemList = [{
    title: 'Picking tiendas',
    open: false,
    type: 'wrapper',
    icon: 'cart-outline',
    children: [
        {
            title: 'Calendario',
            id: 'picking-store-calendar-sga',
            url: '/calendar-sga',
            icon: 'calendar-outline',
        },
    ],
}];
