export namespace TaskModel {

    export interface Task {
        id: number;
        createdAt: Date;
        updatedAt: Date;
        queueName: string;
        taskName: string;
        status: TaskStatus;
        args: any;
        argsId: number;
        error: any;
        errorId: number;
        keepUntil: Date;
        startedAt: Date;
        doneAt: Date;
        artifacts: any[];
    }

}

export enum TaskStatus {
    created = 'created',
    enqueued = 'enqueued',
    cancelled = 'cancelled',
    started = 'started',
    done = 'done',
    failed = 'failed',
}
