import { Injectable } from '@angular/core';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { Api } from '../api';
import { API_ENDPOINTS } from '../api.entities';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CatalogService extends Api {
    syncSizes() {
        return this.http.post(
            this.mgaApiUrl + API_ENDPOINTS.catalog.syncSizes,
            {}
        );
    }

    getWarehouseList(params: any): Observable<WarehouseModel.ResponseModel> {
        const param = !!params ? '/?' + params : '';
        return this.http.get<WarehouseModel.ResponseModel>(
            this.mgaApiUrl + API_ENDPOINTS.catalog.warehouse + param
        );
    }

    getWarehouseItem() {
        return this.http.get(
            this.mgaApiUrl + API_ENDPOINTS.catalog.warehouseItem
        );
    }

    updateWarehouseItem(warehouseId: string, params: any) {
        return this.http.put(
            this.mgaApiUrl +
                API_ENDPOINTS.catalog.warehouseItem.replace(
                    '{id}',
                    warehouseId
                ),
            params
        );
    }

    syncWarehouse() {
        return this.http.post(
            this.mgaApiUrl + API_ENDPOINTS.catalog.warehouseSync,
            {}
        );
    }

    getRackList(params: any) {
        return this.http.get(
            this.mgaApiUrl + API_ENDPOINTS.catalog.rack + '/?' + params
        );
    }

    getRackItem() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.rackItem);
    }

    resizeRackItemAction(id, params) {
        return this.http.post(
            this.mgaApiUrl +
                API_ENDPOINTS.catalog.rackResize.replace('{rackId}', id),
            params
        );
    }

    getContainerList(params: any) {
        return this.http.get(
            this.mgaApiUrl + API_ENDPOINTS.catalog.container + '/?' + params
        );
    }

    getContainerItem() {
        return this.http.get(
            this.mgaApiUrl + API_ENDPOINTS.catalog.containerId
        );
    }

    setLockedContainer(containerId, value) {
        return this.http.post(
            this.mgaApiUrl +
                API_ENDPOINTS.catalog.containerSetLocked
                    .replace('{containerId}', containerId)
                    .replace('{value}', value),
            {}
        );
    }

    getSizesList() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.size);
    }

    getDomainSizes() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.domainSize);
    }

    getPackingItem() {
        return this.http.get(
            this.mgaApiUrl + API_ENDPOINTS.catalog.packingItem
        );
    }

    getPackingListAction() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.packing);
    }

    updatePackingItem() {
        return this.http.post(
            this.mgaApiUrl + API_ENDPOINTS.catalog.packing,
            {}
        );
    }

    getMarketList() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.market);
    }

    deleteMarketItem() {
        return this.http.delete(
            this.mgaApiUrl + API_ENDPOINTS.catalog.marketItem
        );
    }

    updateMarketItem() {
        return this.http.put(
            this.mgaApiUrl + API_ENDPOINTS.catalog.marketItem,
            {}
        );
    }

    getMarketItem() {
        return this.http.get(this.mgaApiUrl + API_ENDPOINTS.catalog.marketItem);
    }
}
