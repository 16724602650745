import { RackModel, WarehouseModel } from './';

export namespace ContainerModel {

    export interface Container {
        id: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        warehouse: WarehouseModel.Warehouse;
        warehouseId: number;
        rack: RackModel.Rack;
        rackId: number;
        row: number;
        column: number;
        items: number;
        enabled: boolean;
        reference: string;
        locked: boolean;
    }

    export interface GetByReferenceWithCountResponse {
        container: Container;
        numProducts: number;
    }

}
