import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { IntermediaryService, MgaService } from '../../core';
import { HttpRequestModel, InventoryModel, PackingModel, ProcessType } from '../../models';

import { AudioProvider, FiltersAuditProvider } from '../../../providers';

import { FilterListData, FiltersListComponent } from './filters-list/filters-list.component';

import Inventory = InventoryModel.Inventory;
import SearchPackingInventoriesParameters = PackingModel.SearchPackingInventoriesParameters;
import HttpErrorResponse = HttpRequestModel.HttpErrorResponse;

@Component({
  selector: 'list-products-packing',
  templateUrl: './list-products-packing.component.html',
  styleUrls: ['./list-products-packing.component.scss'],
})
export class ListProductsPackingComponent implements OnInit {

  packingDestination: string = null;
  packingReference = '';
  isReturnPacking: boolean;
  returnPicking: string;
  packingInventories: Inventory[] = [];
  form = this.formBuilder.group({
    products: [],
    warehouses: [],
    orderBy: this.formBuilder.group({
      type: 'reference',
      order: 'ASC',
    }),
  });
  token: string;

  private process: ProcessType;

  private readonly isSorterProcess: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private navParams: NavParams,
    private router: Router,

    private intermediaryService: IntermediaryService,
    private mgaService: MgaService,

    private modalController: ModalController,
    private popoverController: PopoverController,

    private audioProvider: AudioProvider,
    private filtersAuditProvider: FiltersAuditProvider,
  ) {
    this.packingReference = this.navParams.get('packingReference');
    this.packingDestination = this.navParams.get('packingDestination');
    this.packingInventories = this.navParams.get('packingInventories');
    this.process = this.navParams.get('process');
    this.isSorterProcess = !!this.navParams.get('isSorterProcess');
    this.isReturnPacking = !!this.navParams.get('isReturnPacking');
    this.returnPicking = this.navParams.get('returnPicking');
    this.token = this.navParams.get('token');
  }

  async ngOnInit() {
    this.filtersAuditProvider.sort = null;
    this.filtersAuditProvider.filter = 0;
    if (this.packingInventories.length === 0) {
      await this.getProducts();
    }
  }

  private async getProducts(data: SearchPackingInventoriesParameters = null) {
    await this.intermediaryService.presentLoading();
    this.mgaService.api.catalog.packing.searchPackingInventories(this.packingReference, data, this.token).subscribe(async (packing) => {
      if (packing) {
        this.packingInventories = packing.inventories;
        this.packingDestination = packing.destinations.length ? packing.destinations[0].warehouse.reference : null;
      } else {
        this.packingInventories = [];
      }
      await this.intermediaryService.dismissLoading();
    }, async (response: HttpErrorResponse) => {
      await this.intermediaryService.presentToastError(response.error?.message || response.error?.errors[0]?.message || 'Error desconocido');
    })
  }

  async filterProducts() {
    const popover = await this.popoverController.create({
      cssClass: 'popover-filter',
      component: FiltersListComponent,
      componentProps: {
          form: this.form,
          packingReference: this.packingReference,
          token: this.token,
      },
    });

    popover.onDidDismiss().then(async (res: OverlayEventDetail<FilterListData>) => {
      if (res.data) {
        const parameters: SearchPackingInventoriesParameters = {
            filters: {
                productIds: res.data.items.products.filter((p) => p.type === 'product').map((p) => p.value),
                packageIds: res.data.items.products.filter((p) => p.type === 'package').map((p) => p.value),
                warehouseIds: res.data.items.warehouses.map((w) => w.value),
            },
            orderBy: res.data.items.orderBy,
        };

        await this.getProducts(parameters);
        this.form = res.data.form;
      }
    });

    await popover.present();
  }

  async close() {
     await this.modalController.dismiss();
  }

  async btnContinue(con = false) {
    if(con){
      await this.modalController.dismiss(this.packingReference);
    }else{
      await this.modalController.dismiss();
    }
  }

  async btnCarrierEmpty() {
    await this.intermediaryService.presentLoadingNew('Vaciando embalaje...');

    this.mgaService.api.inventory.emptyReturnPacking(this.packingReference, this.token).subscribe(async (packingId) => {
        this.mgaService.api.catalog.packing.emptyBulk({packingIds: [packingId], process: this.process}, this.token).subscribe(() => {
            this.audioProvider.playDefaultOk();
            this.intermediaryService.dismissLoadingNew();
            this.intermediaryService.presentToastSuccess(`El embalaje ${this.packingReference} se ha vaciado correctamente`);
            this.modalController.dismiss(this.packingReference);
        }, (response: HttpErrorResponse) => {
            this.audioProvider.playDefaultError();
            this.intermediaryService.dismissLoadingNew();
            this.intermediaryService.presentToastError(response.error?.message || response.error?.errors[0]?.message || 'Error desconocido');
        });
    }, (response: HttpErrorResponse) => {
        this.audioProvider.playDefaultError();
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastError(response.error?.message || response.error?.errors[0]?.message || 'Error desconocido');
    });
  }

  async btnPosition(ruta: string) {
    await this.router.navigateByUrl(ruta);
    await this.modalController.dismiss(ruta,'navigate');
  }

  async btnCancel() {
    await this.modalController.dismiss();
  }

}
