import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MgaService } from 'src/app/core/mga.service';
import { PopoverController } from "@ionic/angular";

@Component({
    selector: 'app-table-title-filter',
    templateUrl: './table-title-filter.component.html',
    styleUrls: ['./table-title-filter.component.scss'],
})
export class TableTitleFilterComponent implements OnInit {
    @ViewChild('popover') popover;
    @Input() listItems;
    data;
    isOpen = false;
    searchTerm = '';
    filteredItems: any[] = [];
    public allSelected: boolean = true;
    @Output() popoverDismissed = new EventEmitter<any>();

    // Variables to track pagination
    currentPage = 1;
    pagedItems: any[] = [];
    showMoreButton = false;
    pageSize = 20;

    constructor(
        public service: MgaService,
        private popoverCtrl: PopoverController,
    ) {}

    ngOnInit() {
        this.fetchData();
        this.filterData();
        this.loadPage();
    }

    async presentPopover(e: Event) {
        this.popover.event = e;
        this.isOpen = true;
    }

    fetchData() {
        this.filteredItems = this.listItems;
        this.data = this.listItems;
      }

    filterData() {
        if (this.searchTerm === '') {
            this.filteredItems = this.data;
            this.currentPage = 1;
            this.loadPage();
        } else {
            this.filteredItems = this.data.filter((d) => {
                // if the value is numeric, the toLoweCase() function fails
                const value = (typeof d.value === 'number') ? d.value.toString() : d.value;
                return value.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
            this.pagedItems = this.filteredItems;
        }
      }

    checkSelected(event, item) {
        this.listItems[this.listItems.indexOf(item)].checked = event.detail.checked;
        this.checkAllSelected();
    }

    checkAllSelected() {
        this.allSelected = this.filteredItems.every(item => item.checked);
    }

    selectAll() {
        const selected = this.filteredItems.every(item => item.checked);
        for (let iFilter in this.filteredItems) {
          this.filteredItems[iFilter].checked = !selected;
        }
    }

    applyFilters() {
        if (this.pagedItems && this.pagedItems.length > 0) {
            const selectedItems = this.pagedItems.filter(item => item.checked);
            this.popoverCtrl.dismiss({ filters: selectedItems });
        } else {
            this.popoverCtrl.dismiss();
        }
    }

    onPopoverDismiss(data: any) {
        this.popoverDismissed.emit(data.detail.data);
    }

    // Load the current page of items
    loadPage() {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.pagedItems = this.filteredItems?.slice(startIndex, endIndex);
        this.showMoreButton = endIndex < this.filteredItems?.length;
    }

    // Load the next page of items
    loadMore() {
        this.currentPage++;
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        const nextPageItems = this.filteredItems?.slice(startIndex, endIndex);
        this.pagedItems = this.pagedItems.concat(nextPageItems);
        this.showMoreButton = endIndex < this.filteredItems?.length;
    }
}

