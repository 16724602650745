import { createAction, props } from '@ngrx/store';

export const doLoginAction = createAction('do Login', props<any>());
export const doLoginActionSuccess = createAction(
    'do Login success',
    props<any>()
);
export const doLoginActionFail = createAction('do Login fail', props<any>());

export const getProfileAction = createAction('Get profile');
export const getProfileSuccessAction = createAction(
    'Get profile success',
    props<any>()
);
export const getProfileFailAction = createAction(
    'Get profile fail',
    props<any>()
);
