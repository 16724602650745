import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSelect from './selector';
import * as actions from './actions';

@Injectable({
    providedIn: 'root',
})
export class CatalogSandbox {
    //
    public syncSizesLoading$ = this.store.select(fromSelect.syncSizesLoading);
    public syncSizesData$ = this.store.select(fromSelect.syncSizesData);
    public syncSizesFailData$ = this.store.select(fromSelect.syncSizesFailData);
    //
    public getWarehouseListLoading$ = this.store.select(
        fromSelect.getWarehouseListLoading
    );
    public getWarehouseListData$ = this.store.select(
        fromSelect.getWarehouseListData
    );
    public getWarehouseListFailData$ = this.store.select(
        fromSelect.getWarehouseListFailData
    );
    //
    public getWarehouseItemLoading$ = this.store.select(
        fromSelect.getWarehouseItemLoading
    );
    public getWarehouseItemData$ = this.store.select(
        fromSelect.getWarehouseItemData
    );
    public getWarehouseItemFailData$ = this.store.select(
        fromSelect.getWarehouseItemFailData
    );
    //
    public updateWarehouseItemLoading$ = this.store.select(
        fromSelect.updateWarehouseItemLoading
    );
    public updateWarehouseItemData$ = this.store.select(
        fromSelect.updateWarehouseItemData
    );
    public updateWarehouseItemFailData$ = this.store.select(
        fromSelect.updateWarehouseItemFailData
    );
    //
    public syncWarehouseLoading$ = this.store.select(
        fromSelect.syncWarehouseLoading
    );
    public syncWarehouseData$ = this.store.select(fromSelect.syncWarehouseData);
    public syncWarehouseFailData$ = this.store.select(
        fromSelect.syncWarehouseFailData
    );
    //
    public getRackListLoading$ = this.store.select(
        fromSelect.getRackListLoading
    );
    public getRackListData$ = this.store.select(fromSelect.getRackListData);
    public getRackListFailData$ = this.store.select(
        fromSelect.getRackListFailData
    );
    //
    public getRackItemLoading$ = this.store.select(
        fromSelect.getRackItemLoading
    );
    public getRackItemData$ = this.store.select(fromSelect.getRackItemData);
    public getRackItemFailData$ = this.store.select(
        fromSelect.getRackItemFailData
    );
    //
    public resizeRackItemLoading$ = this.store.select(
        fromSelect.resizeRackItemLoading
    );
    public resizeRackItemData$ = this.store.select(
        fromSelect.resizeRackItemData
    );
    public resizeRackItemFailData$ = this.store.select(
        fromSelect.resizeRackItemFailData
    );
    //
    public getContainerListLoading$ = this.store.select(
        fromSelect.getContainerListLoading
    );
    public getContainerListData$ = this.store.select(
        fromSelect.getContainerListData
    );
    public getContainerListFailData$ = this.store.select(
        fromSelect.getContainerListFailData
    );
    //
    public getContainerItemLoading$ = this.store.select(
        fromSelect.getContainerItemLoading
    );
    public getContainerItemData$ = this.store.select(
        fromSelect.getContainerItemData
    );
    public getContainerItemFailData$ = this.store.select(
        fromSelect.getContainerItemFailData
    );
    //
    public setLockedContainerLoading$ = this.store.select(
        fromSelect.setLockedContainerLoading
    );
    public setLockedContainerData$ = this.store.select(
        fromSelect.setLockedContainerData
    );
    public setLockedContainerFailData$ = this.store.select(
        fromSelect.setLockedContainerFailData
    );
    //
    public getSizesListLoading$ = this.store.select(
        fromSelect.getSizesListLoading
    );
    public getSizesListData$ = this.store.select(fromSelect.getSizesListData);
    public getSizesListFailData$ = this.store.select(
        fromSelect.getSizesListFailData
    );
    //
    public getDomainSizesListLoading$ = this.store.select(
        fromSelect.getDomainSizesListLoading
    );
    public getDomainSizesListData$ = this.store.select(
        fromSelect.getDomainSizesListData
    );
    public getDomainSizesListFailData$ = this.store.select(
        fromSelect.getDomainSizesListFailData
    );
    //
    public getPackingItemLoading$ = this.store.select(
        fromSelect.getPackingItemLoading
    );
    public getPackingItemData$ = this.store.select(
        fromSelect.getPackingItemData
    );
    public getPackingItemFailData$ = this.store.select(
        fromSelect.getPackingItemFailData
    );
    //
    public getPackingListLoading$ = this.store.select(
        fromSelect.getPackingListLoading
    );
    public getPackingListData$ = this.store.select(
        fromSelect.getPackingListData
    );
    public getPackingListFailData$ = this.store.select(
        fromSelect.getPackingListFailData
    );
    //
    public updatePackingItemLoading$ = this.store.select(
        fromSelect.updatePackingItemLoading
    );
    public updatePackingItemData$ = this.store.select(
        fromSelect.updatePackingItemData
    );
    public updatePackingItemFailData$ = this.store.select(
        fromSelect.updatePackingItemFailData
    );
    //
    public getMarketListLoading$ = this.store.select(
        fromSelect.getMarketListLoading
    );
    public getMarketListData$ = this.store.select(fromSelect.getMarketListData);
    public getMarketListFailData$ = this.store.select(
        fromSelect.getMarketListFailData
    );
    //
    public deleteMarketItemLoading$ = this.store.select(
        fromSelect.deleteMarketItemLoading
    );
    public deleteMarketItemData$ = this.store.select(
        fromSelect.deleteMarketItemData
    );
    public deleteMarketItemFailData$ = this.store.select(
        fromSelect.deleteMarketItemFailData
    );
    //
    public updateMarketItemLoading$ = this.store.select(
        fromSelect.updateMarketItemLoading
    );
    public updateMarketItemData$ = this.store.select(
        fromSelect.updateMarketItemData
    );
    public updateMarketItemFailData$ = this.store.select(
        fromSelect.updateMarketItemFailData
    );
    //
    public getMarketItemLoading$ = this.store.select(
        fromSelect.getMarketItemLoading
    );
    public getMarketItemData$ = this.store.select(fromSelect.getMarketItemData);
    public getMarketItemFailData$ = this.store.select(
        fromSelect.getMarketItemFailData
    );

    constructor(private store: Store) {}

    doSyncSizes(): void {
        this.store.dispatch(actions.syncSizesAction({}));
    }
    doGetWarehouseList(params: any): void {
        this.store.dispatch(actions.getWarehouseListAction({ params }));
    }
    doGetWarehouseItem(): void {
        this.store.dispatch(actions.getWarehouseItemAction({}));
    }

    doUpdateWarehouseItem(warehouseId: string | number, params: any): void {
        this.store.dispatch(
            actions.updateWarehouseItemAction({ warehouseId, params })
        );
    }
    doClearUpdateWarehouseItemState(): void {
        this.store.dispatch(actions.clearUpdateWarehouseItemStateAction({}));
    }

    doSyncWarehouse(): void {
        this.store.dispatch(actions.syncWarehouseAction({}));
    }
    doGetRackList(params: any): void {
        this.store.dispatch(actions.getRackListAction({ params }));
    }
    doGetRackItem(): void {
        this.store.dispatch(actions.getRackItemAction({}));
    }

    doResizeRackItem(id: number, params: any): void {
        this.store.dispatch(actions.resizeRackItemAction({ id, params }));
    }
    doClearResizeRackItemState(): void {
        this.store.dispatch(actions.clearResizeRackItemStateAction({}));
    }

    doGetContainerList(params: any): void {
        this.store.dispatch(actions.getContainerListAction({ params }));
    }

    doGetContainerItem(): void {
        this.store.dispatch(actions.getContainerItemAction({}));
    }

    doSetLockedContainerItem(containerId, value): void {
        this.store.dispatch(
            actions.setLockedContainerItemAction({ containerId, value })
        );
    }
    doClearSetLockedContainerItemState(): void {
        this.store.dispatch(actions.clearSetLockedContainerItemStateAction({}));
    }

    doGetSizesList(): void {
        this.store.dispatch(actions.getSizesListAction({}));
    }
    doGetDomainSizesList(): void {
        this.store.dispatch(actions.getDomainSizesListAction({}));
    }
    doGetPackingItem(): void {
        this.store.dispatch(actions.getPackingItemAction({}));
    }
    doGetPackingList(): void {
        this.store.dispatch(actions.getPackingListAction({}));
    }
    doUpdatePackingItem(): void {
        this.store.dispatch(actions.updatePackingItemAction({}));
    }
    doGetMarketList(): void {
        this.store.dispatch(actions.getMarketListAction({}));
    }
    doDeleteMarketItem(): void {
        this.store.dispatch(actions.deleteMarketItemAction({}));
    }
    doUpdateMarketItem(): void {
        this.store.dispatch(actions.updateMarketItemAction({}));
    }
    doGetMarketItem(): void {
        this.store.dispatch(actions.getMarketItemAction({}));
    }
}
